const React = require('react');

class OpinionMini extends React.Component {
    render() {
        const {size, source, text, href} = this.props;
        const className = `opinion-mini-${size}`;
        const sourceClass = size === 'large' ? 'ingress' : 'small';
        const textClass = size === 'large' ? 'large' : 'ingress';
        if (href) {
            return (
                <a href={href} className={className}>
                    <p className={sourceClass}>{source}:</p>
                    <p className={textClass}>{text}</p>
                </a>
            )
        }
        return (
            <div className={className}>
                {source && <p className={sourceClass}>{source}:</p>}
                <p className={textClass}>{text}</p>
            </div>
        );
    }
}

module.exports = OpinionMini;