const isNode = (typeof process !== 'undefined');
const React = require('react');
const ReactStripeElements = isNode && require('react-stripe-elements');

const RedirectToStripeCheckout = ({ stripe, sessionId }) => {
    stripe.redirectToCheckout({
        sessionId: sessionId,
      }).then(function (result) {            
        if (result.error) {
            console.error('Stripe checkout cancelled', result.error.message);
        } else {
            console.log('Stripe checkout redirect result', result);
        }
    });
    return (
        <div className="checkout"></div>
    );
};

module.exports = isNode ? ReactStripeElements.injectStripe(RedirectToStripeCheckout) : () => {};