const React = require('react');

const designLogo = (
    <svg x="0px" y="0px" viewBox="0 0 618 198.4">
        <title>Universitetet i Bergen - Fakultet for kunst, musikk og design</title>
        <path d="M608.3,2.5v114.1L609,184l-29.8-46.6L496.9,11.7c21.6,5.8,32.9,20.2,36.6,40.4l10.4-1.5C538.3,21.3,518.4,0,471.5,0
   c-15,0-27.7,2.7-38.4,7.7C422.7,2.7,408.6,0,389.9,0c-7.8,0-15.5,0.8-22.8,2.5h-75.3l-16,0l-10.7,11.6V2.5h-11.9l-2.9,6.9V2.5h-9.9
   v17.8c-3-3.8-6.8-7.2-11.5-10V2.5h-10.2v3c-9.8-3.5-22.3-5.5-38-5.5c-5.8,0-11.5,0.4-17.1,1.3C157.4,0.5,150.5,0,142.8,0
   C135,0,127.3,0.8,120,2.5H55.6v68.8L40.4,87.7V2.5H30.2v96.3l-20.3,22V2.5H0v193.5h9.9v-62l20.3-21.7v19.4c0,24.7,7,44.1,25.4,55.4
   v8.8h9.7v-4c10.7,4.2,24.2,6.5,41.1,6.5c6.4,0,12.3-0.3,17.8-1c5.6,0.6,11.7,1,18.4,1c1.5,0,3.1,0,4.6-0.1c1.8,0.1,3.6,0.1,5.5,0.1
   c4.8,0,9.3-0.2,13.6-0.6c4.4,0.4,9.1,0.6,14.1,0.6c24.1,0,46.9-6.6,59.9-21.9v19.4h9.9v-40.2c0-0.1,0-0.1,0-0.2l5,6.2v34.2h9.7
   v-22.4l11.8,14.4v8h84.8c7.9,1.6,17.1,2.5,27.9,2.5c8.3,0,16.5-0.8,24.2-2.5h11v-3.1c2.3-0.8,4.6-1.8,6.7-2.8
   c11,5.5,24.2,8.4,40,8.4c32.8,0,55.6-8.8,73-29.9v-66.2l61.3,93.6H618V2.5H608.3z M449,11.9v7.8c-1.7-2.1-3.7-4.1-5.9-5.9
   C444.9,13.1,446.9,12.5,449,11.9z M431.6,179.3c-6.5-4.2-11.9-9.5-16.3-16.1c7.8-13.5,12.2-32.1,12.9-56.9
   c12.3,4.9,18.3,12.1,20.8,22.8v27C446.2,166.6,439.8,174.1,431.6,179.3z M289.2,187l-2.4-2.9V98.3l5,6V187H289.2z M325.4,53.5
   c0.7,22.3,11.5,32.9,30.2,38.7h-54V79L325.4,53.5z M363.2,13.1c0.3-0.1,0.6-0.2,0.9-0.3c17.9,2.7,31,9.4,39.7,22
   c-8.4,14.4-12.9,32.8-14,54.5c-42.9-4.8-54.1-13.1-54.1-37.9c0-3.6,0.4-6.9,1-9.9L363.2,13.1z M412.3,92.3
   c-3.8-0.7-7.8-1.3-12.1-1.8c0.7-17.7,3.8-32.8,9.4-45c5,11.8,7.6,27.2,8.1,46.8H412.3z M301.6,101.2h88c0.2,25.1,4.8,46.3,14.2,62.4
   c-7.7,11-18.6,17.6-33.3,20.8l-68.9-83V101.2z M400.1,101.2h9.2c3.1,0.6,5.9,1.2,8.6,1.8c-0.3,21.2-2.9,37.6-8.2,50.1
   C403.3,139.3,400.3,121.8,400.1,101.2z M428.2,96.2c-0.3-26.8-4.7-46.5-12.9-60.8c4.7-6.9,10.6-12.5,17.7-16.8
   c9.3,6.3,14.2,15.5,15.9,27.6l0,0v62C443.8,102.9,437,99,428.2,96.2z M409.3,26.7c-5.4-6.5-12-11.5-19.6-15.2h25.8
   c2.7,0.6,5.2,1.3,7.5,2.2C417.8,17.3,413.2,21.6,409.3,26.7z M343.4,11.4c0.7,0,1.5,0,2.2,0c-9,6-15.6,14.5-18.6,26.1l-25.4,27.6
   V11.4H343.4z M291.7,75.8l-5,5.4V11.4h5V75.8z M276.9,16.2v75.8l-11.8,12.8v-76L276.9,16.2z M65.2,106.2V81.9l-0.1-7.1l11.3-12.1
   v57.1L65.2,106.2z M218.7,85.9l-3.7,9c-0.9-0.2-1.8-0.5-2.7-0.7v-9l6.4-6.8V85.9z M194.8,11.4h7.6v8.7c-2.1-2.7-4.7-5.1-7.6-7.3
   V11.4z M142.3,183.5v-49.2l12.9,19.8l1.7,4v17.1c-3.9,3.4-8.6,6.2-14.3,8.4C142.5,183.6,142.4,183.6,142.3,183.5z M100.5,78.4
   l-0.1-8l7.8,11.9C105.2,81.1,102.7,79.8,100.5,78.4z M100.2,52.1L100,37.5l5-5.3l11.9,29.1l0,0c1.1,6.9,3.5,12.5,7,17l3.7,8.9
   c-1.8-0.3-3.4-0.6-5-0.9L100.2,52.1z M126.6,51.3c0-9.2,2.2-16.4,5.8-22.1v43.6c-0.4-0.5-0.8-1.1-1.2-1.7l-3.4-8.2
   C127,59.6,126.6,55.7,126.6,51.3z M168.8,157.4l-2-3.1v-20.3l5.6-5.9v5.4C172.4,142.3,171.3,150.3,168.8,157.4z M156.9,133.5
   l-14.5-35.1c0.8,0.1,1.6,0.2,2.4,0.3c4.3,0.5,8.4,1,12.1,1.6V133.5z M166.8,120.8v-18.7c1.9,0.4,3.8,0.9,5.6,1.3v11.3L166.8,120.8z
    M131.7,97.1l0.7,1.6v2.5l-2.9-4.4C130.2,96.9,131,97,131.7,97.1z M166.8,182.2l1.6,4c-0.5,0.2-1.1,0.3-1.6,0.4V182.2z M181.4,177.1
   l-6.1-9.5c5.1-10,7.3-22.1,7.3-35.8v-14.6l2.6-2.7v2.2l0.5,49.9L181.4,177.1z M194.8,115.1c3.2,3.4,5.4,7.5,6.7,12.4l-5.4,13.1
   l-1.3,3.2V115.1z M195.7,103l2.1-2.2c1.6,0.3,3.1,0.5,4.6,0.8v6.9C200.4,106.5,198.2,104.7,195.7,103z M183.4,89.5
   c-0.3,0-0.6-0.1-0.9-0.1v-73c0.9,0.5,1.7,1,2.6,1.5v71.9C184.5,89.7,184,89.6,183.4,89.5z M172.4,12.4v75.8
   c-1.9-0.3-3.8-0.5-5.6-0.8v-76h1.7C169.9,11.7,171.1,12,172.4,12.4z M156.9,12.2v73.3c-5.8-1.4-10.7-3-14.6-4.9V19
   C146.8,15.9,151.8,13.7,156.9,12.2z M132.4,14.5c-7.1,5.9-12.4,13.8-14.8,23.9L112,24.7l12.5-13.3h8V14.5z M90.9,55.7v10.6
   c-1.4-3.9-2.1-8.5-2.1-13.9L90.9,55.7z M116.1,94.3l16.3,24.9v58.2c-7.5-6.5-11.2-15.5-12.7-26.9l-10.2,1.5c0.5,3.5,1.2,6.8,2.1,10
   l-11-13.3V89.2C105.1,91.3,110.3,93,116.1,94.3z M116.5,183.7c-6.5-2.5-11.8-5.8-16-9.9v-9.6L116.5,183.7z M194.8,170.3v-1.9
   l7.6-18.4v4.2C201,160.6,198.4,165.9,194.8,170.3z M194.8,90.4V26.6c4,5.4,6.5,11.9,7.6,19.6v36.1L194.8,90.4z M90.9,17.8
   c-1.5,1.6-2.9,3.2-4.2,5V11.4h4.2V17.8z M86.7,79c1.3,1.5,2.7,2.9,4.2,4.2v53.9l-4.2-5.1V79z M90.9,152.5v6.7
   c-1.3-3.2-2.2-6.6-2.9-10.2L90.9,152.5z M212.3,156.4c0.8-3.7,1.3-7.7,1.3-12.1c0-5.5-0.4-10.5-1.3-14.9V126l4.6-11.2l1.8,2.2v16.4
   c0,11.7-1.8,22-6.4,30.5V156.4z M228.9,111.8c5.2,3.9,8.4,8.8,10.3,15l-10.3-12.4V111.8z M228.9,100.2V85.9L239,61.3l1.4-3.3v50.7
   C237.2,105.3,233.4,102.5,228.9,100.2z M250.3,44.6l5.6-6l-0.5,43.3v33.4l-5.1,5.5V44.6z M256.1,19.6l-0.1,4.3l-3.1,3.3L256.1,19.6z
    M238.2,38.7l-3.4,8.3l-5.9,6.4V22.6C233.3,27,236.3,32.4,238.2,38.7z M218.7,64.5l-6.4,6.9V13.1c2.3,0.8,4.4,1.6,6.4,2.6V64.5z
    M76.5,11.4v4.5l-2.9-4.5H76.5z M64.5,14.4l12,18.8v15.3L65,61L64.5,14.4z M55.6,85.1v9.5l-4.2-5L55.6,85.1z M40.4,133.4v-32.1
   l4.6-4.9l10.6,13v65.6C44.5,165.2,40.4,150.9,40.4,133.4z M65.2,121.2L76.5,135c0.1,5.7,0.7,11,1.7,16.1l-6.6,1
   c1.9,14.2,7.3,26.2,19.3,34.5v1.9c-10.4-1.1-18.8-3.5-25.6-6.9V121.2z M212.3,184.2v-3.9c11.9-11.6,16.6-28.3,16.6-48.6v-2.3
   l11.5,14v10.3C237.2,169.6,226,179.1,212.3,184.2z M251.4,141.5c-0.1-2.8-0.3-5.5-0.6-8l4.6-4.9v17.8L251.4,141.5z M265.1,157.9
   v-39.8l11.8-12.6v66.7L265.1,157.9z M301.6,116.4l57.3,69.8c-20-5.5-27.6-17.7-30.1-35.6l-10.2,1.5c2,14.5,7.6,26.7,20,35h-37.1
   V116.4z M412.2,187h-22.5c7.6-3.7,14.2-8.7,19.6-15.2c3.7,4.7,7.9,8.8,12.6,12.3C418.8,185.3,415.5,186.2,412.2,187z M449,177.1v9.6
   c-2.7-0.7-5.3-1.6-7.7-2.6C444.1,182.1,446.7,179.8,449,177.1z M458.9,188.7v-30.4c1.1-4.3,1.7-8.9,1.7-14c0-6.4-0.6-12.1-1.7-17.1
   V44.7l0,0c0,0,0-0.1,0-0.1V9.9c4-0.6,8.2-0.9,12.6-1c2.5,0,4.9,0,7.2,0.1v180.3c-2.3,0.1-4.7,0.2-7.2,0.2
   C467.1,189.5,462.9,189.2,458.9,188.7z M534.6,165c-10.6,12.2-24.7,20.6-46.1,23.5v-87.2h46.1V165z M488.4,92.3V81.9l-0.7-67.5
   L517.4,61l20.5,31.3H488.4z"/>
    </svg>
);

module.exports = designLogo;