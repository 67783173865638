const isNode = (typeof process !== 'undefined');
const React = require('react');
const ReactDOM = isNode ? require('react-dom') : {};

const getImage = require('../../getImage');
const Video = require('../../video/Component/index');
// const Image = require('../../Image');
//const Image = require('../../../../webapp/components/Image');
const Image = (props) => <img {...props} src={getImage(props.sources)} />;




function videoDimensions(innerWidth, innerHeight, width, height) {
    const containerWidth = innerWidth - 480;
    const containerHeight = innerHeight - 408;
    const containerRatio = containerWidth / containerHeight;
    const videoRatio = width / height;

    if (videoRatio > containerRatio) {
        return { width: containerWidth, height: containerWidth / videoRatio };
    } else {
        return { width: videoRatio * containerHeight, height: containerHeight };
    }
}

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        const imagesStatus = props.images.reduce((acc, _, index) => {
            acc[index] = false;
            return acc;
        }, {});

        this.state = {
            initialMargin: 0,
            imagesStatus,
            fullscreen: -1,
        };

        this.handleResize = this.handleResize.bind(this);
        this.imageLoaded = this.imageLoaded.bind(this);
        this.scrollEvent = this.scrollEvent.bind(this);
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden';
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    handleResize() {
        this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
        if (this.props.description) {
            const descriptionNodes = this.refs.description.childNodes;
            const first = descriptionNodes[0];
            const last = descriptionNodes[descriptionNodes.length - 1];
            const width = last.getBoundingClientRect().right - first.getBoundingClientRect().left;
            this.setState({ descriptionWidth: width });
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto';
        window.removeEventListener('resize', this.handleResize);
    }

    imageLoaded(index) {
        const { imagesStatus } = this.state;
        imagesStatus[index] = true;
        this.setState({ imagesStatus });
    }

    scrollEvent(event) {
        if (event.deltaY) {
            this.refs.container.scrollLeft = this.refs.container.scrollLeft + event.deltaY;
        }
    }

    render() {
        const { imagesStatus, fullscreen, innerWidth, innerHeight } = this.state;
        const imagesLoaded = Object.keys(imagesStatus).every(index => imagesStatus[index]);

        const { images, title, description, artists, year, type, typeContent } = this.props;
        const first = images[0];
        const rest = images.length > 0 ? images.slice(1) : [];

        let info = null;

        if (!typeContent) {
            // console.log('Ignore info because missing typecontent');
        }
        else if (type === 'regular' && typeContent.kuProject) {
            info = (
                <div className="info">
                    <p>{title}</p>
                    {artists.map((artist, index) => <span key={index} className="artist"><a href={artist.href} className="small-link" style={{ fontSize: 14 }}>{artist.name}</a></span>)}
                </div>
            );
        } else if (type === 'regular') {
            info = (
                <div className="info">
                    {artists.map((artist, index) => <span key={index} className="artist"><a href={artist.href}>{artist.name}</a></span>)}
                    <p>{title} {year && `, ${year}`}</p>
                    <p>{typeContent.media.join(', ')}</p>
                    <p>{typeContent.size}</p>
                </div>
            );
        } else if (type === 'vimeo') {
            info = (
                <div className="info">
                    {artists.map((artist, index) => <span key={index} className="artist"><a href={artist.href}>{artist.name}</a></span>)}
                    <p>{title} {year && `, ${year}`}</p>
                </div>
            )
        } else if (type === 'soundcloud') {
            info = (
                <div className="info">
                    {artists.map((artist, index) => <span key={index} className="artist"><a href={artist.href}>{artist.name}</a></span>)}
                    <p>{title} {year && `, ${year}`}</p>
                    <p>{typeContent.duration}</p>
                </div>
            );
        }

        let content;
        if (type === 'regular') {
            content = (
                <div className="elements">
                    {!imagesLoaded && <div className="overlay">Laster...</div>}
                    <Image ref="first" className="element firstElement" sources={first} style={{ marginLeft: this.state.initialMargin }} onClick={() => this.setState({ fullscreen: 0 })} onLoad={() => {
                        this.imageLoaded(0);
                        const imageWidth = ReactDOM.findDOMNode(this.refs.first).getBoundingClientRect().width;
                        const innerWidth = window.innerWidth;
                        this.setState({ initialMargin: (innerWidth - imageWidth) / 2 })
                    }} />
                    {description && (
                        <div className="descriptionElement">
                            <div className="wrapper">
                                <h2 className="title" style={{ width: this.state.descriptionWidth || 'auto' }}>{title}</h2>
                                <div
                                    ref="description"
                                    style={{ width: this.state.descriptionWidth || 'auto' }}
                                    className="description"
                                    dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                        </div>
                    )}
                    {rest.map((sources, index) => <Image key={index} style={{ marginLeft: this.state.initialMargin }} className="element" sources={sources} onLoad={() => this.imageLoaded(index + 1)} onClick={() => this.setState({ fullscreen: index + 1 })} />)}
                    <div className="paddingHack" />
                    {fullscreen !== -1 && <div className="fullscreen" style={{ backgroundImage: `url(${getImage(images[fullscreen])})` }} onClick={() => this.setState({ fullscreen: -1 })} />}
                </div>
            );
        } else if (type === 'vimeo') {
            content = (
                <div className="elements">
                    <div className="videoElement" style={videoDimensions(innerWidth, innerHeight, typeContent.width, typeContent.height)}>
                        <Video {...typeContent} />
                    </div>
                    {description && (
                        <div className="descriptionElement">
                            <div className="wrapper">
                                <h2 className="title" style={{ width: this.state.descriptionWidth || 'auto' }}>{title}</h2>
                                <div
                                    ref="description"
                                    style={{ width: this.state.descriptionWidth || 'auto' }}
                                    className="description"
                                    dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                        </div>
                    )}
                </div>
            );
        } else if (type === 'soundcloud') {
            content = (
                <div className="elements">
                    <div className="soundCloudElement">
                        <iframe width="100%" height="450" scrolling="no" frameBorder="no" src={`https://w.soundcloud.com/player/?url=${encodeURIComponent(`http://api.soundcloud.com/tracks/${typeContent.trackId}`)}&auto_play=false&visual=true`}></iframe>
                    </div>
                    {description && (
                        <div className="descriptionElement">
                            <div className="wrapper">
                                <h2 className="title" style={{ width: this.state.descriptionWidth || 'auto' }}>{title}</h2>
                                <div
                                    ref="description"
                                    style={{ width: this.state.descriptionWidth || 'auto' }}
                                    className="description"
                                    dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                        </div>
                    )}
                </div>
            );
        }



        return (
            <div ref="container" className={(type === 'soundcloud' ? 'gallery soundcloud' : 'gallery') + (description ? ' withDescription' : '')} onWheel={this.scrollEvent}>
                <button className="close" onClick={() => history.back()}>x</button>
                {content}
                {info}
            </div>
        );
    }
}


module.exports = Gallery;