const React = require('react');
const isNode = (typeof process !== 'undefined');
const Modal = isNode ? require('react-modal') : () => null;
const Logo = require('../Logo');
const Newsletter = require('../Newsletter');
const Search = require('./Search');
const KHIBLogo = require('./Components/KHIBLogo');
const AnchorMenu = require('./Components/AnchorMenu');
const Link = require('./Components/Link');
const LinkLogo = require('./Components/LinkLogo');

const zIndex = 100000;

const languageName = {
    no: 'Nor',
    en: 'Eng'
};

const menuText = {
    no: 'Meny',
    en: 'Menu'
};

const searchText = {
    no: 'Søk',
    en: 'Search'
};

const closeText = {
    no: 'Lukk',
    en: 'Close'
};

class Menu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            searchOpen: false,
            showNav: !props.children,
        };

        this.handleResize = this.handleResize.bind(this);
        this.onToggleNav = this.onToggleNav.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onToggleSearch = this.onToggleSearch.bind(this);
        this.getModalStyle = this.getModalStyle.bind(this);
        this.getToggleLinkStyle = this.getToggleLinkStyle.bind(this);
        this.getLogoComponentClasses = this.getLogoComponentClasses.bind(this);
        this.renderLanguageLinks = this.renderLanguageLinks.bind(this);
        this.renderMenuContent = this.renderMenuContent.bind(this);
        this.renderNewsletter = this.renderNewsletter.bind(this);
        this.renderKHIBLogo = this.renderKHIBLogo.bind(this);
        this.renderBottomLanguageLinks = this.renderBottomLanguageLinks.bind(this);
        this.renderToggleLinks = this.renderToggleLinks.bind(this);

        this.isMenuOrSearchOpen = this.isMenuOrSearchOpen.bind(this);
        this.isSpecialSite = this.isSpecialSite.bind(this);
        this.isMobile = this.isMobile.bind(this);
        this.showAnchorMenu = this.showAnchorMenu.bind(this);
    }

    componentWillMount() {
        isNode && Modal.setAppElement('body');
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({
            innerWidth: window.innerWidth
        });
    }

    showAnchorMenu() {
        if (!this.isMobile()) {
            return false;
        }

        if (!this.state.menuOpen && !this.state.searchOpen) {
            return false;
        }

        if (this.state.showNav) {
            return false
        }

        if (!this.props.children) {
            return false;
        }
        if (this.props.children instanceof Array) {
            let hasAnchorNode = false;
            this.props.children.forEach(node => {
                if (node.type == 'ul') {
                    hasAnchorNode = true;
                    return
                }
            });
            return hasAnchorNode;
        }

        return this.props.children.type == 'ul';
    }

    isMenuOrSearchOpen() {
        return this.state.menuOpen || this.state.searchOpen;
    }

    isMobile() {
        return this.state.innerWidth < 1000;
    }

    isSpecialSite() {
        return this.props.language !== 'no' && this.props.language !== 'en'; // Hack: If language is not 'no' or 'en' we assume special site
    }

    onToggleNav() {
        this.setState({
            showNav: true,
        });
    }

    onToggleMenu() {
        this.setState({
            menuOpen: !this.state.menuOpen,
            searchOpen: false,
            showNav: !this.props.children,
        });
    }

    onToggleSearch() {
        this.setState({
            searchOpen: !this.state.searchOpen,
            menuOpen: false,
            showNav: !this.props.children,
        });
    }

    getModalStyle() {
        let background = 'white';
        if (this.isMobile()) {
            if (this.state.searchOpen) {
                background = 'white';
            } else if (this.state.menuOpen) {
                background = this.showAnchorMenu() ? 'black' : 'white';
            }
        }

        const modalStyle = {
            overlay: {
                zIndex
            },
            content: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                padding: 0,
                border: 'none',
                borderRadius: 0,
                background: background,
            }
        };

        return modalStyle;
    }

    getToggleLinkStyle() {
        return {
            color: this.showAnchorMenu() ? 'white' : 'black'
        };
    }

    renderLanguageLinks() {
        if (!this.props.languageLink || this.isSpecialSite()) {
            return null;
        }

        let color = this.showAnchorMenu() ? 'white' : 'black';

        return (
            <div className="languageLinks">
                {['no', 'en'].map(lang => {
                    const name = languageName[lang];
                    if (lang === this.props.language) {
                        return <span key={lang} style={{ color: color }}>{name}</span>
                    }

                    return <a href={this.props.languageLink} key={lang} style={{ color: color }} className="small-link">{name}</a>;
                })}
            </div>
        );
    }

    getLogoComponentClasses() {
        if (!this.isMobile()) {
            return 'logo';
        }

        if (this.showAnchorMenu()) {
            return 'logoMobile logo-white';
        }

        return 'logoMobile logo-black';
    }

    renderMenuContent() {
        return (
            <MenuContent
                language={this.props.language}
                links={this.props.links}
                nodes={this.props.children}
                mobile={this.isMobile()}
                title={this.props.displayName}
                showNav={this.state.showNav}
                onClickNav={this.onToggleNav}
                onCloseMenu={() => {
                    this.setState({
                        menuOpen: false,
                        searchOpen: false,
                        showNav: false,
                    })
                }}
                color={open && this.isMobile()}
                showAnchorMenu={this.showAnchorMenu()}
            />
        );
    }

    renderNewsletter() {
        return (
            <Newsletter
                language={this.props.language}
                useBlackLayout={this.isMobile() && !this.state.showNav}
            />
        );
    }

    renderKHIBLogo() {
        if (this.isMobile() && this.isMenuOrSearchOpen() && !this.state.showNav) {
            return (
                <div className="khib-logo-container">
                    <KHIBLogo language={this.props.language} />
                </div>
            );
        }
        return null;
    }

    renderBottomLanguageLinks() {
        if (this.isSpecialSite()) {
            return null;
        }

        return (
            <div className="siteLanguageLinks">
                {this.renderLanguageLinks()}
            </div>
        )
    }

    renderToggleLinks() {
        const men = this.state.menuOpen ? (closeText[this.props.language] || closeText['no']) : (menuText[this.props.language] || menuText['no']);
        const srch = this.state.searchOpen ? (closeText[this.props.language] || closeText['no']) : (searchText[this.props.language] || searchText['no']);

        return (
            <div className="toggleLinks" style={{ zIndex: this.isMenuOrSearchOpen() ? (zIndex + 1) : 1000 }}>
                <div>
                    <button className="toggleLink" onClick={this.onToggleMenu} style={this.getToggleLinkStyle()}>{men}</button>
                    <button className="toggleLink" onClick={this.onToggleSearch} style={this.getToggleLinkStyle()}>{srch}</button>
                </div>
                {!this.isMenuOrSearchOpen() && this.props.children}
            </div>
        )
    }

    render() {
        return (
            <div className="menu">
                {this.renderToggleLinks()}
                <Modal
                    contentLabel="Meny"
                    style={this.getModalStyle()}
                    isOpen={this.isMenuOrSearchOpen()}
                    onRequestClose={() => this.setState({ menuOpen: false, searchOpen: false, showNav: false })} >
                    <div className="menu">
                        <LogoComponent
                            wrapperClassName={this.getLogoComponentClasses()}
                            uri={this.props.mainPage}
                            logoWidth={this.isMobile() ? this.state.innerWidth : (this.state.innerWidth / 8)}
                        />
                        {this.state.menuOpen && (
                            <div>
                                {this.renderLanguageLinks()}
                                {this.renderMenuContent()}
                                {this.renderKHIBLogo()}
                                {this.renderNewsletter()}
                                <LinkLogo url={this.props.uibUrl} />
                            </div>
                        )}
                        {this.state.searchOpen && (
                            <div className="menu-content">
                                <Search url={this.props.searchUrl} language={this.props.language} />
                            </div>
                        )}
                    </div>
                </Modal>
                {this.renderBottomLanguageLinks()}
            </div>
        )
    }
}

const LogoComponent = ({ uri, wrapperClassName, logoWidth }) => {
    return (
        <div className={wrapperClassName}>
            <Logo width={logoWidth} mainPage={uri} />
        </div>
    );
}

const MenuContent = ({ language, links, nodes, mobile, title, onClickNav, onCloseMenu, showAnchorMenu }) => {
    return (
        <div className="menu-content">
            {showAnchorMenu ?
                <AnchorMenu
                    language={language}
                    nodes={nodes}
                    onClickMainMenuNav={onClickNav}
                    title={title}
                    onClickAnchor={onCloseMenu}
                />
                :
                links.map((link, i) => <Link mobile={mobile} link={link} key={i} />)
            }
        </div>
    );
}

module.exports = Menu;
