const isNode = (typeof process !== 'undefined');
const React = require('react');
const raf = isNode ? require('raf') : (() => { });
const stepper = require('./stepper');

const KUNST = 'KUNST';
const MUSIKK = 'MUSIKK';
const DESIGN = 'DESIGN';
const DEFAULT = 'DEFAULT';

const letters = [
    <svg viewBox="0 0 139.64 193.46" height="79px"><path d="M9.92,118.31,118.81,0H132.7L51.34,87.06l88.3,106.4H126.49L44.89,94l-35,37.46v62H0V0H9.92Z" /></svg>,
    <svg viewBox="0 0 152.29 195.94" height="79px"><path d="M0,129.23V0H10.17V131c0,33.49,15.13,55.81,66,55.81s66-22.32,66-55.81V0h10.17V129.23c0,40.68-18.85,66.72-76.15,66.72S0,169.9,0,129.23Z" /></svg>,
    <svg viewBox="0 0 139.14 193.46" height="79px"><path d="M38.69,58.53,8.93,11.9l.74,67.47V193.46H0V0H12.15l88.3,134.93,29.76,46.63-.74-67.46V0h9.67V193.46H127Z" /></svg>,
    <svg viewBox="0 0 141.87 198.43" height="79px"><path d="M0,152l10.17-1.49c3.47,25,16.87,38.94,60.77,38.94,27,0,60.77-9.92,60.77-45.14,0-29.52-11.16-40.43-58.53-45.64C32,94.25,6.7,87.55,6.7,51.34,6.7,14.14,37.95,0,71.18,0c47.87,0,65.73,17.86,69.2,44.65l-10.17,1.49c-3.47-24.31-19.84-37.2-59-37.2-18.85,0-54.07,5.7-54.07,42.41,0,25.3,11.66,33.49,56.8,38.2,47.13,5,68,15.38,68,54.82,0,38.44-34,54.07-70.94,54.07C20.09,198.43,3.72,179.33,0,152Z" /></svg>,
    <svg viewBox="0 0 141.38 193.46" height="79px"><path d="M75.65,193.46H65.73V8.93H0V0H141.38V8.93H75.65Z" /></svg>,
    <svg viewBox="0 0 174.11 193.45" height="79px"><path d="M26,58.78,8.93,17.11l.75,62.25V193.45H0V0H11.91l57,138.15L87.05,183l18.1-44.89L162.2,0h11.9V193.45h-9.67V79.37l.75-62.25L148.07,58.78,92.51,193.45H81.59Z" /></svg>,
    <svg viewBox="0 0 152.29 195.94" height="79px"><path d="M0,129.23V0H10.17V131c0,33.49,15.13,55.81,66,55.81s66-22.32,66-55.81V0h10.17V129.23c0,40.68-18.85,66.72-76.15,66.72S0,169.9,0,129.23Z" /></svg>,
    <svg viewBox="0 0 141.87 198.43" height="79px"><path d="M0,152l10.17-1.49c3.47,25,16.87,38.94,60.77,38.94,27,0,60.77-9.92,60.77-45.14,0-29.52-11.16-40.43-58.53-45.64C32,94.25,6.7,87.55,6.7,51.34,6.7,14.14,37.95,0,71.18,0c47.87,0,65.73,17.86,69.2,44.65l-10.17,1.49c-3.47-24.31-19.84-37.2-59-37.2-18.85,0-54.07,5.7-54.07,42.41,0,25.3,11.66,33.49,56.8,38.2,47.13,5,68,15.38,68,54.82,0,38.44-34,54.07-70.94,54.07C20.09,198.43,3.72,179.33,0,152Z" /></svg>,
    <svg viewBox="0 0 9.92 193.45" height="79px"><path d="M9.92,193.45H0V0H9.92Z" /></svg>,
    <svg viewBox="0 0 139.64 193.46" height="79px"><path d="M9.92,118.31,118.81,0H132.7L51.34,87.06l88.3,106.4H126.49L44.89,94l-35,37.46v62H0V0H9.92Z" /></svg>,
    <svg viewBox="0 0 139.64 193.46" height="79px"><path d="M9.92,118.31,118.81,0H132.7L51.34,87.06l88.3,106.4H126.49L44.89,94l-35,37.46v62H0V0H9.92Z" /></svg>,
    <svg viewBox="0 0 151.3 193.46" height="79px"><path d="M63.74,193.46H0V0H63.74C120,0,151.3,21.33,151.3,96.73S120,193.46,63.74,193.46Zm2.73-8.93c52.34,0,74.41-20.84,74.41-87.81S118.81,8.93,66.47,8.93H9.92V184.54Z" /></svg>,
    <svg viewBox="0 0 133.94 193.46" height="79px"><path d="M129,0V8.93H9.92V89.79H119.05v8.93H9.92v85.82h124v8.93H0V0Z" /></svg>,
    <svg viewBox="0 0 141.87 198.43" height="79px"><path d="M0,152l10.17-1.49c3.47,25,16.87,38.94,60.77,38.94,27,0,60.77-9.92,60.77-45.14,0-29.52-11.16-40.43-58.53-45.64C32,94.25,6.7,87.55,6.7,51.34,6.7,14.14,37.95,0,71.18,0c47.87,0,65.73,17.86,69.2,44.65l-10.17,1.49c-3.47-24.31-19.84-37.2-59-37.2-18.85,0-54.07,5.7-54.07,42.41,0,25.3,11.66,33.49,56.8,38.2,47.13,5,68,15.38,68,54.82,0,38.44-34,54.07-70.94,54.07C20.09,198.43,3.72,179.33,0,152Z" /></svg>,
    <svg viewBox="0 0 9.92 193.45" height="79px"><path d="M9.92,193.45H0V0H9.92Z" /></svg>,
    <svg viewBox="0 0 154.77 198.43" height="79px"><path d="M144.85,165V101.2H95.24V92.27h59.53v76.28c-17.35,21.07-40.16,29.88-72.92,29.88C24.8,198.43,0,160,0,99.21S24.8,0,81.85,0c46.88,0,66.72,21.33,72.43,50.6l-10.42,1.49c-5-27-23.56-43.66-62-43.16C31.5,9.67,10.42,42.66,10.42,99.21S31.49,189.5,81.85,189.5C113.1,189.5,131.72,180.06,144.85,165Z" /></svg>,
    <svg viewBox="0 0 139.14 193.46" height="79px"><path d="M38.69,58.53,8.93,11.9l.74,67.47V193.46H0V0H12.15l88.3,134.93,29.76,46.63-.74-67.46V0h9.67V193.46H127Z" /></svg>
];
const lastLetterWidth = 58;

const anchors = {
    [KUNST]: [0, 0.11859929846017954, 0.24794251789945726, 0.3661171554513721, 0.48661044156241245, 0.6066875599833533, 0.6559793671420038, 0.6990937736217631, 0.7392582023254431, 0.747728771908964, 0.7872618713956905, 0.826794970882417, 0.8696291011627215, 0.9075484891980421, 0.9477129179017221, 0.9561834874852431, 1],
    [MUSIKK]: [0, 0.03816833536967032, 0.0797943436298185, 0.11782601208682172, 0.15660388188698735, 0.19524781811273922, 0.33801827509956184, 0.46289629988000636, 0.5792299092805033, 0.6037643534425029, 0.7182693595515138, 0.8327743656605249, 0.8741297734335922, 0.9107401059988575, 0.9495179757990231, 0.9576961238530229, 1],
    [DESIGN]: [0, 0.041969349511149036, 0.08774070612138168, 0.12955977867209262, 0.17219936342679554, 0.21469167676026912, 0.2670211199240199, 0.3127924765342526, 0.35543206128895555, 0.3644246346015707, 0.4063939841127197, 0.4483633336238687, 0.5847847583095644, 0.705553334796029, 0.8334720890601378, 0.8604498089979833, 1],
    [DEFAULT]: [0, 0.0663817569012973, 0.13877704305496794, 0.2049211110530094, 0.2723629604628279, 0.3395718747474556, 0.42233990463921206, 0.49473519079288264, 0.5621770402027011, 0.576400344173532, 0.6427821010748294, 0.7091638579761266, 0.7810885201013505, 0.8447606235055309, 0.9122024729153494, 0.9264257768861802, 1]
};
const K_RANDOM_INITIAL = 1992700730;
const K_I_INITIAL = 389;

class Logo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            positions: anchors[props.mode].map(pos => pos * (props.width - lastLetterWidth)),
            velocities: letters.map(_ => 0),
            constants: {
                k: 234,
                b: 18,
                ki: K_I_INITIAL,
                krandom: K_RANDOM_INITIAL,
                fcap: 15036
            }
        };

        this.boost = this.boost.bind(this);
        this.updatePositions = this.updatePositions.bind(this);
    }

    boost() {
        clearInterval(this.boostId);
        let constants = this.state.constants;
        constants.ki = K_I_INITIAL;
        constants.krandom = K_RANDOM_INITIAL;
        this.setState({ constants });
        this.boostId = setInterval(() => {
            let constants = this.state.constants;
            constants.ki = constants.ki * 0.9;
            constants.krandom = constants.krandom * 0.9;
            if (constants.krandom < 0.01 * K_RANDOM_INITIAL) {
                constants.ki = 0;
                constants.krandom = 0;
                clearInterval(this.boostId);
            }
            this.setState({ constants })
        }, 50);
    }

    updatePositions() {
        this.animationId = raf(() => {
            const newValues = letters.map((_, index) => {
                const params = Object.assign({}, this.state.constants, { others: this.state.positions.filter((_, i) => i !== index), width: (this.props.width - lastLetterWidth) });
                return stepper(this.state.positions[index], this.state.velocities[index], anchors[this.props.mode][index] * (this.props.width - lastLetterWidth), params);
            });
            this.setState({ positions: newValues.map(val => val.newX), velocities: newValues.map(val => val.newV) });
            if (!newValues.every(result => result.done)) {
                this.updatePositions();
            }
        })
    }

    componentWillReceiveProps(newProps) {
        if (this.animationId != null) {
            raf.cancel(this.animationId);
        }
        this.updatePositions(newProps);
    }

    componentDidMount() {
        this.updatePositions(this.props);
        this.boost();
        window.addEventListener('scroll', this.boost);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.boost);
        clearInterval(this.boostId);
        if (this.animationId != null) {
            raf.cancel(this.animationId);
        }
    }

    render() {
        const style = this.props.style || {};
        style.position = 'relative';
        style.display = 'block';
        style.backgroundImage = 'none';
        style.height = 81;
        style.width = this.props.width;
        return (
            <a style={style} href={this.props.mainPage} title="Til forsiden">
                {letters.map((letter, index) => React.cloneElement(letter, { key: index, style: { position: 'absolute', left: this.state.positions[index] } }))}
            </a>
        );
    }
}

Logo.propTypes = {
    width: React.PropTypes.number.isRequired,
    mode: React.PropTypes.oneOf([KUNST, DESIGN, MUSIKK, DEFAULT]).isRequired
};

Logo.defaultProps = {
    mode: DEFAULT
};

module.exports = Logo;