const isNode = (typeof process !== 'undefined');
const React = require('react');
const ReactDOM = isNode ? require('react-dom') : {};

const Logo = require('../../../../webapp/components/Logo');
const Menu = require('../../../../webapp/components/Menu');
const Image = require('../../../../webapp/components/Image');

function scrollTop(){
    if (window.pageYOffset) return window.pageYOffset;
    return document.documentElement.clientHeight
        ? document.documentElement.scrollTop
        : document.body.scrollTop;
}

function addhttp(url) {
    if (!/^(f|ht)tps?:\/\//i.test(url)) {
        url = "http://" + url;
    }
    return url;
}

class PersonSite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {profileHeight: 250, loaded: false};
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
        this.handleScroll();
        this.handleResize();
        this.setState({loaded: true});
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    }

    handleScroll() {
        this.setState({scrollTop: scrollTop()});
    }

    handleResize() {
        this.setState({innerWidth: window.innerWidth});
    }


    render() {
        const {menu, name, lastname, website, images, bio, position, uibLink, uibLinkUrl} = this.props;
        const {innerWidth, scrollTop, profileHeight} = this.state;
        const fullName = name + ' ' + lastname;

        let logoWidth;
        if (innerWidth < 1000) {
            logoWidth = innerWidth;
        } else {
            if (scrollTop < profileHeight - 60) {
                logoWidth = innerWidth * 5 / 16;
            } else {
                logoWidth = innerWidth / 8
            }
        }

        return (
            <div className="person-site">
                <Menu {...menu}>
                    <div className="logo">
                        {this.state.loaded && <Logo width={logoWidth} mainPage={menu.mainPage}/>}
                    </div>
                </Menu>
                <div className="personInfo">
                    <div className="imageContainer">
                        <Image sources={images} ref="profilePic" onLoad={() => this.setState({profileHeight: ReactDOM.findDOMNode(this.refs.profilePic).getBoundingClientRect().height})}/>
                    </div>
                    <div className="contactDetails">
                        <h1 className="name">{fullName}</h1>
                        <p className="ingress" style={{marginBottom: 10}}>{position}</p>
                        {website && <div><a href={addhttp(website)}>{website}</a></div>}
                        {uibLink && <div><a href={uibLinkUrl ? addhttp(uibLinkUrl) : `http://www.uib.no/personer/${fullName.replace(/\s/g, '.')}`}>{menu.language === 'en' ? 'Contact info' : 'Kontaktinfo'}</a></div>}
                        {bio && (
                            <div style={{marginTop: 60}}>
                                <h3 className="bioHeader">Bio</h3>
                                <p>{bio}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

module.exports = PersonSite;