const isNode = (typeof process !== 'undefined');
const React = require('react');
const axios = isNode && require('axios');
const ReactStripeElements = isNode && require('react-stripe-elements');
const DynamicInputForm = require('./DynamicInputForm');
const RedirectToStripeCheckout = require('./RedirectToStripeCheckout');

const STATES = {
    Registration: 'REGISTRATION',
    Checkout: 'CHECKOUT',
    Failure: 'FAILURE',
    Success: 'SUCCESS',
};

class TicketForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentState: STATES.Registration,
            sessionId: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderDynamicInputForm = this.renderDynamicInputForm.bind(this);
        this.renderRedirectToStripeCheckout = this.renderRedirectToStripeCheckout.bind(this);
    }

    componentWillMount() {
        if (isNode) {
            const params = new URLSearchParams(window.location.search);
            const success = params.get('success');

            if (success == 'true') {
                this.setState({ currentState: STATES.Success });
                return;
            }
            if (success == 'false') {
                this.setState({ currentState: STATES.Failure });
                return;
            }
        }
    }

    handleSubmit(data, ticket) { 
        axios.post(this.props.form.actionUrl, {
            ...data,
            sku: ticket,
        })
        .then(res => {
            console.log(res);
            this.setState({ 
                currentState: STATES.Checkout,
                sessionId: res.data.stripeID,
            });
        })
        .catch(err => {
            console.error(err);
            this.setState({ currentState: STATES.Failure });
        });        
    }

    renderDynamicInputForm() {
        return (            
            <DynamicInputForm 
                onSubmit={this.handleSubmit}
                submitButtonText={this.props.form.submitText}
                header={this.props.name}
                inputs={this.props.form.inputs}
                tickets={this.props.tickets}
            />
       );
    }

    renderRedirectToStripeCheckout() {
        return (
            <ReactStripeElements.StripeProvider apiKey={this.props.apiKey}>
                <ReactStripeElements.Elements>
                    <RedirectToStripeCheckout
                        sessionId={this.state.sessionId}                        
                    />
                </ReactStripeElements.Elements>
            </ReactStripeElements.StripeProvider>
        );
    }

    renderFailure() {
        return (
            <p style={{ color: 'red' }}>
                {this.props.failureMessage}
            </p>
        );
    }

    renderSuccess() {
        return (
            <p style={{ color: '#4BB543' }}>
                {this.props.successMessage}
            </p>
        );
    }

    render() {
        switch (this.state.currentState) {
            case STATES.Registration:
                return this.renderDynamicInputForm();
            case STATES.Checkout:
                return this.renderRedirectToStripeCheckout();
            case STATES.Success:
                return this.renderSuccess();
            case STATES.Failure:
            default: 
                return this.renderFailure();
        }            
    }
}

module.exports = TicketForm;