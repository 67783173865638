const React = require('react');
const util = require('../util');

const Filter = ({ label, name, allText, filters, }) => {    
    const current = util.parseQuery()[name];
    return (
        <div>
            {label && <p className="small filterHeader">{label}:</p>}
            <ul className="list">
                {current ? <a className="small-link" href={util.allHref(name)}>{allText}</a> : <span>{allText}</span>}
                {filters.map((filter, i) => {
                    return (
                        <FilterItem 
                            key={i} 
                            filter={filter} 
                            href={util.makeHref(name, filter.id)} 
                            value={filter.name}
                            selected={current == filter.id}
                        />
                    );
                })}
            </ul>
        </div>
    );
}

const FilterItem = ({ selected, filter, key, href, value }) => {
    if (selected) {
        return (
            <li key={key}>
                <span>{filter.name}</span>
            </li>
        );
    }

    return (
        <li key={key}>
            <a className="small-link" href={href}>{value}</a>
        </li>
    );
}

Filter.defaultProps = {
    allText: 'Alle'
};

module.exports = Filter;