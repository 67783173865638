const React = require('react');
const pickRandom = require('./pickRandom');

function ensureArray(thing) {
    return Array.isArray(thing) ? thing : [thing];
}

class RandomGrid extends React.Component {
    render() {
        return (
            <div {...this.props}>
                {ensureArray(this.props.children).map((child, index) => {
                    const [width, marginRight] = [
                        {
                            '18.75vw': 1/ 3,
                            '31.25vw': 1 / 3,
                            '50vw': 1 / 3
                        },
                        {
                            '6.25vw': 0.8,
                            '12.5vw': 0.2
                        }
                    ].map(pickRandom);
                    return (
                        <div key={index} style={{display: 'inline-block', verticalAlign: 'top', marginBottom: 40, width, marginRight}} className="content">
                            {child}
                        </div>
                    )
                })}
            </div>
        );
    }
}

module.exports = RandomGrid;