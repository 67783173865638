const React = require('react');
const Logo = require('../../../../webapp/components/Logo');
const Menu = require('../../../../webapp/components/Menu');

class PlaceholderHeader extends React.Component {

    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);
        this.getContexLogoWidth = this.getContexLogoWidth.bind(this);

        this.state = {
            logoWidth: window.innerWidth,
        };
    }

    handleResize() {
        this.setState({
            logoWidth: window.innerWidth, 
        });
    }

    /**
     * Set width to full window size on small screens.
     * Used fixed width on large screens.
     * 
     * This is good for now. Might want to add a few more breakpoints later..
     */
    getContexLogoWidth() {
        if (this.state.logoWidth > 2100) {
            return 300;
        }
        if (this.state.logoWidth > 1750) {
            return 250;
        }
        if (this.state.logoWidth > 1600) {
            return 225;
        }
        if (this.state.logoWidth > 1400) {
            return 200;
        }
        if (this.state.logoWidth > 1200) {
            return 175;
        }
        if (this.state.logoWidth > 1100) {
            return 150;
        }
        if (this.state.logoWidth > 1000) {
            return 125;
        }
        return this.state.logoWidth;
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize');
    }

    render() {
        return (
            <div className="placeholder-header">
                <div className="placeholder-header-content">
                    <div className="logo-wrapper">
                        <Logo width={this.getContexLogoWidth()} mainPage={this.props.menu.mainPage} />
                    </div>
                    <Menu {...this.props.menu} />
                </div>
                <div className="header-content">
                    <h1>{this.props.header}</h1>
                </div>
            </div>

        );
    }
}

module.exports = PlaceholderHeader;