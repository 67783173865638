function scrollTop(){
    if (window.pageYOffset) return window.pageYOffset;
    return document.documentElement.clientHeight
        ? document.documentElement.scrollTop
        : document.body.scrollTop;
}

module.exports = function handleAnchor() {
    const hash = window.location.hash;
    if (hash) {
        const element = document.querySelector(hash);
        if (element) {
            setTimeout(() => window.scrollTo(0, scrollTop() + element.getBoundingClientRect().top), 1000);
        }
    }
};