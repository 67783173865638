const React = require('react');
const Menu = require('../../../webapp/components/Menu')
const Logo = require('../../../webapp/components/Logo')

class Error extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loaded: false};
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.setState({loaded: true});
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({innerWidth: window.innerWidth});
    }

    render() {
        return(
            <div className="error-page">
                <div className="logo">
                    {this.state.loaded && (
                        <Logo width={this.state.innerWidth < 1000 ? this.state.innerWidth : (this.state.innerWidth / 8)} mainPage={this.props.menu.mainPage}/>
                    )}
                </div>
                <Menu {...this.props.menu} />
            </div>
        )
    }
}

module.exports = Error;
