const React = require('react');

const musikkLogo = (
    <svg x="0px" y="0px" viewBox="0 0 618 198.4">
        <title>Universitetet i Bergen - Fakultet for kunst, musikk og design</title>
        <path d="M608.3,2.5v114.1l0.7,67.5l-8.8-13.8c0.5-0.6,0.9-1.1,1.4-1.7V92.3h-22.7c-1.1-39.5-11.4-63-30-76v-1.9
   c14.5,5.9,21.6,16.6,23.7,31.8l10.1-1.5c-1.1-8.6-3.7-16.3-8.3-22.8c8.7,7.4,13.9,17.7,16.2,30.2l10.4-1.5
   c-3.2-16.3-10.7-30.1-24.8-39.2H591V2.5h-36.9C546.9,0.9,538.6,0,529.3,0c-2.3,0-4.4,0.1-6.6,0.2C520,0.1,517.1,0,514.2,0
   c-7.8,0-15.4,0.8-22.6,2.5h-62.8v46l-7.4,8.1V2.5h-9.9v64.9l-5.2,5.7V44.7l0,0c0-0.1,0-0.2,0-0.3V2.5h-9.9v17.3
   C386.4,7.4,368.2,0,337.6,0c-33,0-64.1,14.1-64.1,51.3c0,35.6,24.3,42.7,64,47.1v35c0,25-8.4,43.8-33.8,51.7
   c-17.6-5.9-24.4-17.7-26.7-34.6l-10.1,1.5c2.1,15.3,8.1,28,21.9,36.2c-5.1,0.6-10.7,1-16.8,1c-5.4,0-10.4-0.3-15.1-0.8V2.5h-11.8
   l-38.7,94.2V2.5h-10.1v118.8l-5.3,13c-1.7-15.3-7.6-25.3-18.3-32V26.9c3.8,5.3,6.1,11.7,7.2,19.3l10.1-1.5
   c-1.7-13.2-6.9-24.2-17.3-32v-1.3h11.8V2.5h-11.8h-10.1h-3h-9.6h-1.7C140.7,0.9,131.7,0,121.3,0c-7.8,0-15.4,0.8-22.6,2.5H44.1v8.9
   h33.3c-9.7,6.5-16.7,16-19.1,29.1l-24.7-38H21.4v105.7L9.9,120.8V2.5H0v193.5h9.9v-62l11.6-12.5v10.2v64.2H31v-24.2
   c10.7,16.9,31.2,26.7,66.1,26.7c4.1,0,8-0.2,11.8-0.4c3.8,0.3,7.9,0.4,12.2,0.4c8.3,0,16.4-0.8,24.1-2.5h14.6v-4.6
   c1-0.4,1.9-0.9,2.9-1.3l2.4,5.9h10.8l21.2-51.7c3.8,33.4,24.1,54.2,75,54.2c12.6,0,23.4-1.3,32.4-3.7c8.9,2.4,19.7,3.7,32.9,3.7
   c23.7,0,46.2-6.5,59.1-21.5v19h9.9v-38.2c1-4.1,1.5-8.6,1.5-13.4c0-6.1-0.5-11.6-1.5-16.4v-20.6l5.2,6.4v82.2h9.9v-62l3.5-3.8
   l3.9,4.8v61h57.4c7.8,1.6,17,2.5,27.7,2.5c2.7,0,5.3-0.1,7.9-0.3c2.4,0.2,4.9,0.3,7.4,0.3c9.8,0,18.7-0.8,26.8-2.5H593V187h-12.7
   c4.8-2.7,9.3-6,13.4-9.9l12.2,18.8H618V2.5H608.3z M181.4,144.4c0,14.7-5.9,25-14.7,32l-2.6-6.4c6-10.4,8.6-23.4,8.6-38.3V115
   C179.3,121.9,181.4,131.3,181.4,144.4z M93.5,186.9v-30.4l15.9,19.5v12.8c-0.8,0.1-1.6,0.1-2.4,0.2C102,188.5,97.5,187.8,93.5,186.9
   z M92.8,19.6l3,7.3L92.9,30L92.8,19.6z M100.4,11.4h9V12l-6.7,7.4l-3.1-7.7C99.8,11.6,100.1,11.5,100.4,11.4z M150.1,12.3v81.3
   c-5.5-1.3-11.6-2.3-18.2-3.2l-12.6-30.7V16.1l4.3-4.6h23.2C147.9,11.7,149,12,150.1,12.3z M119.3,172.3v-20l18.9,29.1
   c-2.6,1.3-5.4,2.5-8.5,3.5L119.3,172.3z M146,176.4l-24.7-39l-2-3.1V98.3c1.3,0.2,2.7,0.3,4,0.5c0.7,0.1,1.3,0.2,2,0.2l25.3,61.8
   l0.1,11.2C149.3,173.5,147.7,175,146,176.4z M93.5,94c4.9,1.2,10.2,2.1,15.9,3v22.2L93.5,94.8V94z M119.3,84.4l2,4.9
   c-0.7-0.1-1.3-0.2-2-0.2V84.4z M159.7,155.7v-49.2c1,0.4,2,0.9,3,1.3v25.6C162.7,141.6,161.8,149,159.7,155.7z M150.3,135.4
   l-14.4-35c5.3,0.8,10,1.8,14.2,2.9v13.3L150.3,135.4z M109.4,35.8l-2.6-6.3l2.6-2.8V35.8z M99.9,36.9l9.5,23.4v27.4
   c-6.1-0.9-11.3-2-15.9-3.3v-2.6l-0.4-37.6L99.9,36.9z M83.9,80L74,64.8l9.9-10.7V80z M77.7,88.5l6.2,9.5v31.5L51,89.5l12.4-13.4
   C66.9,81.5,71.7,85.4,77.7,88.5z M93.5,112.8l15.9,24.4v23.2l-15.9-19.2V112.8z M159.7,96.3V16c1,0.5,2,1.1,3,1.7v79.7
   C161.7,97,160.7,96.6,159.7,96.3z M67.6,51.3c0-16.4,7-26.6,16.3-32.8v21.3L68.6,56.5l-1-1.5C67.6,53.8,67.6,52.6,67.6,51.3z
    M57.5,57.2c0.3,3.5,0.9,6.7,1.7,9.6L31.5,97.1V16.3L57.5,57.2z M31.5,133.4v-22.9l13.1-14.1l39.3,48.2v39.2
   c-15-6.3-21.1-17.6-23.2-33.3L50.6,152c1.9,14.1,7.2,26,18.9,34.3C40.9,179.2,31.5,159.8,31.5,133.4z M206.4,133.4v-12.1l24.6-60
   l17-41.7l-0.7,62.3V187C216.4,180.5,206.4,160.7,206.4,133.4z M591.9,156.9l-7.4-11.7c0-0.3,0-0.5,0-0.8c0-11.9-1.9-21.2-5.8-28.5
   c0.3-4.6,0.5-9.6,0.6-14.7h12.7V156.9z M438.7,115.3l9.3-10.1c0.5,19,3.6,35.6,9.5,49.4l-18.9-22.9V115.3z M536.7,179.4L489.3,122
   v-20.8h44.1c2,0.4,4,0.8,5.8,1.2V178C538.4,178.5,537.5,179,536.7,179.4z M506.4,186.6c-6.4-1.7-12-4.3-17.1-7.6v-41.9l37.5,46
   C520.7,185,513.9,186.1,506.4,186.6z M469.7,157.1v-43.9l9.9,12.2v45.8C475.8,167.1,472.5,162.4,469.7,157.1z M479.7,92.3h-9.9V88
   c3,1.6,6.3,2.9,9.9,4.1V92.3z M479.7,101.2v9.1l-7.5-9.1H479.7z M472.7,78.7l7-7.6v11.1C477,81.1,474.7,80,472.7,78.7z M469.7,68
   V41.6c2.8-5.2,6.1-9.9,9.9-13.9v29.4L469.7,68z M536.5,92.3c-5.9-1.1-12.4-2-19.6-2.7c-11.2-1.2-20.2-2.6-27.6-4.4v-3.3L489.1,61
   l17.3-18.6L518.1,61l20.3,31.3H536.5z M488.9,47l-0.3-26.8c0.8-0.6,1.7-1.1,2.5-1.6l9.7,15.4L488.9,47z M499.5,14.4
   c2.4-1,4.9-1.8,7.6-2.5c4.4,0.3,8.6,0.9,12.5,1.6l-12.1,13.2L499.5,14.4z M458.3,99.1l1.6,2v23.1c-1.1-7.7-1.6-16.1-1.6-25
   C458.3,99.2,458.3,99.1,458.3,99.1z M549.1,108.7l16.9,26c-3,16-8.6,27.7-16.9,35.9V108.7z M563.7,113.1c1.7,1.5,3.2,3.1,4.4,4.9
   c0,0.6-0.1,1.1-0.1,1.7L563.7,113.1z M568.8,104.4c-1.5-1.2-3.1-2.2-4.9-3.2h4.9C568.8,102.3,568.8,103.4,568.8,104.4z M539.3,75.5
   L513,35.2l17.4-18.8c3.1,1.1,6.1,2.4,8.8,4V75.5z M451.3,63.8c-1.8,8.6-2.9,17.9-3.2,27.8l-9.4,10.3V52l12.9-13.9
   c-1,4-1.5,8.4-1.5,13.2C450.1,56,450.5,60.1,451.3,63.8z M549.1,182.8v-0.6c11.2-7.8,19.4-19.5,24.3-36.1l0.8,1.2
   C573.2,165.6,562.7,176.6,549.1,182.8z M568.7,92.3h-18.6l-1-1.6V27.8C561.7,40.3,568,60.7,568.7,92.3z M438.7,11.4h21.2v3.1
   l-21.2,23.2V11.4z M428.8,62.7v50l-3.1,3.4l-4.3-5.2V70.6L428.8,62.7z M396.3,46.1l0.2,0v37.7l-13.8,15c-5.7-2.6-12.3-4.5-20-6.1
   V11.3C383.9,16.1,393.7,27.9,396.3,46.1z M362.7,102.4c5,1.2,9.4,2.5,13.2,4l-13.2,14.4V102.4z M347.7,9.3c1.8,0.1,3.5,0.3,5.2,0.5
   v81.4c-1.7-0.2-3.4-0.5-5.2-0.7V9.3z M283.9,51.3c0-36.7,34.9-42.4,53.6-42.4v80.3C295,84.5,283.9,76.1,283.9,51.3z M320,188.6
   c20.1-11.1,27.7-31,27.7-56.9v-32c1.8,0.2,3.5,0.5,5.2,0.8v87.9c-5.2,0.8-10.4,1.1-15.5,1.1C330.9,189.5,325.1,189.2,320,188.6z
    M362.7,186.3v-52.3l21.5-23.1c6.8,4.7,10.6,10.9,12.3,19.1v25.5C392.3,172.7,378.5,181.9,362.7,186.3z M390.9,103.5l5.6-6v10.8
   C394.8,106.5,393,104.9,390.9,103.5z M406.4,92.6v-5.7l5.2-5.6v17.6L406.4,92.6z M438.7,187v-40l4.9,6.1
   c1.9,12.6,6.6,23.4,16.3,31.3v2.6H438.7z M566.5,183.2c7.5-5.9,13-13.7,15.9-23.5l5.9,9.1C582.3,174.8,575.2,179.7,566.5,183.2z"/>
    </svg>
);

module.exports = musikkLogo;