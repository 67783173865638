const React = require('react');

class Link extends React.Component {
    render() {
        const {size, text, href} = this.props;
        return (
            <a href={href} className={`link-${size}`}>
                <span>{text}</span>
                <span className="arrow">→</span>
            </a>
        );
    }
}

module.exports = Link;