const React = require('react');
const EventMini = require('../../event-mini/Component/index');

const styles = [15, 7, 11, 11, 7, 7, 7].map(n => ({ width: `${100 * n / 32}vw` }));

function getStyle(index) {
  return styles[index % styles.length]
}

const NO_UPCOMING_EVENTS_TEXT = {
  en: 'There are currently no upcoming events',
  no: 'For øyeblikket er det ingen kommende arrangementer',
};

const CLICK_TO_SEE_PREVIOUS_TEXT = {
  en: 'click here to see previous events',
  no: 'klikk her for å se tidligere arrangementer',
};

class CalendarList extends React.Component {
  render() {
    if (!this.props.events.length) {
      return (
        <div className="calendar-list">
                    <span>
                        {`${NO_UPCOMING_EVENTS_TEXT[this.props.language]}, `}
                      <a href={`${this.props.calendarUrl}?time=past`}>{CLICK_TO_SEE_PREVIOUS_TEXT[this.props.language]}</a>
                    </span>
        </div>
      );
    }

    return (
      <div className="calendar-list">
        {this.props.events.map((event, index) => {
          return (
            <div key={index} className="content list-item" style={getStyle(index)}>
              <EventMini {...event}/>
            </div>
          );
        })}
      </div>
    );
  }
}

module.exports = CalendarList;
