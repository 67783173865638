const isNode = (typeof process !== 'undefined');

function sort(ints) {
    return ints.sort((a, b) => a - b);
}

module.exports = function getImageSource(sources, innerWidth = isNode ? window.innerWidth : 100) {
    if(!sources) return '';
    const widths = Object.keys(sources).map(n => parseInt(n));
    const sorted = sort(widths.filter(width => width >= innerWidth));
    const optimal = sorted.length > 0 ? sorted[0] : sort(widths).reverse()[0];
    return sources[optimal] || '';
};
