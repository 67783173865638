const React = require('react');

const formValues = {
    no: {
        label: 'Nyhetsbrev',
        placeholder: 'Din e-post',
        formId: '4147514A7545405D477040',
        mailingListName: '921806'
    },
    en: {
        label: 'Newsletter',
        placeholder: 'Your e-mail',
        formId: '4147514A74424B5A447340',
        mailingListName: '1260794'
    }

};

class Newsletter extends React.Component {

    constructor(props) {
        super(props);
        this.getContainerStyle = this.getContainerStyle.bind(this);
        this.getEmailStyle = this.getEmailStyle.bind(this);
        this.getSubmitStyle = this.getSubmitStyle.bind(this);
    }

    getContainerStyle() {
        if (!this.props.useBlackLayout) {
            return null;
        }

        return {
            color: 'white',
            background: 'black',
        };
    }

    getEmailStyle() {
        if (!this.props.useBlackLayout) {
            return null;
        }

        return {
            borderBottom: '2px solid #FFF',
            background: 'none',
        };
    }

    getSubmitStyle() {
        if (!this.props.useBlackLayout) {
            return null;
        }

        return {
            border: '2px solid white',
            color: 'white',
        }
    }

    render() {
        const values = formValues[this.props.language];

        if (!values) {
            return null;
        }
    
        return (
             <div className="subscribe" style={this.getContainerStyle()}>
                 <span className="label">{values.label}:</span>
                 <form name="SubscriberForm" action={`https://www.anpdm.com/public/process-subscription-form.aspx?formId=${values.formId}`} method="post">
                     <input type="email" name="pf_Email" placeholder={values.placeholder} className="email" style={this.getEmailStyle()} />
                     <input type="submit" name="Submit" value="Send" className="submit" style={this.getSubmitStyle()} />
                     <input type="hidden" name="pf_DeliveryFormat" value="HTML"/>
                     <input type="hidden" name="pf_FormType" value="OptInForm"/>
                     <input type="hidden" name="pf_OptInMethod" value="SingleOptInMethod"/>
                     <input type="hidden" name="pf_CounterDemogrFields" value="0"/>
                     <input type="hidden" name="pf_CounterMailinglists" value="1"/>
                     <input type="hidden" name="pf_AccountId" value="18155"/>
                     <input type="hidden" name="pf_ListById" value="1"/>
                     <input type="hidden" name="pf_Version" value="2"/>
                     <input type="hidden" name="pf_MailinglistName1" value={values.mailingListName}/>
                 </form>
             </div>
        );
    }
}

module.exports = Newsletter;
