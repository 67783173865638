const React = require('react');
const PublicationMini = require('../../publication-mini/Component');

class PublicationList extends React.Component {
    render() {
        const {publications} = this.props;
        return (
            <div className="publication-list">
                {Object.keys(publications).sort().reverse().map(year => {
                    return (
                         <div key={year}>
                             <h4 className="year">{year}</h4>
                             <div>
                                 {publications[year].map((publication, index) => <div key={index} className="item"><PublicationMini {...publication}/></div>)}
                             </div>
                         </div>
                    );
                })}
            </div>
        );
    }
}

module.exports = PublicationList;