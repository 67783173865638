const isNode = (typeof process !== 'undefined');
const React = require('react');
const axios = isNode && require('axios');
const debounce = isNode ? require('lodash').debounce : () => ({});

const components = {
    'khib:event': require('../../../../site/parts/event-mini/Component'),
    'khib:work': require('../../../containers/WorkMini'),
    'khib:nyhet': require('../../../../site/parts/news-mini/Component'),
    'khib:person': require('../../../../site/parts/person-mini/Component'),
    'khib:landing-page': require('../../../../site/parts/image-link/Component')
};

const placeholderText = {
    no: 'Hva leter du etter?',
    en: 'What are you looking for?'
};

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.state = {
            hits: [], 
            suggestions: [],
        };
    }

    componentDidMount() {
        this.debounceSearch = debounce(this.search, 300);
        setTimeout(() => this.refs.input.focus(), 0);
    }

    search(query) {
        axios.get(`${this.props.url}?query=${encodeURI(query)}`).then(response => this.setState(response.data));
    }

    render() {
        return (
            <div className="search">
                <form onSubmit={event => event.preventDefault()}>
                    <input type="text" ref="input" className="input" placeholder={placeholderText[this.props.language]} onChange={event => this.debounceSearch(event.target.value)}/>
                </form>
                <ul className="hits">
                    {this.state.hits.map((hit, index) => {
                        return (
                            <li key={`${hit._id}-${index}`} className="hit">
                                {React.createElement(components[hit.type], hit)}
                            </li>
                        )
                    })}
                </ul>
            </div>

        );
    }
}

module.exports = Search;