const React = require('react');

class FactsBox extends React.Component {
    render() {
        const {value1, key1, value2, key2, value3, key3, value4, key4} = this.props;
        return (
            <div className="fact-box">
                <div className="fact">
                    <p className="value">{value1}</p>
                    <p className="key">{key1}</p>
                </div>
                <div className="fact">
                    <p className="value">{value2}</p>
                    <p className="key">{key2}</p>
                </div>
                <div className="fact">
                    <p className="value">{value3}</p>
                    <p className="key">{key3}</p>
                </div>
                <div className="fact">
                    <p className="value">{value4}</p>
                    <p className="key">{key4}</p>
                </div>
            </div>
        );
    }
}

module.exports = FactsBox;