const isNode = (typeof process !== 'undefined');
const queryString = isNode ? require('query-string') : () => ({});

/**
 * If client side parse query from window object
 */
function parseQuery() {
    return isNode ? queryString.parse(window.location.search) : {};
}

function allHref(name) {
    if (!isNode) {
        return '';
    }

    const query = parseQuery();

    delete query[name];

    return window.location.pathname + '?' + queryString.stringify(query);
}

function makeHref(name, filter) {
    if (!isNode) {
        return '';
    }

    const query = parseQuery();

    query[name] = filter;
    
    return window.location.pathname + '?' + queryString.stringify(query);
}

module.exports = {
    parseQuery,
    allHref,
    makeHref,
}