const React = require('react');

function pickRandom(list) {
    const index = Math.floor(Math.random() * list.length);
    return list[index];
}

function randomWidth() {
    return `${pickRandom([4, 5, 6]) * 100 / 16 - 2}vw`
}

class Instagram extends React.Component {
    render() {
        if (this.props.error) {
            return null;
        }
        return (
             <div className="instagram">
                 <div className="link-container">
                     <a href="https://www.instagram.com/kmdbergen/" className="large-link" target="_blank">@kmdbergen</a>
                 </div>
                 {this.props.posts.map((post, i) => {
                     return (
                          <a key={i} href={post.link} target="_blank" className="content post" style={{width: randomWidth()}}>
                              <figure>
                                  <img src={post.image}/>
                                  <figcaption>{post.text}</figcaption>
                              </figure>
                          </a>
                     );
                 })}
             </div>
        );
    }
}

module.exports = Instagram;