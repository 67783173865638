const isNode = (typeof process !== 'undefined');
const React = require('react');
const getImage = require('../../getImage');
const moment = isNode ? require('moment') : () => ({format: () => '', isSame: () => false});

const locale = {
    no: 'nb',
    en: 'en'
};

class EventMini extends React.Component {
    constructor(props) {
        super(props);

        moment.locale && moment.locale(locale[props.language]);

        this.formatDay = this.formatDay.bind(this);
    }

    formatDay(day) {
        const formatted = day.format('D');
        
        let suffix = '';
        if (this.props.language === 'no') {
            suffix = '.';
        }

        return formatted + suffix;
    }

    render() {
        const {images, focalPoint, category, header, href} = this.props;
        const from = moment(this.props.from);
        const to = moment(this.props.to);

        return (
            <a href={href} className="event-mini">
                <div className="topBox">
                    <div className="dateBox">
                        <div className="dateBoxText">
                            <div className="start-date">
                                <span className="day">{this.formatDay(from)}</span>
                                <span className="month">{from.format('MMM')}</span>
                            </div>
                            {!to.isSame(from) && (
                                <span className="arrow" aria-label="til">↓</span>
                            )}
                            {!to.isSame(from) && (
                                <div className="end-date">
                                    <span className="day">{this.formatDay(to)}</span>
                                    <span className="month">{to.format('MMM')}</span>
                                </div>
                            )}                            
                        </div>
                    </div>
                    <div className="imgBox" style={{backgroundImage: `url(${getImage(images)})`, backgroundPosition: `${100 * focalPoint.x}% ${100 * focalPoint.y}%`}}></div>
                </div>
                <div className="bottomBox">
                    <p>{category.join(', ')}</p>
                    <span className="large-link" style={{fontWeight: 500}}>{header}</span>
                </div>
            </a>

        )
    }
}

module.exports = EventMini;