const React = require('react');

function getNavText(lang) {
    const txt = (lang == 'no') ? 'Tilbake til hovedmeny' : 'Back to main menu';
    return '← ' + txt;
}

const AnchorMenu = ({ nodes, onClickMainMenuNav, title, language, onClickAnchor }) => {
    return (
        <div className="anchor-menu">
            <div className="back-to-main-menu">
                <button onClick={onClickMainMenuNav}>{getNavText(language)}</button>
            </div>
            <div className="title">
                <h1>{title}</h1>
            </div>
            <div className="nodes" onClick={(e) => {                
                // If <a> element is clicked, close the menu
                if (e.target.nodeName === 'A') {  
                    const section = document.querySelector(e.target.hash);                
                    onClickAnchor();
                    e.preventDefault();
                    window.scrollTo(0, window.scrollY + section.getBoundingClientRect().top - 140);
                }
            }}>
                {nodes}
            </div>
        </div>
    );
}

module.exports = AnchorMenu;
