module.exports = function(fullName, href, uibLink, uibLinkUrl, menu) {
  let linkTo = href;

  if (uibLink) {
      if (uibLinkUrl) {
          linkTo = uibLinkUrl;
      } else {
          const n = fullName.replace(/\s/g, '.');
          if (menu && menu.language == 'en') {
              linkTo = `http://www.uib.no/en/persons/${n}`
          } else {
              linkTo = `http://www.uib.no/personer/${n}`
          }

            // window.location.href = linkTo
      }
  }

  return linkTo;
}
