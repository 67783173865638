const React = require('react');
const getImage = require('../../getImage');
const getURL = require('../../../../lib/getURL');

const PersonMini = ({ name, lastname, href, images, focalPoint, position, department, uibLink, uibLinkUrl, menu }) => {
    const fullName = `${name} ${lastname}`;
    const linkTo = getURL(fullName, href, uibLink, uibLinkUrl, menu);
    const backgrounPosition = focalPoint ? `${100 * focalPoint.x}% ${100 * focalPoint.y}%` : '50% 50%';

    return (
        <a href={linkTo} className="person-mini" target="_blank">
            <div className="imageBox" style={{
                backgroundImage: `url("${getImage(images, 200)}")`,
                backgroundPosition: backgrounPosition
            }}></div>
            <div className="infoBox">
                <div className="department">
                    <span>{department}</span>
                </div>
                <span className="name">{fullName}</span>
                <p className="position">{position}</p>
            </div>
        </a>
    );
}

module.exports = PersonMini;
