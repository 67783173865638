const React = require('react');
const NewsMini = require('../../news-mini/Component');
const Link = require('../../link/Component');

const typeName = {
    general: {
        no: 'nyheter',
        en: 'news'
    },
    pressRelease: {
        no: 'pressemeldinger',
        en: 'press releases'
    },
    mediaCoverage: {
        no: 'medieomtaler',
        en: 'media coverage'
    }
};

class NewsPreview extends React.Component {
    render() {
        const {language, type, newsPage, news} = this.props;
        const name = typeName[type][language] || typeName[type].no;
        return (
            <div className="news-preview">
                <h3>{language === 'en' ? 'Latest' : 'Siste'} {name}</h3>
                <div>
                    {news.map((n, i) => <div key={i} className="item"><NewsMini key={n._id} {...n} hideIngress={true}/></div>)}
                </div>
                <div className="link-container">
                    <Link href={newsPage + `?type=${type}`} text={`${language === 'en' ? 'All' : 'Alle'} ${name}`} size="large"/>
                </div>
            </div>
        );
    }
}

module.exports = NewsPreview;