const React = require('react');
const util = require('../util');

class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        
        this.renderItem = this.renderCategory.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);

        this.state = {
            open: false,
        };
    }

    handleClick() {
        this.setState({ 
            open: !this.state.open,
        });
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                open: false,
            });
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    renderCategory(category, key) {
        return (
            <a href={util.makeHref(this.props.name, category.id)} key={key}>{category.name}</a>
        )
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    render() {
        const queryAttributeTime = util.parseQuery()['time'];

        return (
            <div className="dropdown" ref={this.setWrapperRef}>
                <div className="time">
                    {queryAttributeTime ? <a className="small-link" href={util.allHref('time')}>{this.props.upcomingText}</a> : <span>{this.props.upcomingText}</span>}
                    {!queryAttributeTime ? <a className="small-link" href={util.makeHref('time', 'past')}>{this.props.previousText}</a> : <span>{this.props.previousText}</span>}
                </div>
                <div onClick={() => this.handleClick()} className="selector">
                    <span>{this.state.selectedItem || this.props.placeholder}</span>
                    <i className={this.state.open ? 'up' : 'down'} />
                </div>
                {this.state.open && (
                    <div className="items">
                        {this.props.categories.map((item, i) => this.renderCategory(item, i))}
                    </div>
                )}
                
            </div>
        )
    }
}

module.exports = Dropdown;