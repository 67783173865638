const isNode = (typeof process !== 'undefined');
const React = require('react');
const ReactDOM = isNode ? require('react-dom') : {};

const Image = require('../../../../webapp/components/Image');
const Menu = require('../../../../webapp/components/Menu');
const Logo = require('../../../../webapp/components/Logo');
const handleAnchor = require('../../handleAnchor');

function scrollTop(){
    if (window.pageYOffset) return window.pageYOffset;
    return document.documentElement.clientHeight
        ? document.documentElement.scrollTop
        : document.body.scrollTop;
}

class TemaHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loaded: false, imageHeight: 0, anchors: []};
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.createAnchors = this.createAnchors.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('DOMContentLoaded', this.createAnchors);
        this.handleScroll();
        this.handleResize();

        this.setState({
            loaded: true,
        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('DOMContentLoaded', this.createAnchors);
        window.removeEventListener('resize', this.handleResize);
    }

    handleScroll() {
        this.setState({
            scrollTop: scrollTop(),
        });
    }

    handleResize() {
        this.setState({
            innerWidth: window.innerWidth,
        });
    }

    createAnchors() {
        const elements = [...document.querySelectorAll('h2, h3')];
        elements.forEach((elem, i) => elem.id = `anchor-${i}`);
        const anchors = elements.map(({tagName, innerHTML}, i) => ({text: innerHTML, href: `#anchor-${i}`, indent: tagName === 'H2' ? 0 : 20}));
        this.setState({anchors});
        handleAnchor();
    }

    render() {
        const {menu, header, ingress, mode, images} = this.props;
        let logoWidth;
        let logoClass;
        if (this.state.innerWidth < 1000) {
            logoWidth = this.state.innerWidth;
            logoClass = 'logoResponsive';
        } else {
            logoClass = 'logo';
            if ((this.state.imageHeight - this.state.scrollTop) > 190) {
                logoWidth = this.state.innerWidth * 0.3125;
            } else {
                logoWidth = this.state.innerWidth / 8;
            }
        }
        return (
            <header className="temaside-header">
                <Menu {...menu}>
                    <div className={logoClass}>
                        {this.state.loaded && <Logo width={logoWidth} mode={mode} mainPage={menu.mainPage}/>}
                    </div>
                    {this.state.loaded && this.props.anchors && (
                        <ul className="anchors">
                            {this.state.anchors.map(({text, href, indent}) => <li className="list-item" key={href} style={{paddingLeft: indent}}><a href={href} dangerouslySetInnerHTML={{ __html: text }}/></li>)}
                        </ul>
                    )}
                </Menu>
                <div className="imageContainer">
                    <Image sources={images} className="image" ref="image" alt={this.props.imageText} onLoad={() => this.setState({imageHeight: ReactDOM.findDOMNode(this.refs.image).getBoundingClientRect().height})}/>
                </div>
                <div className="textContainer">
                    <h1 className="header">{header}</h1>
                    <p className="ingress">{ingress}</p>
                </div>
            </header>
        );
    }
}

module.exports = TemaHeader;