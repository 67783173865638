const isNode = (typeof process !== 'undefined');
const React = require('react');
const Select = isNode ? require('react-select').default : () => (null);

const textsI18N = {
    no: ['Jeg ønsker å søke', 'på KMD'],
    en: ['I want to apply for', 'at KMD']
};

const applyI18N = {
    no: ['Søk'],
    en: ['Apply']
};

class ApplyPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {selected: {label: this.props.links[0].text, value: 0}};
    }

    render() {
        const selected = this.state.selected.value;
        const texts = textsI18N[this.props.language || 'no'];
        return (
            <form className="apply-picker" onSubmit={event => {
                event.preventDefault();
                window.location = this.props.links[selected].url;
            }}>
                <span className="text">{texts[0]}</span>
                <Select
                    value={selected}
                    clearable={false}
                    searchable={false}
                    onChange={value => this.setState({selected: value})}
                    options={this.props.links.map(({text}, index) => ({label: text, value: index}))}/>
                <span className="text">{texts[1]}<button type="submit" className="submit">{applyI18N[this.props.language || 'no']} →</button></span>
            </form>
        );
    }
}

module.exports = ApplyPicker;