const React = require('react');

class CollaboratorMini extends React.Component {
    render() {
        return (
            <div className="collaborator-mini">
                <div className="metadata">
                    <span className="artist">
                        <a href={this.props.href}>{this.props.name}</a>
                    </span>                    
                    <p>{this.props.title}</p>
                </div>
            </div>
        )
    }
}

module.exports = CollaboratorMini;
