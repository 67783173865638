const React = require('react');
const Logo = require('../../../../webapp/components/Logo');
const Menu = require('../../../../webapp/components/Menu');

class OpinionSite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loaded: false};
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.setState({loaded: true});
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({innerWidth: window.innerWidth});
    }
    render() {
        const {source, text} = this.props;
        const {innerWidth} = this.state;
        let logoWidth;
        if (innerWidth < 1000) {
            logoWidth = innerWidth;
        } else {
            logoWidth = innerWidth / 8;
        }

        return (
            <div className="opinion-site">
                <Menu {...this.props.menu}>
                    <div className="logo">
                        {this.state.loaded && <Logo width={logoWidth} mainPage={this.props.menu.mainPage}/>}
                    </div>
                </Menu>
                <div className="opinion">
                    <p>{source}:</p>
                    <h1>{text}</h1>
                </div>
            </div>
        );
    }
}

module.exports = OpinionSite;