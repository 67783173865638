const isNode = (typeof process !== 'undefined');
const sum = isNode ? require('lodash/sum') : (() => {});

const secondsPerFrame = 1 / 60;
const precision = 2;

function stepper(x, v, destX, {k, b, others, ki, krandom, fcap} ) {
    const Fspring = -k * (x - destX);
    const Fdamper = -b * v;
    const Finteraction = sum(others.map(pos => (Math.sign(x - pos) * ki) / (((x-pos)!==0)?Math.pow(x - pos, 2):0.00000000001)));

    const sumDistances = sum(others.map(pos => Math.abs(x - pos)));
    let Frandom = (Math.random() - 0.5) * krandom / ((sumDistances!==0)?Math.pow(sumDistances, 2):0.0000000000001);
    if (Math.abs(Frandom) < 700) {Frandom = 0; }

    const F = Fspring + Fdamper + Finteraction + Frandom;

    const Fcapped = Math.sign(F) * Math.min(Math.abs(F), fcap);
    const a = Fcapped;


    const newV = v + a * secondsPerFrame;
    const newX = x + newV * secondsPerFrame;

    if (Math.abs(newV) < precision && Math.abs(newX - destX) < precision) {
        return {
            newX: destX,
            newV: 0,
            done: true
        };
    }

    return {
        newX,
        newV,
        done: false
    }
}

module.exports = stepper;