const React = require('react');
const Image = require('../../components/Image');

class WorkMini extends React.Component {
    
    constructor(props) {
        super(props);
        this.renderImageLink = this.renderImageLink.bind(this);
        this.renderMetadata = this.renderMetadata.bind(this);
    }

    renderImageLink() {
        return (
            <a href={this.props.href} className="imageContainer" title="Se arbeid">
                <Image sources={this.props.images[0]} alt={this.props.title}/>
            </a>
        );
    }

    renderMetadata() {
        // For some reason typeContent is null/not set on first render?
        // Not sure when and why this happened.
        if (this.props.typeContent) {
            if (this.props.type === 'regular' && this.props.typeContent.kuProject) {
                return (
                    <div className="metadata">
                        <a href={this.props.href}>{this.props.title}</a>
                        <p className="small">{this.props.artists.map(({name}) => name).join(', ')}</p>
                    </div>
                );
            }

            if (this.props.type === 'regular') {
                return (
                    <div className="metadata" style={{ textAlign: 'right' }}>
                        {this.props.artists.map((artist, index) => <span key={index} className="artist"><a href={artist.href} className="small">{artist.name}</a></span>)}
                        <p className="small">{this.props.title} {this.props.year && `, ${this.props.year}`}</p>
                        <p className="small">{this.props.typeContent.media.join(', ')}</p>
                    </div>
                );
            }

            return (
                <div className="metadata">
                    {this.props.artists.map((artist, index) => <span key={index} className="artist"><a href={artist.href} className="small">{artist.name}</a></span>)}
                    <p className="small">{this.props.title} {this.props.year && `, ${this.props.year}`}</p>
                 </div>
            );
        }
        return null;
    }

    render() {
        return (
            <div className="work-mini">
                {this.renderImageLink()}
                {this.renderMetadata()}
            </div>
        )
    }
}

module.exports = WorkMini;