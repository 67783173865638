const React = require('react');
const Logo = require('../../../../webapp/components/Logo');
const Menu = require('../../../../webapp/components/Menu');

class ListesideHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loaded: false};
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.setState({loaded: true});
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({innerWidth: window.innerWidth});
    }

    render() {
        const {header, menu} = this.props;
        const {innerWidth} = this.state;
        return (
            <div className="listeside-header">
                <Menu {...menu}>
                    <div className="logo">
                        {this.state.loaded && <Logo width={innerWidth < 1000 ? innerWidth : (innerWidth / 8)} mainPage={menu.mainPage}/>}
                    </div>
                </Menu>
                <h1 className="header">{header}</h1>
            </div>

        );
    }
}

module.exports = ListesideHeader;