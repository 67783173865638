const React = require('react');
const getImage = require('../../../site/parts/getImage');

class Speaker extends React.Component {
    render() {
        const url = this.props.collaborator ? this.props.collaborator.href : null;
        return (
            <div className="speaker-container">
                <div className="image-container">
                    <a href={url}>
                        <div className="imageBox" style={{
                            backgroundImage: `url("${getImage(this.props.speaker.images, 200)}")`,
                            backgroundPosition: `${100 * this.props.speaker.focalPoint.x}% ${100 * this.props.speaker.focalPoint.y}%`,
                        }}></div>
                    </a>
                </div>
                <div className="text-container">
                    <div className="infoBox">
                        <span className="category">{this.props.speaker.title}</span>
                        <h4 className="name">{this.props.speaker.name}</h4>
                        <p className="bio">{this.props.speaker.bio}</p>
                        {url && (
                            <p>
                                <a href={url}>
                                    <span className="arrow" style={{ marginLeft: 0 }}>{this.props.collaboratorText}</span>
                                </a>
                            </p>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

module.exports = Speaker;