const React = require('react');

class Video extends React.Component {
    render() {
        const {id, width, height} = this.props;
        const loop = this.props.loop || 0;
        const autoplay = this.props.autoplay || 0;
        const autopause = this.props.autopause || 0;
        
        return (
            <div className="video">
                <div className="video-wrapper" style={{ paddingBottom: `${100 * height / width}%` }}>
                    <iframe src={`https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&badge=0&autoplay=${autoplay}&autopause=${autopause}&loop=${loop}`} frameBorder="0"/>
                </div>
            </div>
        );
    }
}

module.exports = Video;