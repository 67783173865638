const isNode = (typeof process !== 'undefined');
const React = require('react');
const ReactDOM = isNode ? require('react-dom') : {};
const Image = require('../../../../webapp/components/Image');
const Logo = require('../../../../webapp/components/Logo');
const Menu = require('../../../../webapp/components/Menu');

function scrollTop() {
    if (window.pageYOffset) return window.pageYOffset;
    return document.documentElement.clientHeight
         ? document.documentElement.scrollTop
         : document.body.scrollTop;
}

class ForsideHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loaded: false };
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
        this.handleScroll();
        this.handleResize();
        this.setState({ loaded: true })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        const headerHeight = ReactDOM.findDOMNode(this.refs.header).getBoundingClientRect().height;
        this.setState({ innerWidth: window.innerWidth, headerHeight })
    }

    handleScroll() {
        this.setState({ scrollTop: scrollTop() });
    }

    render() {
        const { headerHeight, scrollTop, innerWidth } = this.state;
        let logoWidth;
        let logoClass;

        if (innerWidth < 1000) {
            logoWidth = innerWidth;
            logoClass = "responsiveLogo";
        } else if (scrollTop < headerHeight) {
            logoWidth = Math.max(headerHeight - scrollTop, 100);
            logoClass = "top";
        } else {
            logoWidth = innerWidth / 8;
            logoClass = "bottom";
        }

        let image;

        if (this.props.bannerType === 'vimeo') {
            const { id, width, height } = this.props.bannerContent;
            const loop = this.props.bannerContent.loop || 0;
            const autoplay = this.props.bannerContent.autoplay || 0;
            const autopause = this.props.bannerContent.autopause || 0;
            image = (
                 <div className="video-wrapper" style={{ paddingBottom: `${100 * height / width}%` }}>
                     <iframe
                          src={`https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&badge=0&autoplay=${autoplay}&autopause=${autopause}&loop=${loop}`}
                          frameBorder="0" />
                 </div>
            );
        } else if (this.props.bannerType === 'image') {
            image = (
                 <div className="imageContainer">
                     <Image 
                        className="image" 
                        sources={this.props.bannerContent.image}
                        onLoad={() =>{
                          const domImageNode = ReactDOM.findDOMNode(this.refs.image);
                          if(domImageNode !== null){
                              this.setState({ imageHeight: domImageNode.getBoundingClientRect().height });
                          }
                        }}
                    />
                    {
                        this.props.bannerContent.caption &&
                        <div className="image-caption" style={{marginLeft: 20, marginRight: 20}}>
                            <div className="image-content" dangerouslySetInnerHTML={{__html: this.props.bannerContent.caption}}/>
                            <div className="fill"/>
                        </div>
                    }                    
                 </div>
            );
        } else if (this.props.image) {
            image = (
                 <div className="imageContainer">
                    <Image 
                        className="image" 
                        sources={this.props.image}
                        onLoad={() => {
                          const domImageNode = ReactDOM.findDOMNode(this.refs.image);
                          if(domImageNode !== null){
                              this.setState({ imageHeight: domImageNode.getBoundingClientRect().height })
                          }
                        }}
                    />
                 </div>
            );
        }

        return (
             <div className="forside-header">
                 <div className="menu">
                     <Menu {...this.props.menu} />
                     <div className={logoClass}>
                         {this.state.loaded && <Logo width={logoWidth} mainPage={this.props.menu.mainPage} />}
                     </div>
                 </div>
                 <header ref="header" className="header-content"
                         dangerouslySetInnerHTML={{ __html: this.props.content }} />
                 {image}
             </div>
        );
    }
}

module.exports = ForsideHeader;
