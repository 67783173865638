const React = require('react');
const WorkMini = require('../WorkMini');
const RandomGrid = require('../../components/RandomGrid');

class WorkList extends React.Component {
    render() {
        return (
            <RandomGrid className="work-list">
                {this.props.works.map(work => <WorkMini key={work._id} {...work}/>)}
            </RandomGrid>
        );
    }
}

module.exports = WorkList;