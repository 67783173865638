//
// External Dependencies
//
import React from 'react';
import ReactDOM from 'react-dom';
import ReactPixel from 'react-facebook-pixel';

//
// Import styles
//
import './scss/main.scss';

//
// Local Dependencies
//
import TemaHeader from './site/parts/temaside-header/TemaHeader';
import ForsideHeader from './site/parts/forside-header/ForsideHeader';
import NewsMini from './site/parts/news-mini/Component';
import EventMini from './site/parts/event-mini/Component';
import ImageLink from './site/parts/image-link/Component';
import EventSite from './site/parts/event-site/Component';
import PersonSite from './site/parts/person-site/Component';
import PersonMini from './site/parts/person-mini/Component';
import PersonList from './site/parts/person-list/Component';
import PersonListHeader from './site/parts/person-list-header/Component';
import CalendarListHeader from './site/parts/calendar-list-header/Component';
import CalendarList from './site/parts/calendar-list/Component';
import ArbeiderHeader from './site/parts/arbeider-header/Component';
import PlaceholderHeader from './site/parts/placeholder-header/Component';
import SectionHeader from './site/parts/section-header/Component';
import ApplyPicker from './site/parts/apply-picker/Component';
import Gallery from './site/parts/gallery/Component';
import ListesideHeader from './site/parts/listeside-header/Component';
import NewsList from './site/parts/news-list/Component';
import NewsSiteHeader from './site/parts/news-site-header/Component';
import Footer from './site/parts/footer/Component';
import FactsBox from './site/parts/fact-box/Component';
import OpinionSite from './site/parts/opinion-site/Component';
import OpinionMini from './site/parts/opinion-mini/Component';
import ImageCaption from './site/parts/image-caption/Component';
import Link from './site/parts/link/Component';
import OpinionList from './site/parts/opinion-list/Component';
import EventList from './site/parts/event-list/Component';
import Quote from './site/parts/quote/Component';
import PersonWorkList from './site/parts/person-work-list/Component';
import MiniInterview from './site/parts/mini-interview/Component';
import Ingress from './site/parts/ingress/Component';
import ImageGallery from './site/parts/image-gallery/Component';
import SpecialEventHeader from './site/parts/special-event-header/Component';
import SuperSpecialEventHeader from './site/parts/super-special-event-header/Component';
import Video from './site/parts/video/Component';
import Error from './site/error/Component';
import NewsPreview from './site/parts/news-preview/Component';
import PublicationMini from './site/parts/publication-mini/Component';
import PublicationList from './site/parts/publication-list/Component';
import Instagram from './site/parts/instagram/Component';
import SpecialEventFooter from './site/parts/special-event-footer/Component';

import TicketForm from './webapp/containers/Form/TicketForm';
import Speaker from './webapp/containers/Speaker';
import WorkMini from './webapp/containers/WorkMini';
import WorkList from './webapp/containers/WorkList';
import Collaborator from './site/parts/collaborator/Component';
import CollaboratorMini from './webapp/containers/CollaboratorMini';

const options = {
  autoConfig: true, 	// set pixel's autoConfig
  debug: false, 		  // enable logs
};

ReactPixel.init('817529388370607', {}, options);
ReactPixel.pageView();   

window.React = React;
window.ReactDOM = ReactDOM;

window.components = {
    ForsideHeader,
    TemaHeader,
    NewsMini,
    EventMini,
    WorkMini,
    ImageLink,
    EventSite,
    PersonSite,
    PersonMini,
    PersonList,
    PersonListHeader,
    CalendarListHeader,
    CalendarList,
    WorkList,
    ArbeiderHeader,
    SectionHeader,
    PlaceholderHeader,
    ApplyPicker,
    Gallery,
    ListesideHeader,
    NewsList,
    NewsSiteHeader,
    Footer,
    FactsBox,
    OpinionSite,
    OpinionMini,
    ImageCaption,
    Link,
    OpinionList,
    EventList,
    Quote,
    PersonWorkList,
    MiniInterview,
    Ingress,
    ImageGallery,
    SpecialEventHeader,
    SuperSpecialEventHeader,
    Video,
    Error,
    NewsPreview,
    PublicationMini,
    PublicationList,
    Instagram,
    SpecialEventFooter,
    TicketForm,
    Speaker,
    Collaborator,
    CollaboratorMini,
};

// Accept HMR as per:
// https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
