const React = require('react');

const Logo = (
    <svg x="0px" y="0px" viewBox="0 0 618 198.4">
        <title>Universitetet i Bergen - Fakultet for kunst, musikk og design</title>
        <path d="M608.3,2.5v114.1l0.7,67.5l-9.7-15.4c0,0,0.1-0.1,0.1-0.1V92.3h-49.3L542,79.8V27.5c3.5,5.2,5.7,11.4,6.7,18.6l10.1-1.5
   c-1.7-13-6.7-23.8-16.9-31.6V9.7c28.1,3.6,42.3,19.1,46.5,42.3l10.4-1.5C593.3,21.3,573.6,0,527,0c-5.9,0-11.5,0.4-16.7,1.3
   c-6-0.8-12.7-1.3-20.1-1.3c-7.8,0-15.4,0.8-22.6,2.5H387C379.3,0.9,370.3,0,359.9,0c-7.8,0-15.4,0.8-22.6,2.5h-98.9
   C230.7,0.9,221.7,0,211.2,0c-7.8,0-15.4,0.8-22.6,2.5h-10v3.1c-18.3,7.1-31.5,21.6-31.5,45.8c0,24.4,11.5,35.4,31.5,41.4V129L111,25
   l20.9-22.5h-13.8l-12.7,13.9l-9-13.9H84.3v36.9L47.5,79.6V2.5H37.4v88.2L9.9,120.8V2.5H0v193.5h9.9v-62l27.6-29.7v27.5
   c0,40.7,18.7,66.7,75.7,66.7c21.3,0,37.3-3.7,48.9-10.4c10.8,6.6,26.5,10.4,49,10.4c21.4,0,41.8-5.3,55.1-17.4
   c4.8,4.5,10.8,8.3,18.1,11.1v3.8h9.9v-0.7c8.6,2.1,18.7,3.2,30.4,3.2c6.2,0,12-0.3,17.3-0.9c5.4,0.6,11.4,0.9,17.9,0.9
   c8.3,0,16.5-0.8,24.2-2.5v0h78.4c7.8,1.6,17,2.5,27.7,2.5c6.5,0,12.8-0.5,18.9-1.5c5.6,1,11.7,1.5,18.1,1.5
   c28.5,0,49.5-6.8,65.8-22.6l13.1,20.1H618V2.5H608.3z M589.6,101.2v52.1l-10.1-15.9L556,101.2H589.6z M438.7,174
   c2.5-2.7,4.8-5.8,6.8-9.1l18,22.1h-0.7C451.6,184.5,443.9,180.1,438.7,174z M512.3,187h-6.9c-1.2-0.3-2.4-0.6-3.5-1l-12.6-15.3
   v-69.5h20.1c9.6,1.8,17,4,22.8,7v70.7C526.3,182.7,519.4,185.3,512.3,187z M428.8,129.1c-2.4-12.6-8.3-21.2-17.9-27.1l0.7-0.8h8.2
   l26.6,32.7c-0.8,4.6-1.9,8.9-3.1,12.8L428.8,129.1z M420,144.4c0,2.7-0.2,5.3-0.6,7.7l0,0c0,0,0,0,0,0c-2.1,13.2-9.5,22.1-19.3,27.9
   v-48.3v-18.2l1.9-2l17.2,21.1C419.8,136.1,420,140,420,144.4z M479.7,27.8v60.1c-8.3-1.2-15.2-2.7-20.7-4.5c-0.3-3.8-0.6-7.5-1.1-11
   c1.9-12.4,5.3-23.2,10.3-32.2L479.7,27.8z M381.2,11.5c0.9,0,1.8,0.1,2.6,0.1v1.8l-2.6,6.4V11.5z M418.5,46.1l5.5-0.8l-23.9,26.1V17
   C411,23.2,416.7,33,418.5,46.1z M383.8,89.3l-2.6,2.8c0,0,0,0-0.1,0V44.3l2.6-6.4V89.3z M371.3,90.6c-2.8-0.4-5.7-0.7-8.7-1
   l8.7-21.2V90.6z M359,98.4c0.9,0.1,1.9,0.2,2.8,0.3c3.4,0.4,6.5,0.8,9.5,1.2v3L355,120.8v-12.6L359,98.4z M371.3,116.3v61.5
   c-4.5,3.2-9.9,5.7-16.3,7.6v-51.6L371.3,116.3z M381.2,105.7l2.6-2.8v15l-2.6,2.9V105.7z M400.1,92.3v-7l26.2-28.2
   c0.4,4.4,1.2,8.3,2.4,11.8l-21.4,23.4H400.1z M422.4,89.5l11-11.8c3.2,4.3,7.4,7.7,12.6,10.4c-0.1,1.4-0.1,2.7-0.2,4.1h-21.2
   L422.4,89.5z M445.7,101.2c0.1,6.2,0.4,12.1,1,17.8L432,101.2H445.7z M459.4,134.5c-0.6-2.7-1.1-5.5-1.6-8.4
   c1-7.5,1.5-15.8,1.6-24.9h20.2v57.9L459.4,134.5z M447.6,71.9c-0.3,1.8-0.5,3.7-0.7,5.6c-2.8-2.1-4.9-4.5-6.5-7.2l6-6.4
   C446.8,66.4,447.2,69.1,447.6,71.9z M437,59.8c-0.4-2.6-0.6-5.4-0.6-8.5c0-1.9,0.1-3.7,0.3-5.4l3-3.3c1.5,3.1,2.7,6.4,3.8,10
   L437,59.8z M427.5,38.3c-1.6-6.8-4.3-12.9-8.5-18.2c4.4,2.4,8.3,5.4,11.6,9C429.3,32,428.3,35,427.5,38.3z M371.3,43.8L355,83.6
   V11.4h4.4h12V43.8z M345.2,107.6l-6.4,15.7V95.4c2.1,0.4,4.2,0.8,6.4,1.1V107.6z M345.2,132.2V187h-6.4v-39.1L345.2,132.2z
    M328.9,186.1c-1.8-0.5-3.5-1.1-5.1-1.7l5.1-12.4V186.1z M381.2,133.9l2.6-2.8v32.5c-0.8,1.5-1.7,3-2.6,4.3V133.9z M452.9,148.7
   c5.6,15.8,14.4,28.3,26.8,36.8v1.5h-3.1l-26.1-31.7C451.3,153.2,452.2,151,452.9,148.7z M532.2,97.6c-10.2-4-23.2-6.3-39.3-8
   c-1.2-0.1-2.4-0.3-3.6-0.4v-7.3l-0.7-63.2c0.6-0.4,1.3-0.8,2-1.1L518.1,61l14.1,21.6V97.6z M479.7,11.4v1.5
   c-8.3,5.7-14.9,13.2-20.1,22.2l-8.6,9.4c-1.3-3.3-2.8-6.4-4.4-9.2l22-23.7c0.2-0.1,0.5-0.1,0.7-0.2H479.7z M435.5,21.2
   c-4.3-4-9.1-7.2-14.4-9.8h25C442.1,14.1,438.5,17.4,435.5,21.2z M345.2,11.4v75.8c-2.3-0.4-4.4-0.8-6.4-1.2V11.5
   c0.1,0,0.2,0,0.2-0.1H345.2z M328.9,14.9v68.5c-3.9-1.3-7.2-2.7-9.9-4.4V21.1C322,18.6,325.3,16.6,328.9,14.9z M309.1,68.3
   c-2.1-4.6-3-10.2-3-17c0-6.4,1.1-11.9,3-16.6V68.3z M328.9,93.2v54.3l-9.9,24.7V89.7C322,91,325.3,92.2,328.9,93.2z M381.2,186.9
   v-4.4c0.9-0.8,1.8-1.6,2.6-2.4v6.4c-0.4,0.1-0.8,0.2-1.1,0.3C382.2,186.9,381.7,186.9,381.2,186.9z M424.1,169.2
   c0.7,1.4,1.4,2.8,2.2,4.1c-3.1,2.5-6.5,4.7-10.3,6.5C419.2,176.7,421.9,173.2,424.1,169.2z M429.7,151.6c0.2-1.8,0.3-3.8,0.4-5.7
   l9.1,11.1c-1.6,3.2-3.5,6.2-5.5,8.8C431.7,161.6,430.5,156.9,429.7,151.6z M542,115.5c6.1,6.8,8.2,16.1,8.2,28.9
   c0,10.7-3.1,19.1-8.2,25.6V115.5z M542,102.5v-1.3h2.2l4.3,6.7C546.7,105.9,544.5,104.1,542,102.5z M499,13.7
   c2.4-0.9,4.9-1.6,7.4-2.3h9.1c6.6,1.5,12.1,3.7,16.6,6.6v46.6L499,13.7z M309.1,11.4v5.5c-8.2,8.1-13.3,19.4-13.3,34.4
   c0,15.6,4.7,25.8,13.3,32.6v90.3l-13.5-33.6l-1.6-3.8V11.4H309.1z M284.2,112.8l-25.3-61.7V22.5c6.2,6,9.6,13.9,11,23.7l10.1-1.5
   c-1.8-13.9-7.4-25.4-19.1-33.2h23.3V112.8z M266.2,165.5c-5.2-8.8-7.2-19.6-7.2-32.1v-21.8c9.5,7.1,12.5,17.4,12.5,32.7
   C271.4,152.8,269.4,159.8,266.2,165.5z M258.9,100.1V75.7l15.9,38.7C271.1,108.3,265.8,103.7,258.9,100.1z M248.8,26.5l-5.4-13.2
   c1.9,0.7,3.7,1.4,5.4,2.2V26.5z M222.6,90.6V11.4h4.5v79.8C225.6,91,224.1,90.8,222.6,90.6z M227.1,100.6v87.6
   c-1.5,0.2-3,0.4-4.5,0.6v-89C224.1,100.1,225.6,100.4,227.1,100.6z M236,19.6L248.8,51v45c-3.7-1.2-7.7-2.2-12.1-3.1v-11L236,19.6z
    M213,8.9v80.5c-9.5-1-17.5-2.2-24.2-3.7V11.8c8-2.3,16.2-2.9,22.5-2.9C211.8,8.9,212.4,8.9,213,8.9z M157.5,51.3
   c0-19.1,9.4-29.7,21.1-35.6v67.2C162.5,77.2,157.5,68,157.5,51.3z M213,98.7v17.9l0.7,67.5l-25.6-40.3c0.4-3.8,0.6-7.8,0.6-12V95.2
   C196,96.6,204.1,97.7,213,98.7z M122.8,61l55.1,84.7c-1.7,12.6-6.5,23.1-15.8,30.6c-6.7-6.4-10-15.1-11.4-25.8l-10.1,1.5
   c1.6,11.6,5.4,21.8,13.3,29.6c-5.9,3-13,5.1-21.6,6.3l-38.3-46.4V81.9l-0.4-38.1l10.9-11.7L122.8,61z M93.2,14.4l5.7,9l-5.6,6.1
   L93.2,14.4z M84.3,53.7v76.2L51,89.5L84.3,53.7z M47.5,133.4v-33.4l36.8,45.1V186C56.6,178.7,47.5,159.4,47.5,133.4z M93.9,188v-31
   l26.2,32.1c-2.3,0.1-4.6,0.2-7,0.2C106,189.2,99.7,188.8,93.9,188z M185.5,157.5l20.8,32c-16.1-0.4-27.7-2.9-36-7.3
   C177.7,175.7,182.6,167.3,185.5,157.5z M236.7,186.2v-83.7c4.6,1.1,8.6,2.3,12.1,3.7v25.5c0,16.8,3.2,31,10.9,42
   C253.6,179.7,245.4,183.7,236.7,186.2z M272.6,173.8c5.6-7.8,8.9-17.5,8.9-29.4c0-7.1-0.7-13.3-2.1-18.6l4.8,11.7v44.4
   C279.7,179.6,275.8,176.9,272.6,173.8z M294.1,169.7c2,4.4,4.7,8.3,8.2,11.8l2.6,6.4c-3.9-0.6-7.5-1.4-10.8-2.3V169.7z M421.1,187
   c3.8-1.8,7.2-4,10.5-6.5c2.2,2.4,4.7,4.5,7.7,6.5H421.1z M542,188.7v-5.1c11.2-8.7,18.3-21.5,18.3-39.2c0-9.8-1.3-17.8-3.9-24.4
   l30.9,47.5C576.8,178.6,562.8,186.2,542,188.7z"/>
    </svg>
);

module.exports = Logo;