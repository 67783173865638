const React = require('react');

class Anchor extends React.Component {
    constructor(props) {
        super(props);
        this.renderMainAnchor = this.renderMainAnchor.bind(this);
        this.renderSubAnchor = this.renderSubAnchor.bind(this);
    }

    renderMainAnchor() {
        return (
            <div className="main-anchor">
                <a href={this.props.link.href} className="large-link main-link"><span className="link-underline">{this.props.link.text}</span></a>
            </div>
        );
    }

    renderSubAnchor(anchor, key) {
        return (
            <a key={key} href={anchor.href} className="small-link sub-link"><span className="link-underline">{anchor.text}</span></a>
        );
    }
    
    render() {
        return (
            <div className="section">
                {this.renderMainAnchor()}
                {this.props.link.children.map((anchor, i) => this.renderSubAnchor(anchor, i))}
            </div>
        );
    }
}
 
module.exports = Anchor;