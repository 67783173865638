const isNode = (typeof process !== 'undefined');
const React = require('react');
const ReactDOM = isNode ? require('react-dom') : {};

// const Image = require('../../Image');
const Image = require('../../../../webapp/components/Image');
const getImage = require('../../getImage');

const Img = (props) => <img {...props} src={getImage(props.sources)} />

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        const imagesStatus = props.images.reduce((acc, _, index) => {
            acc[index] = false;
            return acc;
        }, {});
        this.state = { initialMargin: 0, imagesStatus, fullscreen: -1 };

        this.handleResize = this.handleResize.bind(this);
        this.imageLoaded = this.imageLoaded.bind(this);
        this.scrollEvent = this.scrollEvent.bind(this);
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden';
        this.handleResize();
    }

    handleResize() {
        if (this.props.description) {
            const descriptionNodes = this.refs.description.childNodes;
            const first = descriptionNodes[0];
            const last = descriptionNodes[descriptionNodes.length - 1];
            const width = last.getBoundingClientRect().right - first.getBoundingClientRect().left;
            this.setState({ descriptionWidth: width });
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto';
    }

    imageLoaded(index) {
        const { imagesStatus } = this.state;
        imagesStatus[index] = true;
        this.setState({ imagesStatus });
    }

    scrollEvent(event) {
        if (event.deltaY) {
            this.refs.container.scrollLeft = this.refs.container.scrollLeft + event.deltaY;
        }
    }

    render() {
        const { imagesStatus, fullscreen } = this.state;
        const imagesLoaded = Object.keys(imagesStatus).every(index => imagesStatus[index]);

        const { images } = this.props;
        const first = images[0];
        const rest = images.length > 0 ? images.slice(1) : [];

        return (
            <div ref="container" className="image-gallery showall" onWheel={this.scrollEvent}>
                {!imagesLoaded && <div className="overlay">Laster...</div>}
                <button className="close" onClick={this.props.close}>x</button>
                <div className="elements">
                    <figure className="element" style={{ marginLeft: this.state.initialMargin }}>
                        <Img ref="first" sources={first.image} onClick={() => this.setState({ fullscreen: 0 })} onLoad={() => {
                            this.imageLoaded(0);
                            const imageWidth = ReactDOM.findDOMNode(this.refs.first).getBoundingClientRect().width;
                            const innerWidth = window.innerWidth;
                            this.setState({ initialMargin: (innerWidth - imageWidth) / 2 })
                        }} />
                        <figcaption>
                            <div className="count">
                                <a>1 av {images.length}</a>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: first.caption }} />
                        </figcaption>
                    </figure>
                    {rest.map((image, index) => {
                        return (
                            <figure className="element" key={index}>
                                <Img sources={image.image}
                                    onLoad={() => this.imageLoaded(index + 1)}
                                    onClick={() => this.setState({ fullscreen: index + 1 })} />
                                <figcaption>
                                    <div className="count">
                                        <a>{index + 2} av {images.length}</a>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: image.caption }} />
                                </figcaption>
                            </figure>
                        );
                    })}
                </div>
                {fullscreen !== -1 && <div className="fullscreen" style={{ backgroundImage: `url(${getImage(images[fullscreen].image)})` }} onClick={() => this.setState({ fullscreen: -1 })} />}
            </div>
        );
    }
}


class ImageGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }
    render() {
        const { images } = this.props;
        const { open } = this.state;
        const first = images[0];

        if (!open) {
            return (
                <figure className="image-gallery preview">
                    <button className="image-button" onClick={() => {
                        document.body.style.overflow = 'hidden';
                        this.setState({ open: true });
                    }}>
                        <Img sources={first.image} />
                        <figcaption dangerouslySetInnerHTML={{ __html: first.caption }} />
                        <p className="count">1/{images.length}</p>
                    </button>
                </figure>
            )
        }

        return <Gallery {...this.props} close={() => {
            document.body.style.overflow = 'auto';
            this.setState({ open: false });
        }} />;
    }
}


module.exports = ImageGallery;