const React = require('react');

const uibLogo = (
    <svg x="0px" y="0px" viewBox="0 0 70.9 70.9" aria-label="Universitetet i Bergen">
        <title>Universitetet i Bergen</title>
        <g>
            <g>
                <path d="M6.4,27.9c-0.6-0.1-1.8-0.3-2.2-0.2C4,27.8,3.9,28,3.9,28.2l-0.1,0.3c-0.1,0.1-0.2,0-0.2-0.1c0.1-0.4,0.2-0.8,0.2-1.2
			c0.1-0.4,0.1-0.7,0.2-1.1c0.1-0.1,0.2,0,0.3,0l0,0.3c0,0.3,0,0.5,0.2,0.6c0.3,0.2,1.5,0.4,2.1,0.5l1.2,0.2
			c0.9,0.2,1.8,0.5,2.3,1.2c0.4,0.5,0.5,1.3,0.4,2.1c-0.1,0.7-0.4,1.4-0.9,1.8c-0.5,0.5-1.3,0.6-2.6,0.4l-2.4-0.4
			c-1.1-0.2-1.3-0.2-1.4,0.4L3,33.4c-0.1,0.1-0.2,0-0.2-0.1C2.8,32.8,2.9,32.5,3,32c0.1-0.4,0.1-0.8,0.2-1.3c0-0.1,0.2,0,0.3,0
			l0,0.3c-0.1,0.6,0.1,0.7,1.2,0.8l2.2,0.4c1.7,0.3,2.8,0,3.1-1.6c0.3-1.5-0.8-2.3-2.4-2.5L6.4,27.9z"/>
                <path d="M12.9,19.6c0.3,0.2,1.4,0.8,1.6,0.9c0,0,0.1,0.1,0,0.2c-0.2,0-0.7,0.1-2,0.4l-3.6,0.8c-0.4,0.1-1.5,0.3-1.8,0.4l0,0
			c0.1,0.1,0.3,0.2,0.6,0.4l2,1.1c0.4,0.2,1.6,0.9,2,0.9c0.1,0,0.3-0.2,0.4-0.4l0.2-0.3c0.1,0,0.2,0.1,0.2,0.1
			c-0.2,0.4-0.4,0.7-0.6,1.1c-0.2,0.4-0.3,0.6-0.5,1c-0.1,0-0.2,0-0.2-0.1l0.1-0.3c0.1-0.2,0.1-0.4,0-0.5c-0.3-0.3-1.4-0.9-1.8-1.2
			l-2.6-1.5c-0.3-0.1-0.5-0.2-0.8-0.2c-0.2,0.1-0.4,0.2-0.5,0.4L5.3,23c-0.1,0-0.2-0.1-0.2-0.1c0.2-0.4,0.5-0.9,0.6-1
			c0.1-0.1,0.2-0.3,0.2-0.4c0.6,0.1,2.4-0.3,2.9-0.4l1.5-0.3c1.1-0.2,1.8-0.4,2.5-0.6l0,0c0-0.1-0.2-0.1-0.4-0.3l-2-1.1
			c-0.4-0.2-1.6-0.9-2-0.9c-0.1,0-0.2,0.1-0.5,0.5l-0.1,0.1c-0.1,0-0.2-0.1-0.2-0.1c0.3-0.4,0.4-0.7,0.6-1.1c0.2-0.4,0.3-0.6,0.5-1
			c0.1,0,0.2,0,0.2,0.1L9,16.5c-0.1,0.3-0.1,0.5-0.1,0.6c0.2,0.3,1.4,1,1.8,1.2L12.9,19.6z"/>
                <path d="M12.8,14.2c-0.8-0.8-0.9-0.9-1.4-0.5l-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0.4-0.4,0.6-0.6,0.9-0.9c0.3-0.3,0.5-0.6,0.9-1
			c0.1,0,0.2,0.1,0.2,0.2l-0.2,0.2c-0.4,0.5-0.3,0.6,0.5,1.4l2.7,2.6c0.8,0.8,0.9,0.9,1.4,0.5l0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
			c-0.4,0.4-0.6,0.6-0.9,0.9c-0.3,0.3-0.5,0.6-0.9,1c-0.1,0-0.2-0.1-0.2-0.2l0.2-0.2c0.4-0.5,0.3-0.6-0.5-1.4L12.8,14.2z"/>
                <path d="M16.6,10.1c-0.5-0.4-0.8-0.5-1.2-0.3l-0.2,0.1c-0.1,0-0.2-0.1-0.1-0.2c0.3-0.1,0.6-0.3,0.9-0.6c0.4-0.2,0.7-0.5,1.1-0.7
			c0.1,0,0.2,0.1,0.2,0.2l-0.1,0.1c-0.3,0.3-0.3,0.4-0.3,0.4c0.1,0.1,0.5,0.5,1.2,1c1.1,0.8,2.1,1.5,3.2,2.3
			c-0.1-0.8-0.3-1.9-0.4-2.5c-0.1-0.8-0.3-2-0.4-2.5c0-0.2-0.1-0.3-0.1-0.4c0-0.1-0.2-0.1-0.5,0.1l-0.2,0.1c-0.1,0-0.2-0.2-0.1-0.2
			c0.3-0.1,0.6-0.3,0.9-0.6C20.7,6.2,21,6,21.3,5.8c0.1,0,0.2,0.1,0.2,0.2l-0.3,0.2c-0.2,0.2-0.3,0.3-0.3,0.6c0,0.6,0.1,1.5,0.3,2.7
			l0.2,1.4c0.2,1,0.4,2.3,0.5,2.9c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c-0.3-0.3-0.8-0.6-1.2-0.9L16.6,10.1z"/>
                <path d="M25.3,6.1C25,5,25,4.9,24.4,5l-0.2,0c-0.1,0-0.1-0.2-0.1-0.3c0.4-0.1,0.8-0.2,1.2-0.3l1.9-0.5c0.6-0.2,1.2-0.4,1.4-0.4
			c0.1,0.2,0.4,0.9,0.5,1.3c0,0.1-0.2,0.1-0.2,0.1c-0.3-0.4-0.5-0.8-1-0.7c-0.2,0-0.6,0.1-1,0.2l-0.7,0.2c-0.3,0.1-0.3,0.1-0.2,0.5
			l0.6,2c0.1,0.3,0.1,0.3,0.4,0.2l0.6-0.2c0.4-0.1,0.7-0.2,0.8-0.3c0.1-0.1,0.2-0.2,0.1-0.4l0-0.4c0-0.1,0.2-0.1,0.3-0.1
			c0.1,0.2,0.1,0.6,0.2,1c0.1,0.4,0.3,0.7,0.3,1c0,0.1-0.2,0.1-0.3,0.1l-0.2-0.4c-0.1-0.2-0.2-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.8,0.2
			L27,7.6c-0.3,0.1-0.3,0.1-0.3,0.4l0.4,1.4c0.2,0.5,0.3,0.9,0.5,1c0.2,0.1,0.4,0.1,1.3-0.1c0.8-0.2,1.1-0.3,1.2-0.5
			c0.2-0.2,0.3-0.6,0.4-1.2c0.1-0.1,0.2-0.1,0.3,0c0,0.4,0,1.2-0.1,1.5c-0.9,0.2-1.8,0.5-2.7,0.7l-0.9,0.3c-0.4,0.1-0.8,0.2-1.4,0.4
			c-0.1,0-0.1-0.2-0.1-0.2l0.3-0.1c0.6-0.2,0.6-0.4,0.3-1.4L25.3,6.1z"/>
                <path d="M33.6,4.4c0-1,0-1.2-0.5-1.2l-0.3,0c-0.1-0.1-0.1-0.2,0-0.3c0.6,0,1.3-0.1,2.3-0.1c0.7,0,1.3,0.1,1.7,0.3
			c0.5,0.3,0.9,0.7,0.9,1.5c0,1-0.8,1.5-1.3,1.7c-0.1,0.1,0,0.2,0.1,0.3c0.8,1.4,1.4,2.2,2.1,2.8c0.2,0.2,0.4,0.3,0.7,0.3
			c0.1,0,0.1,0.1,0,0.1c-0.1,0-0.3,0.1-0.5,0c-0.9,0-1.4-0.3-2.2-1.3c-0.3-0.4-0.7-1.1-1-1.6c-0.2-0.2-0.3-0.3-0.7-0.3
			c-0.5,0-0.5,0-0.5,0.2l0,1.3c0,1.1,0,1.2,0.6,1.3l0.2,0c0.1,0.1,0,0.2,0,0.3c-0.5,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.3,0
			c-0.1,0-0.1-0.2,0-0.3l0.3,0c0.6-0.1,0.6-0.2,0.6-1.3L33.6,4.4z M34.5,5.9c0,0.2,0,0.3,0,0.3c0,0,0.2,0.1,0.7,0.1
			c0.4,0,0.8,0,1.1-0.3c0.3-0.2,0.5-0.6,0.5-1.3c0-0.8-0.5-1.6-1.7-1.6c-0.7,0-0.7,0-0.7,0.3L34.5,5.9z"/>
                <path d="M42.9,11.1c-0.8-0.3-1.3-0.7-1.4-0.9c-0.1-0.3,0-1,0.2-1.5c0.1-0.1,0.2,0,0.3,0c0,0.6,0.1,1.7,1.2,2
			c0.8,0.3,1.4-0.2,1.6-0.8c0.1-0.4,0.2-1-0.4-1.7l-0.8-0.9c-0.4-0.5-0.8-1.2-0.5-2.1c0.3-1,1.3-1.5,2.6-1.1
			c0.3,0.1,0.7,0.3,0.9,0.4c0.1,0.1,0.2,0.1,0.3,0.2c0,0.3-0.1,0.9-0.2,1.3c-0.1,0-0.2,0-0.3,0c0-0.5,0-1.3-1-1.6
			c-1-0.3-1.4,0.3-1.5,0.7c-0.2,0.6,0.2,1.2,0.5,1.5L45,7.6c0.5,0.6,0.9,1.4,0.6,2.3C45.3,11,44.2,11.5,42.9,11.1z"/>
                <path d="M51.2,8.8c0.6-0.9,0.7-1.1,0.2-1.5l-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.4,0.3,0.7,0.5,1.1,0.7c0.4,0.2,0.7,0.4,1.1,0.7
			c0,0.1,0,0.2-0.1,0.2l-0.2-0.1c-0.6-0.3-0.7-0.1-1.2,0.8l-2,3.2c-0.6,0.9-0.6,1.1-0.1,1.5l0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
			c-0.4-0.3-0.7-0.5-1.1-0.7c-0.4-0.2-0.7-0.4-1.1-0.7c0-0.1,0-0.2,0.1-0.2l0.2,0.1c0.6,0.3,0.7,0.1,1.2-0.8L51.2,8.8z"/>
                <path d="M55.7,17.3c-0.8,0.7-0.9,0.9-0.5,1.4l0.2,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.4-0.5-0.6-0.7-0.9-1c-0.3-0.3-0.5-0.6-1-1
			c0-0.1,0.1-0.2,0.2-0.2l0.3,0.3c0.4,0.4,0.6,0.3,1.4-0.4l3.4-3.2c0.2-0.2,0.2-0.2,0-0.5l-0.4-0.4c-0.3-0.3-0.7-0.7-1.1-0.8
			c-0.3,0-0.6,0.1-0.9,0.1c-0.1,0-0.2-0.2-0.1-0.3c0.5-0.2,1.1-0.5,1.4-0.8c0,0,0.1,0.1,0.1,0.1c-0.2,0.3,0,0.5,0.4,0.9l2.9,3.1
			c0.4,0.4,0.5,0.5,0.8,0.4c0.1,0,0.1,0.1,0.1,0.2c-0.4,0.2-1.1,0.7-1.3,1c-0.1,0-0.2-0.1-0.2-0.2c0.2-0.2,0.4-0.5,0.4-0.7
			c0-0.3-0.2-0.6-0.6-1.1l-0.5-0.5c-0.2-0.2-0.2-0.2-0.5,0L55.7,17.3z"/>
                <path d="M61.2,24.3c0.2-0.2,0.2-0.2,0.1-0.5l-0.7-1.4c-0.1-0.2-0.2-0.3-0.4-0.3l-1.2,0.1c-0.5,0.1-0.8,0.1-0.9,0.2
			c-0.1,0.1-0.1,0.1-0.1,0.4l0.1,0.3c0,0.1-0.2,0.2-0.2,0.1c-0.1-0.3-0.2-0.5-0.4-1c-0.1-0.3-0.3-0.6-0.5-0.9c0-0.1,0.2-0.2,0.2-0.1
			l0.2,0.3c0.1,0.2,0.3,0.4,0.7,0.3c0.5,0,1.3-0.1,2.5-0.2l3-0.2c0.4,0,0.6-0.1,0.7-0.2c0.2,0.2,0.4,0.3,0.6,0.3c0,0.1,0,0.1,0,0.2
			c-0.4,0.3-0.7,0.7-1.1,1l-3.2,3.1c-0.7,0.7-0.8,0.9-0.6,1.3l0.1,0.3c0,0.1-0.2,0.1-0.2,0.1c-0.2-0.4-0.3-0.7-0.5-1.2
			c-0.2-0.5-0.4-0.8-0.5-1.1c0-0.1,0.1-0.2,0.2-0.1l0.2,0.3c0.1,0.2,0.2,0.3,0.3,0.3c0.1-0.1,0.3-0.2,0.4-0.4L61.2,24.3z M61.2,22
			c-0.3,0-0.3,0-0.1,0.3l0.6,1.2c0.1,0.3,0.2,0.3,0.4,0.1l1.3-1.2c0.2-0.2,0.4-0.4,0.5-0.5l0,0c-0.1,0-0.3,0-0.7,0L61.2,22z"/>
                <path d="M60.6,31.5c-0.1-0.9,0.1-1.5,0.1-1.7c0.2-0.2,0.9-0.4,1.4-0.5c0.1,0,0.1,0.2,0.1,0.2c-0.5,0.2-1.5,0.8-1.3,2
			c0.1,0.9,0.8,1.2,1.4,1.1c0.5-0.1,1-0.2,1.3-1.1l0.5-1.1c0.3-0.6,0.8-1.3,1.6-1.4c1-0.1,1.9,0.5,2.1,1.9c0,0.3,0,0.7,0,1
			c0,0.1,0,0.3,0,0.4c-0.2,0.1-0.8,0.3-1.2,0.4c-0.1,0-0.1-0.2-0.1-0.3c0.5-0.2,1.1-0.6,1-1.6c-0.2-1-0.9-1.1-1.3-1.1
			c-0.6,0.1-1,0.7-1.1,1.1l-0.4,0.9c-0.3,0.7-0.8,1.4-1.8,1.6C61.7,33.5,60.8,32.8,60.6,31.5z"/>
            </g>
            <g>
                <path d="M9.5,43.1c0.7-0.3,0.9-0.4,0.8-0.8l-0.1-0.3c0-0.1,0.2-0.1,0.2-0.1c0.2,0.5,0.5,1.2,0.9,2.1c0.2,0.6,0.4,1.2,0.3,1.8
			c-0.1,0.5-0.3,1-1,1.2c-0.7,0.3-1.2,0-1.7-0.5c-0.1,0-0.1,0.1-0.1,0.2c0.1,0.6-0.1,1.5-1.2,1.9c-1.1,0.4-2.2-0.1-2.9-1.9
			c-0.1-0.3-0.3-0.8-0.4-1.2C4.3,45,4.1,44.7,4,44.3c0-0.1,0.2-0.1,0.2-0.1l0.1,0.2c0.3,0.5,0.4,0.5,1.4,0.1L9.5,43.1z M6.2,45.2
			c-0.4,0.1-0.8,0.3-1,0.6c-0.2,0.3-0.1,0.6,0,0.9c0.3,0.9,1,1.4,1.9,1c0.8-0.3,1.5-1,1-2.6c-0.2-0.5-0.3-0.5-0.5-0.5L6.2,45.2z
			 M8.5,44.4c-0.3,0.1-0.3,0.1-0.1,0.6c0.4,1,0.9,1.4,1.8,1.1c1-0.4,1.1-1.2,0.8-2c-0.1-0.2-0.2-0.4-0.2-0.4c-0.1-0.1-0.1-0.1-0.3,0
			L8.5,44.4z"/>
                <path d="M12.6,50.4c0.9-0.7,1-0.8,0.7-1.3l-0.1-0.1c0-0.1,0.2-0.2,0.2-0.1c0.3,0.4,0.5,0.7,0.7,1l1.2,1.6c0.4,0.5,0.8,1,0.9,1.1
			c-0.1,0.2-0.7,0.7-1,1c-0.1,0-0.2-0.1-0.2-0.2c0.3-0.4,0.5-0.7,0.3-1.2c-0.1-0.2-0.3-0.5-0.6-0.9l-0.5-0.6c-0.2-0.3-0.2-0.2-0.5,0
			l-1.7,1.3c-0.2,0.2-0.2,0.2,0,0.5l0.4,0.5c0.3,0.4,0.5,0.6,0.6,0.7c0.1,0.1,0.2,0.1,0.4,0l0.4-0.2c0.1,0,0.2,0.1,0.2,0.2
			c-0.2,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.6,0.5-0.8,0.6c-0.1,0-0.2-0.1-0.2-0.2l0.3-0.3c0.1-0.1,0.2-0.3,0.1-0.5
			c-0.1-0.1-0.2-0.4-0.4-0.7l-0.4-0.5c-0.2-0.3-0.2-0.3-0.4-0.1l-1.2,0.9c-0.4,0.3-0.7,0.6-0.8,0.8c0,0.2,0,0.4,0.6,1.2
			c0.5,0.6,0.7,0.9,0.9,1c0.2,0.1,0.7,0.1,1.3,0c0.1,0,0.1,0.2,0.1,0.3c-0.3,0.2-1.1,0.4-1.5,0.5c-0.5-0.8-1.1-1.5-1.7-2.3l-0.6-0.8
			c-0.3-0.4-0.5-0.6-0.9-1.1c0-0.1,0.1-0.2,0.2-0.2l0.2,0.3c0.4,0.4,0.6,0.4,1.4-0.3L12.6,50.4z"/>
                <path d="M17.7,56.2c0.6-0.8,0.7-1,0.4-1.3l-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.5,0.3,1.1,0.7,1.9,1.3c0.5,0.4,1,0.8,1.2,1.3
			c0.3,0.5,0.3,1.1-0.2,1.7c-0.6,0.8-1.5,0.8-2.1,0.6c-0.1,0-0.1,0.1-0.1,0.2c-0.1,1.6-0.2,2.6,0.1,3.5c0,0.2,0.2,0.5,0.4,0.7
			c0,0,0,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.4-0.2c-0.7-0.5-1-1.1-1-2.3c0-0.5,0.1-1.3,0.1-1.9c0-0.3-0.1-0.5-0.4-0.7
			c-0.4-0.3-0.4-0.3-0.5-0.1l-0.8,1.1c-0.7,0.9-0.7,1-0.3,1.4l0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.4-0.3-0.7-0.5-1-0.8
			c-0.4-0.3-0.7-0.5-1.1-0.7c0-0.1,0-0.2,0.1-0.2l0.2,0.1c0.5,0.3,0.6,0.2,1.3-0.7L17.7,56.2z M17.5,57.9c-0.1,0.2-0.2,0.2-0.2,0.3
			c0,0,0.1,0.2,0.6,0.5c0.3,0.2,0.7,0.4,1,0.4c0.4,0,0.8-0.2,1.2-0.7c0.5-0.7,0.5-1.6-0.4-2.3c-0.5-0.4-0.6-0.4-0.7-0.1L17.5,57.9z"
                />
                <path d="M28.4,66.3c-0.1,0.4-0.1,0.7,0,0.8c0,0,0,0.1,0,0.1c-0.1,0-0.3-0.1-0.5-0.1c-0.6,0-1.3-0.1-1.9-0.3
			c-1.2-0.4-2.2-1.1-2.7-2.1c-0.4-0.8-0.5-1.7-0.2-2.6c0.3-0.8,0.8-1.6,1.7-2.1c0.9-0.5,2-0.6,3.5-0.1c0.6,0.2,1.1,0.5,1.2,0.6
			c0.2,0.1,0.5,0.3,0.6,0.3c-0.1,0.3-0.2,0.9-0.3,1.5c-0.1,0.1-0.2,0-0.3-0.1c0.1-1.2-0.6-1.8-1.6-2.1c-1.9-0.6-3.2,0.5-3.7,2
			c-0.6,1.9-0.1,3.7,1.9,4.4c0.6,0.2,1,0.2,1.2,0.1c0.1-0.1,0.2-0.2,0.3-0.5l0.1-0.4c0.3-0.8,0.3-0.9-0.3-1.2l-0.4-0.2
			c-0.1-0.1,0-0.2,0.1-0.2c0.3,0.1,0.8,0.3,1.4,0.5c0.4,0.1,0.7,0.2,1.1,0.3c0.1,0.1,0,0.2-0.1,0.3l-0.2,0c-0.4-0.1-0.5,0.2-0.7,0.7
			L28.4,66.3z"/>
                <path d="M33.7,62.6c0-1.1,0-1.2-0.6-1.3l-0.2,0c-0.1,0,0-0.2,0-0.3c0.5,0,0.8,0,1.2,0l2,0c0.7,0,1.3,0,1.4,0
			c0.1,0.2,0.1,0.9,0.2,1.4c0,0.1-0.2,0.1-0.3,0c-0.2-0.5-0.2-0.9-0.8-1c-0.2-0.1-0.6-0.1-1-0.1l-0.8,0c-0.3,0-0.3,0-0.3,0.4l0,2.1
			c0,0.3,0,0.3,0.3,0.3l0.6,0c0.4,0,0.8,0,0.9-0.1c0.1,0,0.2-0.1,0.3-0.4l0.1-0.4c0.1-0.1,0.2-0.1,0.3,0c0,0.3,0,0.7,0,1.1
			c0,0.4,0,0.8,0,1c0,0.1-0.2,0.1-0.3,0L36.6,65c0-0.2-0.1-0.3-0.3-0.4c-0.1,0-0.4-0.1-0.8-0.1l-0.6,0c-0.3,0-0.3,0-0.3,0.3l0,1.5
			c0,0.6,0,0.9,0.2,1.1c0.1,0.1,0.3,0.2,1.3,0.2c0.8,0,1.1,0,1.3-0.2c0.2-0.1,0.5-0.5,0.7-1c0.1-0.1,0.2,0,0.3,0.1
			c-0.1,0.4-0.3,1.2-0.5,1.5c-0.9,0-1.9,0-2.8,0l-0.9,0c-0.5,0-0.8,0-1.4,0c-0.1,0-0.1-0.2,0-0.3l0.3,0c0.6-0.1,0.7-0.2,0.7-1.3
			L33.7,62.6z"/>
                <path d="M48,60.9c-0.2-0.5-0.6-1.7-0.6-2.1c0-0.1,0.2-0.2,0.5-0.4l0.1-0.1c0-0.1,0-0.2-0.1-0.2c-0.3,0.1-0.6,0.2-1,0.4
			c-0.4,0.1-0.7,0.3-1.2,0.4c-0.1,0.1,0,0.2,0.1,0.3l0.2,0c0.4-0.1,0.6-0.1,0.7,0c0.3,0.2,0.8,1.5,1,2l0.8,2.1
			c0.1,0.2,0.1,0.4,0.1,0.4l0,0c-0.6-0.3-1.3-0.7-2.3-1.2l-1.4-0.7c-0.5-0.2-2.1-1.1-2.5-1.5c-0.1,0.1-0.3,0.1-0.4,0.2
			c-0.2,0.1-0.7,0.3-1.1,0.4c-0.1,0.1,0,0.2,0.1,0.3l0.2,0c0.2-0.1,0.5-0.1,0.7,0c0.3,0.1,0.4,0.4,0.5,0.6l1.1,2.8
			c0.2,0.5,0.6,1.7,0.6,2.1c0,0.1-0.1,0.2-0.4,0.4L43.3,67c0,0.1,0,0.2,0.1,0.2c0.3-0.1,0.6-0.3,1-0.4c0.4-0.1,0.7-0.3,1.2-0.4
			c0.1-0.1,0-0.2-0.1-0.3l-0.3,0.1c-0.3,0.1-0.5,0.1-0.6,0c-0.3-0.2-0.8-1.5-0.9-2l-0.8-2.1c-0.1-0.3-0.2-0.5-0.2-0.7l0,0
			c0.3,0.1,1.3,0.7,1.6,0.9l3.2,1.7c1.2,0.7,1.6,0.9,1.8,1c0.1,0,0.1-0.1,0.1-0.2c-0.1-0.2-0.6-1.4-0.7-1.7L48,60.9z"/>
                <path d="M56.2,60.7c-0.7,0.6-1.3,0.7-1.5,0.8c-0.2-0.1-0.8-0.6-1.2-0.9c0-0.1,0.1-0.2,0.2-0.2c0.5,0.3,1.5,0.8,2.4,0
			c0.7-0.6,0.6-1.3,0.2-1.8c-0.3-0.4-0.7-0.7-1.6-0.6l-1.2,0.1c-0.6,0.1-1.5,0-2-0.7c-0.6-0.8-0.6-1.9,0.5-2.8
			c0.2-0.2,0.6-0.4,0.8-0.5c0.1-0.1,0.3-0.1,0.3-0.2c0.2,0.1,0.7,0.5,1,0.9c0,0.1-0.1,0.2-0.2,0.2c-0.4-0.3-1.1-0.6-1.9,0
			c-0.8,0.7-0.5,1.3-0.2,1.7c0.4,0.5,1.1,0.5,1.5,0.4l1-0.1c0.8-0.1,1.7-0.1,2.3,0.7C57.4,58.7,57.3,59.8,56.2,60.7z"/>
                <path d="M57.4,51.6c-0.9-0.6-1.1-0.7-1.5-0.3l-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0.3-0.4,0.5-0.7,0.8-1.1c0.2-0.3,0.4-0.6,0.7-1.1
			c0.1,0,0.2,0.1,0.2,0.1l-0.1,0.2c-0.3,0.5-0.2,0.7,0.7,1.3l3,2.2c0.9,0.6,1,0.7,1.5,0.2l0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
			c-0.3,0.4-0.5,0.7-0.8,1c-0.3,0.4-0.5,0.7-0.7,1.1c-0.1,0-0.2,0-0.2-0.1l0.1-0.2c0.3-0.5,0.2-0.6-0.7-1.3L57.4,51.6z"/>
                <path d="M66,47.1c-0.3,0.8-0.8,1.2-0.9,1.4c-0.3,0-1-0.1-1.5-0.3c-0.1-0.1,0-0.2,0.1-0.2c0.6,0,1.7,0,2.1-1.1
			c0.3-0.8-0.1-1.4-0.7-1.7c-0.4-0.2-0.9-0.3-1.7,0.3l-1,0.7c-0.5,0.4-1.3,0.7-2.1,0.4c-0.9-0.4-1.4-1.4-0.9-2.6
			c0.1-0.3,0.3-0.6,0.5-0.9c0.1-0.1,0.2-0.2,0.2-0.3c0.3,0,0.9,0.1,1.3,0.3c0,0.1,0,0.2-0.1,0.3c-0.5,0-1.3,0-1.6,0.9
			c-0.4,1,0.2,1.4,0.7,1.6c0.6,0.2,1.2-0.1,1.5-0.4l0.8-0.6c0.6-0.5,1.4-0.9,2.3-0.5C66.1,44.8,66.5,45.9,66,47.1z"/>
            </g>
        </g>
        <path d="M35.4,0C15.9,0,0,15.9,0,35.4C0,55,15.9,70.9,35.4,70.9C55,70.9,70.9,55,70.9,35.4C70.9,15.9,55,0,35.4,0z M35.4,69.8
	c-19,0-34.4-15.4-34.4-34.4c0-19,15.4-34.4,34.4-34.4c19,0,34.4,15.4,34.4,34.4C69.8,54.4,54.4,69.8,35.4,69.8z"/>
        <g>
            <path d="M43.8,52.6c-0.7,0.5-1.2,1.3-1.5,2c0.7,0.5,2.2,1.2,4.7,1.6c1.1-0.6,2-1.2,3-2c-0.9-0.2-1.8-0.7-2.4-1
		C46.6,52.6,45.2,51.6,43.8,52.6z"/>
            <path d="M27.2,47.9c-0.9-0.2-1.3-0.2-2.6,0.2c-2.2,0.7-5.7,4.6-5.7,4.6c1,1,2.2,1.9,3.4,2.7c1-1.2,3.5-4.1,5.9-4.1
		c1.3,0,2,0.5,3,1.2c0.1-0.1,0.2-0.3,0.3-0.4C29.6,51.1,27.2,47.9,27.2,47.9z"/>
            <path d="M34.8,51.3c-1.1,0.1-1.8,1-2.3,1.9c2,1.4,3.8,3,6.4,3.7c2,0.6,2.8,0.8,4.9,0.9c0.9-0.3,1.8-0.7,2.7-1.2
		c-1.3-0.2-3-0.8-4.4-1.4C39.6,54.1,36.9,51,34.8,51.3z"/>
        </g>
        <path d="M28.7,52.5c-3,0-4.2,2.5-5.1,3.6c3.5,2,7.6,3.2,11.9,3.2c2.3,0,4.5-0.3,6.6-0.9c-1.3-0.1-3-0.4-4.4-1
	C34.3,56.1,30.8,52.5,28.7,52.5z"/>
        <path d="M49.9,50.5c-2-2.5-3.5-3-4.3-2.8c-0.8,0.2-0.6,0.8-1.5,1.8c-0.8,1-2.7,2.1-3.1,2.3c0.3,0.3,0.7,0.8,0.8,0.9
	c0.9-0.9,2.2-1.7,3.3-1.7c2.1,0,2.7,1.7,5.5,2.7c0.6-0.5,1.2-1,1.7-1.5C52.4,52.2,51.1,52,49.9,50.5z"/>
        <g>
            <path d="M45.3,29.9c-0.1-0.1,0-0.3-0.1-0.4c-0.1-0.4-0.3-0.8-0.3-1.3c-0.1-0.3,0.2-0.4,0.4-0.5c0.1-0.3,0.1-0.6,0-0.8
		c-0.1-0.4-0.7-0.5-0.7-1c0-0.3-0.2-0.6-0.2-1c-0.2-0.5-0.1-1.1-0.2-1.6c-0.1-0.6-0.1-1.2-0.2-1.8c-0.1-0.7-0.2-1.4-0.4-2.1
		c-0.1-0.6-0.3-1.2-0.6-1.7c0-0.4-0.4-0.7-0.2-1.1c0.3-0.5,0.6-1,0.8-1.5c0.2-0.3,0.1-0.7,0.2-1.1c0-0.3,0.1-0.6,0-0.9
		c-0.1,0-0.1,0-0.1,0c-0.1,0.5-0.7,0.7-1.1,1c-0.6,0.2-1.2,0.1-1.9,0.2c-0.4,0-0.7,0.4-1.2,0.1c-0.2-0.1-0.4-0.2-0.7-0.3
		c-0.5,0-1,0-1.4,0c-0.7,0-1.2-0.4-1.9-0.3c-0.9,0-1.6,0.4-2.5,0.3c-0.8-0.2-1.6,0.1-2.2,0.4c-0.2,0-0.5-0.2-0.8-0.2
		c-0.7-0.3-1.6,0.2-2.1-0.3c-0.3-0.3-0.5-0.6-0.5-1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.3-0.3,0.6-0.3,0.9c-0.1,0.4,0,0.9,0.1,1.3
		c0.1,0.7,0.6,1.3,0.5,2c-0.1,0.3-0.3,0.7-0.4,1C27,19,27.1,19.5,27,19.9c-0.3,0.8-0.1,1.6,0,2.5c-0.3,1-0.4,2-0.5,3
		c-0.1,0.7,0,1.4-0.3,2c-0.4,0.4-0.7,0.7-0.6,1.3c0,0.3,0.2,0.5,0.3,0.7c0.1,0.5,0.1,1.1,0,1.6c0,0.4-0.4,0.6-0.5,0.9
		c-0.1,0.4,0.2,0.6,0.4,0.8c0.1,0.2,0.1,0.6,0.2,0.8c0.1,0.7,0.2,1.3,0.3,2c0.2,1,0.3,2,0.5,2.9c0.2,1.2,0.5,2.3,0.8,3.4
		c0.4,0.8,0.5,1.7,0.8,2.5c0.2,0.7,0.6,1.3,0.6,2c0,0.1,0,0.2,0.1,0.2c0,0.5-0.4,0.8-0.3,1.3c0,0,1.5,2.6,3.2,3.7
		c0.7-0.8,1.7-1.5,3-1.5c2.1,0,4,2.1,6,3.7c0.1-0.2,0.3-0.3,0.5-0.5c-0.7-0.9-3.6-4.3-6-4.7c0,0,0.2-0.5,0-0.7
		c-0.2-0.3-0.2-0.5-0.2-0.8c0-0.1-0.1-0.4,0-0.5c0.4,0.1,0.5,0.5,0.7,0.8c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0-0.4,0.5-0.6,0.7-0.8
		c0.1,0,0.1,0,0.2,0.1c0.1,0.4,0,0.9,0.3,1.2c0.1,0.1,0,0.2-0.1,0.3c0,0.3-0.2,0.2-0.2,0.2c1.8,0.9,2.8,1.9,3.8,3.1
		c0.7-0.5,3.2-2.1,3.1-3.7c0-0.5-0.3-0.5-0.4-0.8c-0.2-0.2-0.4-0.4-0.5-0.7c-0.3-0.5-0.5-1.1-0.4-1.8c0.2-0.5,0.2-1,0.4-1.5
		c0.3-0.9,0.5-1.8,0.7-2.7c0.3-1.2,0.7-2.5,0.8-3.8c0.2-0.4,0.2-0.9,0.3-1.4c0.2-0.4,0.1-1,0.2-1.5c0.1-0.4,0-0.8,0.2-1.1
		c0.1-0.2,0-0.4,0.1-0.6c0-0.3,0.3-0.2,0.3-0.5c0.2-0.1,0.3-0.4,0.4-0.5C45.9,30.6,45.5,30.3,45.3,29.9z M44.1,26.4
		c0.2,0.1,0.4,0.1,0.5,0.3c0.2,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3-0.1,0.3c-0.4,0-0.7-0.2-0.9-0.4C43.8,26.8,44,26.6,44.1,26.4z
		 M42,31.3c0,0.6,0,1.3-0.1,1.8c-0.1,0.1,0,0.2,0,0.3c-0.1,0.6,0,1.3-0.1,1.9l-0.1,0C41.4,35,41,34.9,41,34.5c0.3-1,0.2-2.2,0.3-3.3
		c0.1-0.9,0.1-1.9,0-2.8c0,0,0-0.1,0.1-0.1c0.3,0.1,0.4,0.4,0.7,0.5C42.1,29.7,41.9,30.5,42,31.3z M28.3,23.4
		c-0.3,0-0.3-0.3-0.4-0.5c-0.1-0.1-0.1-0.3-0.1-0.4c0.1-0.1,0.1-0.2,0.1-0.3c-0.1-0.1-0.3,0-0.3-0.2c0-0.2-0.1-0.5,0.2-0.6
		c0.2-0.1,0.5,0,0.8-0.2c0,0,0-0.1-0.1-0.1c-0.3,0-0.5,0.1-0.7,0.2c-0.1,0-0.1-0.1-0.1-0.2c0-0.7-0.1-1.3,0.1-1.8
		c0-0.1-0.1-0.3,0-0.4c0.3-0.1,0.4,0.4,0.7,0.3v0c-0.2-0.2-0.4-0.4-0.6-0.4c-0.1-0.2,0-0.5,0-0.7c0.1-0.1,0.1-0.2,0.2-0.1
		c0.2,0.1,0.3,0.3,0.5,0.4c0.1,0,0.1-0.1,0.1-0.2c-0.2-0.3-0.8-0.3-0.5-0.8c0-0.2,0.1-0.4,0.3-0.4c0.2,0.1,0.3,0.3,0.5,0.4l0.1,0
		c-0.2-0.2-0.3-0.4-0.2-0.6c0.1-0.1,0.3,0.1,0.4,0.1l0.2,0.1c0.2,0.1,0.5,0.2,0.5,0.5c0.2,0.3,0.2,0.6,0.5,0.8
		c0.1,0.2,0.2,0.3,0.3,0.4c-0.1,0.1-0.2,0.1-0.2,0c-0.2-0.1-0.3-0.4-0.5-0.6c-0.1,0-0.1,0-0.1,0.1c0.1,0.2,0.1,0.5,0.3,0.6l0,0.2
		c-0.3,0-0.7-0.3-0.9-0.5c0,0-0.1-0.1-0.2,0c-0.1,0.1,0,0.2,0,0.2c0.2,0.3,0.3,0.6,0.6,0.7c0.1,0,0.1,0.2,0,0.2
		c0,0.2-0.2,0.1-0.3,0.1c-0.2-0.2-0.4-0.4-0.6-0.5l-0.1,0c0,0.2,0.2,0.3,0.2,0.4c0.2,0.3,0.6,0.3,0.5,0.7c0,0.1,0,0.2-0.1,0.2
		c-0.5-0.1-1.2-0.3-1.6,0.2c0,0.1,0,0.1,0.1,0.1c0.5,0,0.9,0,1.3,0.3c0.1,0.1,0.2,0.2,0.1,0.3c-0.1,0-0.2,0-0.3,0.1
		c-0.1,0.1-0.3,0.2-0.5,0.3c0,0,0,0.1,0,0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.1,0,0.2,0,0.2,0.1c0,0.1-0.1,0.1-0.2,0.2
		c-0.2,0.2-0.5,0.3-0.5,0.6l0.1,0c0.3,0,0.5-0.5,0.8-0.4c0.1,0,0.1,0.1,0.1,0.2c-0.4,0.3-1,0.5-1.1,1.1c0,0,0,0.1,0,0.1
		c0.1,0,0.1-0.1,0.2-0.2c0.4-0.3,0.7-0.6,1.2-0.7c0,0,0.1,0,0.1,0.1c0,0.2-0.4,0.4-0.4,0.6c-0.1,0.2-0.1,0.4-0.2,0.6
		c0,0,0.1,0.1,0.1,0c0.2-0.3,0.4-0.8,0.8-1c0,0.1,0.1,0,0.2,0.1c0.2,0,0.5,0.1,0.6,0.3c0,0.5-0.2,1.1,0,1.5c0.1-0.1,0-0.2,0.1-0.3
		c0-0.4,0.2-0.6,0.3-1c0-0.1,0.2-0.1,0.2-0.1c0.2,0.1,0.2,0.3,0.4,0.4c0.1-0.1,0-0.4,0-0.5c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.2,0
		c0.1-0.1,0-0.2,0-0.2c0.2-0.2,0.3-0.6,0.7-0.5l0.1,0.1c0,0.2,0,0.5-0.1,0.7c-0.1,0-0.1-0.2-0.3-0.1c-0.1,0.1,0,0.2,0,0.3
		c0.1,0.2,0.2,0.4,0.3,0.6c0,0.1,0.1,0.1,0,0.2c-0.3,0-0.4-0.3-0.5-0.4c-0.1-0.1-0.2,0-0.2,0.1c-0.1,0.3,0.2,0.6,0.4,0.8
		c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2,0,0.3l0.1,0.2c-0.1,0.1-0.3,0-0.5-0.1c-0.3-0.2-0.6-0.6-0.7-1c0,0-0.1-0.1-0.1,0
		c-0.1,0.3,0,0.5,0.2,0.7c0.2,0.1,0.3,0.3,0.3,0.5c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3-0.2-0.3-0.6-0.3-0.9c0,0-0.1-0.1-0.1,0
		c-0.1,0.2-0.1,0.4-0.1,0.5c0,0.1,0.1,0.4-0.1,0.5c-0.2,0.1-0.4,0-0.6,0.1c-0.2-0.1-0.2-0.4-0.2-0.6c0-0.1-0.1,0-0.1,0
		c-0.2,0.1-0.2,0.6-0.5,0.5c-0.2-0.1-0.5-0.2-0.7-0.5c-0.1-0.3,0.2-0.4,0.2-0.7c0.1-0.2,0.3-0.4,0.4-0.6c-0.1-0.1-0.3,0.1-0.4,0.2
		c-0.2,0.3-0.4,0.6-0.5,0.9c0,0.1-0.1,0.1-0.2,0.1c-0.3-0.3-1-0.5-0.9-1c0.1-0.1,0.1-0.2,0.2-0.3C28.5,23.2,28.5,23.4,28.3,23.4z
		 M35.3,24.5c0,0,0.1,0,0.1,0c0.1-0.2,0.1-0.6,0.1-0.8c0-0.7,0.1-1.4,0.2-2c0-0.1,0-0.3,0.2-0.3c0.2,0,0.5,0.1,0.6,0.3
		c0.1,0.9,0,1.9-0.3,2.7c-0.1,0.4-0.4,0.7-0.6,1.1c-0.1,0.1-0.1,0.4-0.4,0.4c-0.6-0.8-1-1.8-1-2.9c0-0.4-0.2-1,0.1-1.3
		c0.3-0.1,0.5-0.4,0.8-0.3l0,0.1C35.3,22.4,35,23.5,35.3,24.5z M37.9,27.4c-0.5-0.2-1-0.7-1-1.3c0-0.1,0.1-0.1,0.1,0l0.5,0.5
		c0.4,0.2,0.8,0.5,1,0.9c0,0.1,0.1,0.3-0.1,0.4c-0.2,0.2-0.4,0.3-0.7,0.3c-0.5-0.1-1-0.5-1.2-0.9c-0.1-0.3-0.1-0.7,0-1
		c0.2,0,0.1,0.2,0.2,0.3c0.2,0.4,0.6,1,1.1,1.1C38,27.5,38,27.4,37.9,27.4z M37.7,22.7c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.1-0.2,0.1-0.4,0.1-0.5c0,0,0-0.1,0-0.1c-0.1-0.1-0.2,0-0.2-0.2c0-0.1,0-0.1,0-0.2l0.2,0c0.2,0.2,0.5,0.5,0.8,0.7
		c0,0.2,0,0.4-0.1,0.6c0,0.2-0.2,0.4-0.2,0.6c0,0.1,0.1,0.1,0.1,0c0.2-0.3,0.3-0.6,0.6-0.9c0.3-0.1,0.6,0,0.9,0
		c0.2,0.1,0.1,0.4,0.2,0.6c0,0.1,0,0.3,0.1,0.4c0.2,0,0.2-0.3,0.2-0.4c0.1-0.3,0-0.5,0-0.8c0-0.1,0-0.1,0-0.2c0.1-0.1,0.2-0.1,0.3,0
		c0.2,0.1,0.3,0.4,0.5,0.5c0.1,0,0-0.1,0-0.2c0-0.2-0.3-0.4-0.3-0.6c0-0.1,0.1-0.1,0.2-0.1c0.2,0.1,0.1,0.4,0.3,0.5
		c0.1,0,0-0.1,0-0.2c-0.1-0.2-0.2-0.4-0.2-0.6c0.1-0.1,0.2,0,0.2,0c0.3,0.1,0.5,0.2,0.8,0.4c0.3,0.2,0.3,0.6,0.4,0.9
		c-0.1,0.3,0.2,0.7-0.1,0.9c-0.2,0.1-0.4,0.3-0.6,0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.3-0.7-0.6-0.6c-0.1,0.1,0.1,0.3,0.1,0.5
		c0,0.3,0.3,0.6,0.1,0.9c-0.3-0.1-0.4,0.3-0.7,0.2c-0.3-0.1-0.6,0.5-0.9,0c0-0.4,0-0.8-0.1-1.3c0-0.1-0.1-0.3-0.2-0.4
		c-0.1,0.1,0,0.3-0.1,0.5c0,0.4-0.1,0.8-0.3,1.1c-0.1,0.1-0.2,0.2-0.3,0.1c0,0-0.1,0-0.1-0.1c0-0.2,0.1-0.4,0.1-0.6
		c0-0.1-0.1,0-0.1,0c-0.2,0.3-0.5,0.7-0.9,0.6c-0.1-0.1-0.1-0.1-0.2-0.1c0.1-0.2,0.4-0.4,0.4-0.6c-0.2,0-0.3,0.2-0.5,0.3
		c-0.1-0.7,0.6-1.1,0.7-1.8C37.8,22.7,37.8,22.7,37.7,22.7z M37.7,19.4c0.3-0.4,0.8-0.5,1.3-0.6c0.5,0,1.1,0.1,1.4,0.5
		c0.4,0.5,0.5,1.2,0.3,1.8c-0.3,0.6-0.9,1.1-1.6,1.1c-0.3,0.1-0.5-0.1-0.8-0.2c-0.4-0.2-0.5-0.6-0.7-0.9
		C37.3,20.6,37.5,19.9,37.7,19.4z M42.9,27.4c0,0,0.1-0.1,0.2-0.1c0.2,0,0.3,0,0.5,0.1c0.1,0.2,0.2,0.5,0.1,0.7
		c-0.3,0.1-0.5,0-0.7-0.2C42.8,27.7,43,27.6,42.9,27.4z M43.2,27.1c-0.3-0.1-0.6-0.4-0.8-0.7c-0.1-0.3-0.3-0.5-0.3-0.8
		c0-0.2-0.1-0.4,0-0.6h0c0.2,0.5,0.6,0.9,1,1.3c0.1,0.1,0.3,0.1,0.4,0.3C43.4,26.8,43.3,27,43.2,27.1z M40.8,33.2
		c-0.3,0.1-0.5-0.3-0.8-0.4c-0.3-0.4-0.6-0.8-0.6-1.3c0,0,0.1-0.1,0.1-0.1c0.2,0.3,0.4,0.7,0.8,0.8c0.1,0,0.1,0,0.1-0.1
		c-0.1-0.3-0.3-0.6-0.2-0.9c0.2,0,0.3,0.3,0.6,0.3c0.1,0.1,0.1,0.4,0.1,0.5C40.8,32.5,40.9,32.9,40.8,33.2z M40.7,39.7
		c0-0.8,0.2-1.7,0.2-2.5c0.1-0.1,0.3-0.2,0.5-0.2c0,0.1,0,0.3,0,0.5c0,0.8-0.1,1.6-0.1,2.5c0,0.3-0.1,0.7-0.1,1
		c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.7-0.2,1.3-0.3,1.9c-0.1,0.1-0.1,0.4-0.3,0.4c-0.2-0.2-0.1-0.5-0.1-0.7c0.1-0.2,0-0.5,0.1-0.7
		C40.4,41.6,40.8,40.7,40.7,39.7z M40.4,45.6c0.2,0.4,0.2,0.8,0.3,1.1c0,0.2,0.1,0.4-0.1,0.5c-0.5-0.1-0.9,0.1-1.3,0.4
		c-0.1,0-0.2,0-0.3-0.1c-0.1-0.2,0-0.4,0-0.6c0.1-0.2,0-0.5,0.2-0.7c0.1-0.1,0.3-0.1,0.3-0.3c-0.2-0.1-0.5,0-0.6-0.2
		c-0.2-0.3-0.4-0.6-0.4-1c0.3-0.3,0.8-0.4,1.2-0.3c0.1,0.1,0.3,0.1,0.3,0.2C40.3,45,40.3,45.3,40.4,45.6z M39.7,47.9
		c0.1-0.3,0.4-0.3,0.6-0.4c0.1,0,0.2,0,0.2,0.1c-0.1,0.6-0.2,1.2-0.6,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0,0-0.1,0-0.2
		C40,48.8,39.9,48.3,39.7,47.9z M38.7,49c-0.4-0.1-0.9-0.4-0.9-0.9c0-0.1-0.1-0.2-0.1-0.3c0.2-0.2,0.5-0.2,0.8-0.2l0.1,0.1
		C38.5,48,38.4,48.6,38.7,49z M38.1,47.3c-0.2,0-0.4,0.2-0.6,0.2c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.7,0-1.4,0.6-1.8
		c0.1-0.1,0.2,0,0.3,0c0.2,0.2,0.3,0.5,0.4,0.7c0.1,0.2,0.2,0.3,0.3,0.6c0.1,0.3,0,0.6,0,0.9C38.6,47.3,38.4,47.1,38.1,47.3z
		 M33.5,48.2c0.2-0.1,0.4-0.3,0.7-0.1c0.2,0.2,0.2,0.4,0.2,0.6c-0.2,0.4-0.6,0.8-1,0.9c0,0-0.1,0-0.1-0.1c0-0.3,0.2-0.5,0.1-0.8
		C33.3,48.5,33.4,48.4,33.5,48.2z M34.3,47.9c-0.2,0-0.4-0.1-0.5-0.2c-0.2,0-0.2,0.2-0.4,0.3c-0.1,0-0.1,0.1-0.2,0.1
		c-0.1-0.2-0.1-0.4-0.1-0.6c-0.1-0.2,0-0.5,0.1-0.7c0.2-0.1,0.3-0.3,0.5-0.3c0.1-0.1,0.1-0.1,0.1-0.2c-0.1-0.1-0.3-0.1-0.4-0.1
		c-0.2,0.1-0.3,0.2-0.5,0.4c-0.2-0.3-0.2-0.6-0.2-1c0-0.3,0.1-0.7,0.4-0.8c0.2,0.1,0.6,0.1,0.7,0.4c0.4,0.5,0.8,1,0.9,1.7
		c0,0.4,0.1,0.8-0.1,1.2C34.5,48,34.4,47.9,34.3,47.9z M33.1,44c-0.6-0.3-0.7-1-0.6-1.6c0-0.1,0-0.3,0.2-0.3
		c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.6,1,1,1.5,1.5c0.4,0.2,0.6,0.7,0.6,1.2c0.1,0.1,0,0.3,0,0.4c0,0-0.1,0-0.1-0.1
		C34.4,44.8,33.8,44.2,33.1,44z M30.3,26.6c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.2,0.3-0.4,0.5-0.6c0.1,0,0.2,0,0.2,0.1
		c0,0.3-0.1,0.5-0.2,0.8c-0.3,0.6-0.7,1.2-1.4,1.3c-0.2,0-0.4-0.1-0.5-0.2c0-0.3-0.1-0.5-0.1-0.7c0-0.5,0.4-0.9,0.7-1.4
		c0.1-0.1,0.3-0.2,0.3,0c-0.1,0.3-0.1,0.7-0.1,1C30.2,26.5,30.2,26.6,30.3,26.6z M31.8,30.3c0.4-0.3,0.7-0.8,1-1.1c0,0,0.1,0,0.1,0
		c0,0.7-0.4,1.3-0.9,1.9c-0.3,0.3-0.8,0.5-1.2,0.4c-0.3-0.1-0.4-0.4-0.5-0.6c0-0.3,0.1-0.5,0.2-0.7c0.4-0.3,0.9-0.6,1.1-1
		c0-0.1,0.1-0.1,0.2-0.1c0,0.5-0.3,0.9-0.5,1.3C31.5,30.5,31.6,30.4,31.8,30.3z M32.9,27.6c0.4-0.1,0.6-0.4,0.8-0.7
		c0-0.2,0.2-0.3,0.3-0.5c0,0,0,0,0.1,0c0,0.7-0.3,1.4-0.9,1.9c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2-0.1-0.5-0.2-0.5-0.4
		c0-0.5,0.1-0.9,0.5-1.1c0.1-0.1,0.2-0.3,0.4-0.4c0,0.3-0.1,0.6-0.2,0.8C33,27.4,32.8,27.5,32.9,27.6z M34.8,34.5
		C34.8,34.5,34.8,34.6,34.8,34.5c0.2-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2,0,0.2,0c0.3,0.2,0.1,0.6,0.2,0.9c0,0.3-0.1,0.7-0.2,1
		c0.1,0.1,0.2,0,0.2,0c0.3-0.4,0.2-0.8,0.3-1.3c0-0.1,0-0.2,0.1-0.3c0.1,0,0.1,0,0.1,0.1c0,0.3,0,0.6,0,0.9l0.1,0
		c0.3-0.4,0.1-0.9,0.3-1.4c0.1-0.2,0-0.5,0.1-0.8c0.2,0,0.2,0.2,0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,1,0.4,2.4-0.6,3
		c-0.3,0.2-0.8,0.1-1.1,0c-0.3-0.1-0.4-0.5-0.5-0.7c-0.2-0.6-0.4-1.2-0.3-1.9c0.1-0.6,0.2-1.3,0.5-1.8c0.1,0,0,0.2,0,0.3
		C34.7,33.5,34.6,34,34.8,34.5z M37.7,32.4c-0.1-0.2-0.1-0.5-0.2-0.8c0-0.2,0.2,0,0.2,0.1c0.2,0.6,1.1,0.7,1.1,1.4
		c0.1,0.2,0.1,0.4,0,0.6c-0.2,0.2-0.5,0.3-0.9,0.3c-0.5-0.2-0.8-0.7-1.2-1.1c0-0.3-0.3-0.5-0.1-0.8c0.1,0,0.2,0.1,0.2,0.1
		c0.3,0.3,0.6,0.6,0.9,0.8c0.1,0,0.1,0,0.2-0.1C38,32.7,37.7,32.6,37.7,32.4z M37.2,38.3c-0.1-0.2-0.4-0.4-0.4-0.6
		c0-0.1-0.1-0.2,0.1-0.3c0.5,0.2,0.9,0.5,1.2,0.9c0.1,0.3,0.1,0.7,0,0.9c0,0.1-0.1,0.3-0.2,0.3c-0.1,0.1-0.3,0.1-0.5,0.1
		c-0.5-0.3-0.8-0.9-0.9-1.5h0.1c0.2,0.2,0.3,0.7,0.7,0.5C37.2,38.5,37.2,38.4,37.2,38.3z M38,37.1c-0.3-0.4-0.6-0.8-0.6-1.4
		c0,0,0.1-0.1,0.1,0c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.2,0.5,0.5,0.5c0.1,0,0-0.2,0-0.2c0-0.3-0.2-0.5-0.1-0.8h0
		c0.4,0.4,0.9,0.8,0.7,1.4c0,0.2-0.2,0.3-0.3,0.3C38.4,37.2,38.2,37.2,38,37.1z M32.9,36.5c0.3-0.3,0.5-0.6,0.7-0.9
		c0.1,0,0.1,0,0.1,0.1c0.1,0.8-0.3,1.4-0.9,1.8c-0.2,0.1-0.4,0.1-0.5,0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.6,0.1-1.1,0.5-1.5
		c0.2-0.2,0.2-0.4,0.4-0.5c0.2,0.1,0.1,0.5,0.1,0.7c0,0.3-0.2,0.4-0.2,0.7C32.8,36.7,32.8,36.6,32.9,36.5z M33.5,37.7
		c0.1-0.3,0.4-0.4,0.6-0.7c0.1,0,0.2,0,0.2,0.1c0,0.5-0.3,0.9-0.5,1.3c0.1,0.1,0.2-0.1,0.4-0.1c0.3-0.2,0.3-0.6,0.5-0.9
		c0.1,0,0.2,0.1,0.2,0.1c0.3,0.7-0.1,1.3-0.4,1.8c-0.2,0.4-0.6,0.2-0.9,0.3c-0.2,0-0.4-0.1-0.4-0.3C32.9,38.7,33.3,38.3,33.5,37.7z
		 M34.3,31.9c0,0.3-0.1,0.5-0.2,0.8l-0.1,0.1c0.1,0.1,0,0.2,0,0.3c-0.1,0.6-0.6,1-1.2,1.1c-0.2,0-0.4-0.1-0.6-0.2
		c-0.3-0.2-0.2-0.6-0.2-1c0.2-0.6,0.6-1.2,1-1.8c0,0,0.1-0.1,0.1,0c0.1,0.6,0,1.1-0.2,1.6c0,0.1,0,0.2,0.1,0.3
		c0.2-0.1,0.2-0.3,0.4-0.4l0-0.1c0.2-0.4,0.5-0.8,0.6-1.3c0-0.1,0.1-0.1,0.1-0.1C34.4,31.6,34.3,31.8,34.3,31.9z M31.3,28.9
		c-0.2,0.2-0.5,0.4-0.8,0.4c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.6,0.4-0.9,0.5-1.4c0.1-0.1,0.2-0.3,0.3-0.3c0.1,0.3-0.1,0.6-0.1,1
		c0.1,0.1,0.2-0.1,0.3-0.2c0.2-0.4,0.4-0.9,0.5-1.3c0-0.1,0.1,0,0.1,0C31.9,27.6,31.7,28.3,31.3,28.9z M30.2,32.5l0.5-0.5
		c0.1,0,0.1,0,0.1,0.1c0.1,0.3-0.1,0.7-0.2,0.9c0,0.1-0.1,0.3,0,0.4c0.2,0,0.3-0.2,0.4-0.3c0.2-0.3,0.4-0.6,0.7-0.8c0,0,0.1,0,0.1,0
		c0,0.5-0.3,0.9-0.5,1.3c-0.2,0.4-0.6,0.7-1.1,0.6l-0.1-0.1C30.1,33.6,29.9,33,30.2,32.5z M31.9,38.6c0.1-0.1,0.1-0.2,0.2-0.2
		c0,0.2-0.2,0.3-0.2,0.5c-0.1,0.2-0.2,0.4-0.2,0.6c0.1,0.1,0.2,0,0.3,0c0.2-0.2,0.4-0.4,0.6-0.6c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
		c-0.2,0.4-0.4,1-1,1.1c-0.2,0-0.4,0-0.5-0.1c-0.1-0.3,0-0.7,0.2-1C31.5,38.9,31.6,38.6,31.9,38.6z M31.8,42.4
		c0,0.4,0.1,0.9-0.1,1.2c-0.1,0.1-0.2,0.2-0.3,0.1c-0.2-0.1-0.2-0.2-0.2-0.4c0-0.4-0.1-0.8,0.1-1.2c0.1-0.3,0.1-0.5,0.2-0.8
		c0-0.2,0.1-0.4,0.1-0.6c0.1,0.1,0,0.3,0.1,0.4C31.8,41.6,31.6,42,31.8,42.4z M31.1,45.4c0.1-0.2,0.2-0.5,0.4-0.7
		c0.1-0.2,0.4-0.1,0.6-0.2c0.2,0.1,0.6-0.1,0.5,0.2c-0.2,0.4-0.4,0.9-0.2,1.3c-0.1,0.2-0.6,0-0.5,0.3c0.2,0.3,0.5,0.1,0.6,0.4
		c0.1,0.3,0.1,0.7,0.2,1c0,0.1-0.1,0-0.1,0c-0.3-0.2-0.6-0.4-0.9-0.2c-0.2,0.1-0.3,0.3-0.5,0.5C31.1,48.1,31,48,31,47.9
		C30.8,47.1,30.9,46.2,31.1,45.4z M33.6,42.8c-0.2-0.3-0.3-0.7-0.5-1c0-0.4-0.1-0.7,0-1.1c0-0.1,0-0.2,0.1-0.3l0,0
		c0,0.8,0.5,1.3,0.9,1.9c0.3,0.3,1,0.4,0.9,1c0,0.3,0.2,0.5,0,0.8c-0.1,0-0.1,0-0.1-0.1C34.6,43.5,34.1,43.2,33.6,42.8z M35.1,41.9
		c-0.4-0.4-0.7-0.9-0.5-1.5c0-0.4,0.2-0.8,0.5-1c0.1-0.1,0-0.3,0.2-0.3c0.1,0.1,0,0.2-0.1,0.2c0.1,0.4,0.3,1-0.1,1.3
		c0,0.1,0,0.2,0.1,0.2c0.4,0.1,0.3-0.4,0.5-0.5c0,0.3-0.2,0.6-0.4,0.8c0,0.1,0.2,0.1,0.2,0.2c0.2,0,0.3-0.3,0.4-0.4
		c0.1-0.5,0.1-0.9-0.2-1.3c0-0.1-0.1-0.3,0.1-0.5c0.3,0,0.4,0.3,0.5,0.5c0.2,0.4,0.3,1,0.1,1.4c0.1,0.5-0.4,0.6-0.6,0.9
		C35.6,41.9,35.3,42,35.1,41.9z M36.1,42.8c0-0.2,0.2-0.3,0.3-0.5c0.2-0.5,0.4-0.9,0.7-1.3c0.2-0.2,0.1-0.5,0.2-0.8
		c0.1,0,0.1,0.2,0.2,0.3c0.1,0.5,0.1,1.1-0.1,1.5c-0.3,0.6-0.8,1.1-1,1.6c-0.1,0.1-0.1,0.3-0.2,0.4C35.9,43.8,36,43.2,36.1,42.8z
		 M36.5,44.8c0.1-0.5,0.4-0.9,0.6-1.3c0.4-0.5,0.6-1,0.8-1.5c0-0.1-0.1-0.2,0-0.3c0.2,0.3,0.3,0.7,0.2,1.1c0,1-1.1,1.6-1.2,2.5
		c-0.1,0.1-0.2,0.3-0.3,0.4C36.4,45.4,36.5,45.1,36.5,44.8z M38.8,42.8c0-0.6,0.2-1.1,0.2-1.7c0-0.1,0.1-0.2,0.1-0.1
		c0.1,0.6,0,1.2,0.2,1.7c0,0.4,0.1,0.9-0.3,1.1c-0.1,0.1-0.3-0.1-0.3-0.2C38.6,43.3,38.9,43.1,38.8,42.8z M39.7,39.3
		c-0.1,0.1-0.2,0.2-0.4,0.1c-0.6-0.2-0.8-0.8-0.9-1.3c0.1,0,0.2,0.2,0.3,0.2c0.2,0.1,0.3,0.5,0.6,0.3c0-0.3-0.3-0.4-0.4-0.7
		c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.1,0.2,0.3,0.3c0.3,0.1,0.6,0.4,0.7,0.7C39.9,38.8,39.8,39.1,39.7,39.3z M38.5,30.6
		c-0.4-0.3-0.5-0.7-0.6-1.2c0-0.1-0.1-0.3,0-0.4c0.1,0,0.1,0.1,0.1,0.1c0.2,0.2,0.4,0.6,0.7,0.7c0.1,0.1,0.1,0.1,0.1,0.2
		c0.1,0,0.2,0,0.2-0.1c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.3-0.4-0.5-0.3-0.9c0.1-0.1,0.2,0.1,0.4,0.2c0.4,0.1,1,0.4,1.1,0.9
		c0,0.3,0,0.7-0.2,0.9C39.4,30.8,38.9,30.8,38.5,30.6z M39.7,27.6c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.2,0.4,0.5,0.3,0.8
		c-0.1,0.1-0.1,0.3-0.2,0.4c-0.3,0-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.4-0.2-0.7c0-0.1,0.1,0,0.2,0c0.2,0.1,0.3,0.4,0.5,0.2
		C39.8,27.9,39.6,27.8,39.7,27.6z M40,27.4c-0.3-0.5-0.6-0.9-0.6-1.5c0,0,0.1,0,0.1,0c0.2,0.3,0.5,0.8,1,0.8
		c0.1-0.1-0.1-0.3-0.1-0.4c0-0.2-0.1-0.3-0.1-0.5c0.1,0,0.1,0.1,0.2,0.2c0.3,0.2,0.6,0.4,0.7,0.8c0,0.3,0,0.7-0.3,1
		C40.6,27.7,40.2,27.6,40,27.4z M36.8,31.2c-0.3,0.4-0.9,0.8-1.5,0.8c-0.3-0.1-0.6-0.1-0.8-0.4c0-0.2-0.3-0.3-0.2-0.6
		c-0.2-0.5-0.4-1-0.3-1.6c-0.1-0.6,0.1-1.3,0.2-1.9c0.1-0.1,0.2-0.4,0.3-0.2c0,0.6-0.2,1.1,0.1,1.6c0.1,0,0.1,0.1,0.2,0.1
		c0.2-0.1,0-0.4,0.1-0.6c0.1-0.1,0.1,0,0.2,0.1c0.1,0.6,0,1.3,0.3,1.8c0.1,0.1,0.2,0.2,0.3,0.1c0.1-0.1,0-0.1,0.1-0.2
		c-0.3-0.8-0.3-1.8,0-2.6c0.1,0,0.1,0,0.1,0.1c0.1,0.5-0.1,1.1,0.1,1.5c0.2-0.1,0.2-0.3,0.3-0.5c0.1-0.5,0-1.1,0.1-1.6
		c0.1,0,0,0.1,0.1,0.1C36.8,28.5,37.6,30,36.8,31.2z M34.4,19.9c0-0.1,0.1-0.3-0.1-0.3c-0.1,0-0.3,0.1-0.4,0.1c0,0-0.1-0.1-0.1-0.1
		c0,0,0,0,0-0.1c0.2-0.1,0.5-0.3,0.7-0.4c0.1-0.1,0.2-0.2,0.3-0.1C34.9,19.3,34.7,19.6,34.4,19.9C34.4,20,34.5,19.9,34.4,19.9z
		 M33.7,19c-0.2,0-0.4,0.1-0.6-0.1c0.1-0.2,0.3-0.1,0.4-0.2c0.3-0.1,0.5,0.2,0.7,0.2c0,0,0,0,0,0.1C34.1,19.1,33.9,19,33.7,19z
		 M34.4,17.4c-0.1,0-0.1-0.2-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0.1-0.2,0.3-0.3,0.5l0,0.1c-0.2,0-0.2-0.2-0.4-0.3
		c-0.1-0.3,0.2-0.5,0.3-0.7c0.2,0,0.5,0.1,0.6,0.3C34.3,17,34.4,17.2,34.4,17.4z M33.5,18.1c-0.1,0.3,0.3,0.2,0.4,0.3l0,0.1
		c0,0.1-0.2,0.1-0.3,0.1c-0.3-0.1-0.6,0-0.8,0.1c-0.1,0-0.2,0-0.3-0.1c0-0.2,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2
		c-0.3-0.2-0.7,0-1,0c-0.2,0.1-0.5,0.1-0.7,0c0,0,0-0.1,0.1-0.1c0.3,0.1,0.5-0.2,0.8-0.2c0.2-0.1,0.5,0,0.8,0
		C33.3,18,33.4,18,33.5,18.1z M32.8,17.5c-0.5,0-0.9,0.3-1.5,0.2c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.1-0.2,0.4-0.1,0.5-0.2
		c0.1,0,0.3,0,0.4-0.1c0.3,0,0.7,0,0.9,0.3l0,0.1C33,17.6,32.9,17.5,32.8,17.5z M31.4,18.9c0.6-0.2,1.2,0,1.6,0.3
		c0.4,0.5,0.5,1.3,0.2,1.9c-0.2,0.4-0.5,0.9-1,0.9c-0.6,0.3-1.2,0-1.7-0.4c-0.3-0.4-0.6-0.9-0.4-1.4C30.3,19.7,30.8,19.1,31.4,18.9z
		 M29.5,28.7C29.6,28.7,29.6,28.7,29.5,28.7c0,1.2,0.1,2.3,0.2,3.6c0.1,1.1,0.1,2.2,0.2,3.2c0,0.2-0.2,0.3-0.3,0.5
		c-0.1,0.1-0.2,0.3-0.4,0.4C29,36,29.1,35.6,29,35.2c-0.1-1.7-0.3-3.6-0.3-5.4c0.1-0.2,0.2-0.4,0.3-0.7C29.2,29,29.4,28.9,29.5,28.7
		z M30.1,35.8L30.1,35.8c0.3-0.3,0.6-0.6,0.8-0.8c0,0,0.1,0,0.1,0c-0.1,0.5-0.2,1-0.6,1.5c0,0.1,0,0.1,0.1,0.1
		c0.2-0.3,0.4-0.5,0.7-0.8c0.2-0.2,0.3-0.4,0.5-0.6c0.1,0.2,0,0.4,0,0.5c-0.1,0.8-0.4,1.5-1.2,1.9c-0.3,0-0.4,0.2-0.7,0.1
		c-0.2,0-0.4-0.2-0.5-0.4C29.2,36.7,29.6,36.2,30.1,35.8z M29.7,38.1L30,38c0,0,0.1,0,0.1,0.1c0.1,0.9,0.1,1.8,0.2,2.7
		c0,0.7,0.1,1.2,0.2,1.9c0.1,0.4,0,0.9,0.2,1.3c0.2,0.4-0.1,0.6-0.2,0.9c-0.3,0,0-0.4-0.2-0.6c-0.3-1.2-0.6-2.3-0.7-3.6
		c-0.1-0.6-0.1-1.2-0.2-1.8c-0.1-0.3,0-0.6-0.1-0.9C29.4,37.9,29.5,38.1,29.7,38.1z M29.4,46.6c0-0.4,0.1-0.8,0.3-1.1
		c0-0.2,0.2-0.2,0.4-0.3c0.2,0,0.4-0.2,0.6,0c0,0.1,0.2,0.2,0.1,0.4c-0.1,0.2-0.2,0.4-0.5,0.6c-0.1,0-0.3-0.1-0.3,0.1
		c0,0.3,0.4,0.1,0.5,0.3c0,0.2,0.1,0.5,0,0.7c-0.2,0-0.5,0-0.7,0.1c-0.1,0.1-0.2,0.2-0.3,0.1C29.3,47.3,29.3,46.9,29.4,46.6z
		 M29.9,47.6c0.2-0.1,0.4-0.1,0.6,0c0,0.2-0.1,0.5-0.1,0.7c0,0.3,0.2,0.6,0.5,0.7v0c-0.2,0.1-0.4-0.1-0.6-0.2
		c-0.3-0.2-0.3-0.5-0.4-0.7C29.9,48,29.7,47.8,29.9,47.6z M31.9,47.8c0.2,0,0.4,0,0.6,0.2c0,0.2-0.1,0.4-0.1,0.7
		c0,0.3,0,0.6,0.2,0.8l0,0.1c-0.2,0-0.4-0.2-0.5-0.3c-0.3-0.3-0.6-0.6-0.6-1C31.5,48.1,31.7,47.9,31.9,47.8z M41.3,47.6
		c-0.1,0,0-0.1,0-0.2c0.2-0.3,0.5-0.1,0.8-0.2l0,0c0.1,0.3-0.3,0.5-0.4,0.8c-0.1,0.1-0.1,0.2-0.1,0.2c-0.2,0.2-0.3,0.4-0.5,0.6
		l-0.1,0C41.1,48.5,41.5,48.1,41.3,47.6z M42.2,46.9C42.2,47,42.1,47,42,47c-0.3-0.1-0.5,0.1-0.8,0.1c-0.1-0.2-0.2-0.5-0.2-0.7
		c0.2-0.2,0.4-0.3,0.5-0.5c0-0.1,0-0.1-0.1-0.1c-0.2,0-0.4,0.4-0.6,0.1c-0.1-0.2-0.2-0.5-0.3-0.8c0.2-0.1,0.4,0,0.5,0.1l0.3,0.1
		c0.5,0.1,0.5,0.7,0.8,1C42.2,46.4,42.5,46.7,42.2,46.9z M41.1,36.8c-0.6,0.1-1.2-0.2-1.6-0.6c-0.2-0.3-0.4-0.6-0.4-1
		c0-0.1,0.1-0.1,0.2-0.1c0.3,0.3,0.7,0.6,1.1,0.7l0.1-0.1c0.1,0,0.1,0.2,0.2,0.1c0,0,0.1-0.1,0-0.1c-0.5-0.4-0.8-0.9-0.9-1.4
		c0-0.1,0.1-0.1,0.2-0.1c0.5,0.3,1,0.7,1.5,1.1c0.2,0.3,0.4,0.6,0.3,0.9C41.5,36.5,41.4,36.7,41.1,36.8z M41.4,27c0-0.1,0.1,0,0.1,0
		c0.2,0.2,0.3,0.4,0.5,0.6c0.4,0.3,0.7,0.7,1.2,0.8c0.1,0.2,0.4,0.2,0.5,0.3c0,0.2-0.1,0.4-0.3,0.4c-0.6,0-0.9-0.4-1.3-0.8
		C41.8,28,41.6,27.5,41.4,27z M43.7,26.1c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.2-0.6-0.3-1c0-0.3-0.1-0.6,0.1-0.8c0.1,0,0.1,0.1,0.1,0.1
		C43.5,24.9,43.8,25.5,43.7,26.1z M43.4,22.2c0,0.1-0.1,0-0.2,0c-0.1-0.2-0.4-0.3-0.5-0.6c0-0.1-0.2-0.1-0.2,0c0,0-0.1,0.1,0,0.1
		c0.3,0.4,0.4,0.8,0.7,1.2c0,0.2-0.2,0.4-0.3,0.6c0,0-0.1,0-0.1,0c-0.2-0.1-0.2-0.3-0.3-0.5c0-0.1-0.1-0.3-0.1-0.4
		c0.1-0.4-0.3-0.6-0.4-0.9c-0.2-0.2-0.5-0.2-0.6-0.4c0-0.1,0-0.2,0.1-0.2c0.3-0.3,0.6,0.1,0.9-0.1c0.1,0.1,0.3,0,0.4,0
		c0.2,0.1,0.4,0.3,0.6,0.3c0.2,0.1,0.2,0.3,0.2,0.5C43.4,22,43.3,22.1,43.4,22.2z M42.8,18.6c0,0.1-0.1,0.3,0,0.4
		c0.1,0.1,0.3,0.1,0.3,0.3c0,0.1,0,0.2-0.1,0.2c-0.3,0-0.6,0.1-0.8,0.4c0,0.1,0,0.1,0.1,0.1c0.2,0,0.2-0.3,0.5-0.3
		c0.2,0,0.3-0.1,0.5,0c0,0.2,0.2,0.3,0.2,0.4c-0.1,0.3,0.1,0.7-0.1,1c-0.4,0-0.7-0.4-1.1-0.1c-0.2,0-0.5-0.2-0.7-0.1
		c-0.2-0.1-0.3-0.4-0.2-0.6c0.1-0.2,0.3-0.1,0.4-0.3c0.1,0,0.3,0.1,0.3,0c0-0.2-0.3-0.1-0.4-0.1c-0.1,0-0.2,0.1-0.4,0.1
		c-0.1-0.1-0.1-0.2-0.1-0.4c0.2-0.4,0.7-0.5,1-0.6c0,0,0.1-0.1,0-0.1c-0.4-0.3-0.9,0.1-1.2,0.3c-0.1,0-0.1,0-0.1-0.1
		c0.1-0.3,0.2-0.6,0.5-0.7c0,0,0.1-0.1,0.1-0.1c-0.1-0.1-0.2,0-0.3,0c-0.1,0.1-0.3,0.3-0.5,0.4c0-0.2,0.3-0.4,0.4-0.7
		c0,0,0-0.1-0.1-0.1c-0.3,0.2-0.5,0.5-0.9,0.7c-0.1,0-0.2,0-0.3,0c-0.1,0,0-0.1,0-0.2c0.2-0.3,0.5-0.6,0.6-0.9
		c0.2-0.6,0.9-0.5,1.3-0.9c0.2,0.1,0.4-0.1,0.5,0.1c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0.2-0.6,0.3-0.7,0.6c0.2,0.1,0.2-0.1,0.4-0.2
		c0.1-0.1,0.2-0.2,0.3-0.1c0.2,0.2,0.3-0.3,0.4,0.1c-0.1,0.2-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.3-0.3,0.5c0,0.1,0.1,0,0.2,0
		c0.2-0.2,0.4-0.4,0.7-0.4C42.7,17.9,42.9,18.2,42.8,18.6z M39.3,15.6c0.2-0.3,0.6-0.2,0.8-0.4c0.7-0.2,1.4,0.1,2-0.4l0.7-0.5
		c0.3,0,0.4-0.3,0.6-0.4c0.1,0,0.2,0,0.2,0.1c-0.1,0.3-0.2,0.6-0.3,1c-0.1,0.5-0.6,0.6-1,0.9c-0.2,0.2-0.5,0.2-0.8,0.2
		c-0.6,0-1.1,0.3-1.4,0.8c0.1,0.2-0.1,0.3-0.2,0.3c-0.1,0.1-0.3,0.1-0.4,0.2c0.1,0.1,0.2,0,0.2,0.2c-0.3,0.3-0.8,0-1.1,0.3
		c0,0-0.1,0.1-0.1,0.1c0.1,0.1,0.3,0,0.3,0.1c-0.2,0.2-0.6,0.1-0.8,0.3c0,0.1,0,0.1,0.1,0.1c0.1,0,0.2-0.1,0.3,0
		c0,0.1-0.1,0.1-0.2,0.2c-0.3,0.1-0.6,0-0.8,0.2c0.1,0.1,0.3,0.1,0.4,0.2c-0.3,0.2-0.7,0.2-0.9,0.4c-0.2,0.1,0.1,0.7-0.3,0.5
		c-0.2-0.2-0.3-0.4-0.4-0.7c0.1-0.1,0-0.4,0.1-0.3c0.2,0.1,0.2,0.3,0.3,0.4c0.1,0,0.2-0.1,0.3-0.2c-0.2-0.3,0.3-0.3,0.4-0.5
		c0-0.3,0.3-0.2,0.4-0.3c0.3-0.1,0.5-0.1,0.8-0.2c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0-0.1c0.3-0.4,0.8-0.3,1.2-0.4
		c0-0.1-0.2-0.1-0.1-0.2c0.3-0.2,0.8-0.1,0.9-0.5c0-0.1,0.1-0.2,0-0.2c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.6,0.4
		c0-0.1,0.1-0.2,0.2-0.3c0.3-0.3,0.5-0.7,0.7-1.2c0-0.1-0.1,0-0.2,0c-0.3,0.3-0.7,0.5-1,0.8c-0.1,0-0.2,0.1-0.2,0
		c-0.1-0.2,0.2-0.2,0.2-0.4C39,16.1,39.1,15.8,39.3,15.6z M36.1,17.4C36,17.3,36,17.1,36,16.9c0,0-0.1,0-0.1,0
		c-0.1,0.1-0.2,0.3-0.2,0.4c0,0-0.1,0-0.1,0c-0.1-0.3,0-0.6,0.1-0.8c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.2,0.2,0.3,0.3
		C36.2,16.9,36.3,17.2,36.1,17.4z M37.1,17c-0.1-0.1-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0-0.1,0-0.1-0.1
		c0-0.3,0.1-0.5,0.2-0.8c0.1-0.2,0.4-0.2,0.5-0.4c0.1-0.1,0.4-0.2,0.4,0.1c0,0.2,0.1,0.4,0.2,0.6c0,0.2-0.2,0.4-0.2,0.6
		c0,0-0.1,0.1-0.2,0.1C37.3,17.4,37.1,17.2,37.1,17z M37.3,14.7c0-0.3,0.3-0.2,0.5-0.3c0.3,0,0.7,0,0.9,0.3c0.1,0.1,0.1,0.2,0.1,0.4
		c-0.2,0.1-0.1,0.4-0.3,0.5c-0.3-0.1-0.2-0.4-0.4-0.6c-0.3-0.2-0.5,0.1-0.8,0.2C37.2,15,37.3,14.9,37.3,14.7z M37.7,15.3
		c0.1,0,0.1,0,0.2,0.1c0,0.4,0.5,0.7,0.1,1c-0.3,0-0.2-0.3-0.3-0.5c-0.2-0.2-0.4,0.2-0.6,0.1c-0.1,0-0.2-0.1-0.1-0.2
		C36.9,15.5,37.4,15.4,37.7,15.3z M35.5,14.7c0.2-0.4,0.6-0.4,1-0.4c0.2,0.2,0.2,0.4,0.4,0.5c0,0.1,0,0.3-0.1,0.4
		c-0.1,0-0.2-0.2-0.3-0.2c-0.2-0.1-0.4-0.2-0.6-0.1c-0.1,0-0.2,0.2-0.3,0.1C35.5,14.9,35.5,14.8,35.5,14.7z M36,15.2
		c0.2-0.1,0.3,0.2,0.4,0.4c0,0.1,0.3,0.2,0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2-0.1-0.3-0.4-0.5-0.3c-0.1,0.1-0.2,0.2-0.3,0.2
		c-0.1-0.1-0.1-0.3-0.1-0.4C35.6,15.5,35.8,15.3,36,15.2z M35.4,19.1c0.2-0.1,0.4,0.1,0.5-0.1l0,0c0,0.2,0.2,0.5,0.1,0.7
		c0,0.1,0.1,0.2,0.1,0.4c-0.1,0-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.1,0.5-0.3,0.2c-0.1-0.2,0.1-0.4-0.1-0.5
		c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0-0.2-0.1-0.2-0.2c0.3-0.1-0.1-0.5,0.3-0.6C35.3,19,35.4,19.1,35.4,19.1z M35.2,17.1
		c-0.1,0-0.1-0.1-0.1-0.2c0-0.1-0.1-0.2-0.2-0.1c-0.1,0.1-0.1,0.3-0.1,0.4l0,0c-0.2-0.2-0.3-0.4-0.2-0.7c0-0.2,0.2-0.3,0.3-0.4
		c0.1,0,0.2,0.1,0.3,0.2C35.3,16.6,35.3,16.9,35.2,17.1z M34.5,15.9c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.1
		c-0.1-0.1,0-0.2,0-0.3c0.1-0.2,0.2-0.5,0.4-0.6c0.2,0,0.4,0.3,0.6,0.3c0.1,0.1,0.1,0.2,0.1,0.3C35,15.9,34.6,15.6,34.5,15.9z
		 M34.5,14.3c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.3,0.3,0.5c0,0.1,0,0.2-0.1,0.2c-0.4,0.1-0.4-0.4-0.8-0.3c-0.1,0.1-0.3,0.3-0.4,0.5
		c-0.1,0-0.1,0.1-0.2,0.1c-0.1-0.1-0.2-0.2-0.2-0.3C33.8,14.6,34.1,14.3,34.5,14.3z M33.7,15.8c0,0.1,0.1,0.3,0,0.4
		c-0.2,0-0.5,0-0.6,0.3c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1-0.1-0.2-0.2-0.3-0.4c0.1-0.2,0.2-0.5,0.3-0.7c0,0,0.1-0.1,0.1-0.1
		C33.3,15.8,33.5,15.9,33.7,15.8z M32.4,14.6c0.2,0,0.4,0,0.6,0.2c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0,0.1,0.1,0.1,0.2
		c-0.1,0.1-0.2,0-0.3,0c-0.2,0-0.5-0.3-0.7,0.1c-0.1,0.1-0.1,0.3-0.2,0.3c-0.1-0.1-0.2-0.3-0.2-0.5C32.1,15,32.2,14.8,32.4,14.6z
		 M27.3,14.7c0-0.3,0-0.5,0-0.8c0,0,0.1-0.1,0.1-0.1c0.3-0.1,0.2,0.3,0.4,0.4c0.1,0.3,0.4,0.6,0.7,0.6c0.6,0,1.2-0.1,1.8,0.1
		c0.4,0.1,0.8,0.4,1.1,0.8c0.1,0,0.1-0.1,0.2-0.1c0.1,0.1,0.4,0.2,0.3,0.4c0.3,0,0.4,0.4,0.7,0.6c-0.2,0.2,0.5,0.5-0.1,0.5
		c-0.4-0.2-0.8,0.1-1.2,0.1c-0.1,0-0.2,0-0.3-0.1l0-0.1c0.2-0.2,0.5-0.3,0.8-0.3c0.1,0,0-0.2,0.2-0.1c0.1,0.1,0.1,0.2,0.2,0.2
		c0,0,0-0.1,0-0.1c-0.2,0-0.2-0.3-0.4-0.3c-0.2,0-0.3,0-0.4-0.2c-0.1-0.3-0.4-0.4-0.6-0.5c-0.1-0.1-0.2-0.1-0.3-0.1
		c-0.1,0.1-0.1,0.1-0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.8v0c-0.3,0-0.5-0.4-0.7-0.3c0,0-0.1,0.1,0,0.2c0.1,0.2,0.4,0.4,0.2,0.6
		c-0.2,0-0.3-0.2-0.4-0.3c0-0.3-0.3-0.3-0.5-0.4c-0.2-0.1-0.4,0-0.7,0c-0.2-0.1-0.4,0.1-0.6-0.1C27.8,16,27.3,15.4,27.3,14.7z
		 M27.1,25.9c0.1-0.4,0-0.8,0.3-1.1c0.2,0,0.1,0.3,0.1,0.4c0.1,0.3,0.1,0.7,0.2,1c-0.1,0.2-0.3,0.3-0.5,0.3
		C27.1,26.3,27.1,26.1,27.1,25.9z M28.8,25.3c0.1-0.1,0.2-0.1,0.2,0c0,0.3-0.1,0.6-0.2,0.9c-0.3,0.4-0.4,0.9-0.7,1.3
		c-0.2,0.1-0.4,0.4-0.7,0.4c-0.1-0.2-0.3-0.4-0.3-0.7C27.7,26.6,28.4,26,28.8,25.3z M27.6,29.1c-0.1,0.1-0.2,0.2-0.4,0.1
		c0-0.2-0.1-0.6,0.1-0.7c0.1-0.1,0.3-0.3,0.4-0.1C27.8,28.6,27.9,28.9,27.6,29.1z M28,28.9c0.3-0.4,0.7-0.7,0.9-1.2
		c0,0,0.1-0.1,0.1-0.1c-0.1,0.8-0.4,1.6-0.9,2.2c-0.2,0.3-0.6,0.4-0.9,0.5c-0.1,0-0.2-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.3
		C27.3,29.6,27.7,29.3,28,28.9z M25.9,28.5c0-0.3,0.1-0.5,0.4-0.7c0.1-0.1,0.3-0.2,0.5-0.2l0.2,0.3c0,0.4-0.4,0.5-0.7,0.8
		c-0.1,0-0.1,0.1-0.2,0.1C25.9,28.8,25.9,28.6,25.9,28.5z M26.2,32.4c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.3-0.2-0.5
		c0.1-0.2,0.4-0.3,0.5-0.5c0.3-0.2,0.5-0.5,0.8-0.8c0.1,0,0.3-0.1,0.4,0C27.1,31.2,26.7,31.9,26.2,32.4z M27.9,41.8
		c-0.4-1.3-0.8-2.6-1-4c-0.2-1.2-0.3-2.4-0.5-3.6c0.1-0.5,0-1.1,0-1.6c0.1-0.2,0.3-0.3,0.5-0.4c0,0,0.1,0,0.1,0.1
		c0,0.9,0.2,1.7,0.2,2.6c0.1,0.7,0.2,1.4,0.2,2c0,0.7,0.1,1.4,0.1,2.1c0.1,0.8,0.2,1.5,0.3,2.2C27.9,41.4,27.9,41.6,27.9,41.8
		L27.9,41.8z M28.2,40.8c-0.1-0.6-0.1-1.3-0.2-1.9c-0.2-1.2-0.2-2.5-0.3-3.7c-0.1-0.3-0.1-0.7-0.1-1.1c-0.1-0.8-0.1-1.6-0.2-2.3
		c-0.1-0.5,0.1-0.9,0.2-1.2c0.1-0.1,0.3-0.3,0.4-0.2c0.1,0.8,0,1.6,0.1,2.4c0.1,0.3,0.1,0.7,0.1,1c0.1,1.3,0.1,2.5,0.3,3.7
		c0.2,1.7,0.4,3.4,0.6,5.2c0.1,0.6,0.2,1.2,0.3,1.9c0,0-0.1,0.1-0.1,0C28.7,43.4,28.4,42.2,28.2,40.8z M42.3,41.2
		c-0.2,0.9-0.3,1.8-0.6,2.7c0,0.3-0.1,0.6-0.2,0.8c0,0-0.1,0-0.1,0c0-0.7,0.3-1.2,0.3-1.8c0.1-0.5,0.1-1,0.2-1.5
		c0.1-0.7,0.1-1.3,0.1-2c0.1-0.2,0.1-0.5,0.1-0.7c-0.1-0.5,0.1-1,0.1-1.5c0.1-0.5,0.2-1.1,0.2-1.6c0.1-1,0.2-1.9,0.2-2.9
		c0.1-0.3,0-0.7,0.1-0.9c0-0.3,0-0.5,0-0.8c0.1-0.2-0.2-0.7,0.2-0.8c0.1,0.1,0.3,0.2,0.4,0.3c0,0.3-0.1,0.6-0.1,0.9
		c0,0.5-0.1,1-0.1,1.6c0,0.8,0,1.5-0.1,2.2c-0.1,0.9-0.2,1.8-0.3,2.6C42.8,38.9,42.5,40,42.3,41.2z M44.5,31.7
		c-0.1,0.6,0,1.1-0.1,1.7c0,0.5-0.2,1-0.2,1.5c0,0.8-0.3,1.5-0.3,2.3c-0.1,0.8-0.3,1.5-0.5,2.3c-0.2,0.9-0.5,1.8-0.8,2.6
		c0,0-0.1,0-0.1,0c0.1-0.8,0.3-1.7,0.4-2.5c0.2-1,0.4-2.1,0.6-3.1c0.1-0.8,0.1-1.6,0.2-2.4c0.1-1,0.2-2,0.3-2.9
		c0.1-0.1,0.3,0.1,0.4,0.1C44.5,31.4,44.5,31.6,44.5,31.7z M45.1,31.2c-0.5-0.4-1-0.7-1.3-1.2c-0.1-0.1-0.2-0.3-0.2-0.5
		c0.1-0.1,0.1-0.2,0.2-0.1c0.2,0,0.3,0.3,0.6,0.3c0.1,0.1,0.1,0.2,0.1,0.3c0.2,0.2,0.5,0.5,0.7,0.7c0.1,0.1,0.2,0.2,0.1,0.4
		C45.3,31.1,45.2,31.3,45.1,31.2z"/>
            <path d="M28,14.9c0.2,0.2,0.5,0.2,0.8,0.2c0.3,0.1,0.6,0,0.8,0.2c0.1,0.1,0.3,0.2,0.3,0.5l-0.1,0c-0.6-0.3-1.3,0-1.8-0.5
		c-0.2,0-0.3-0.2-0.3-0.4c0-0.1,0-0.1,0-0.2C27.8,14.8,27.9,14.9,28,14.9z"/>
            <path d="M43.2,14.8c0,0.1-0.1,0.3-0.3,0.4c-0.5,0.3-1.2,0.5-1.9,0.4c0,0,0-0.1,0-0.1c0.4-0.1,0.9-0.2,1.3-0.4
		C42.6,15,42.9,14.9,43.2,14.8L43.2,14.8z"/>
            <path d="M31.1,19.7c-0.1,0-0.8,1.1-0.1,1.7c0.9,0.8,2-0.6,1.8-0.9c-0.1-0.1-0.4,0.8-1.2,0.6C30.6,20.9,31.1,19.8,31.1,19.7z"/>
            <path d="M38.3,21.2c0,0.3,1.1,0.8,1.7,0.1c0.7-1.1-0.3-1.7-0.6-1.4c-0.1,0.1,0.6,0.4,0.3,1.1C39.2,21.7,38.3,21,38.3,21.2z"/>
        </g>
    </svg>
);

module.exports = uibLogo;