const React = require('react');
const EventMini = require('../../event-mini/Component/index');
const RandomGrid = require('../../../../webapp/components/RandomGrid');

class EventList extends React.Component {
    render() {
        return (
            <RandomGrid>
                {this.props.events.map(event => <EventMini key={event._id} {...event}/>)}
            </RandomGrid>
        );
    }
}

module.exports = EventList;