const React = require('react');
const Logo = require('../../../../webapp/components/Logo');
const Menu = require('../../../../webapp/components/Menu');
const WorkMini = require('../../../../webapp/containers/WorkMini');
const Filter = require('./Filter');

const categories = [
    { id: 'art', no: 'Kunst', en: 'Fine Art' },
    { id: 'music', no: 'Musikk', en: 'Music' },
    { id: 'design', no: 'Design', en: 'Design' },
    { id: 'kuProject', no: 'Kunstnerisk utviklingsarbeid', en: 'Artistic Research' }
];

function getCategories(lang) {
    return categories.map(cat => ({ id: cat.id, name: cat[lang] || cat.no }));
}

class ArbeiderHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loaded: false};
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.setState({loaded: true})
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({innerWidth: window.innerWidth});
    }

    render() {
        const { menu, work } = this.props;
        const language = menu.language;
        const {innerWidth} = this.state;
        let logoWidth;
        if (innerWidth > 1000) {
            logoWidth = innerWidth / 8;
        } else {
            logoWidth = innerWidth;
        }

        return (
            <header className="arbeider-header">
                <Menu {...menu}>
                    <div className="logo">
                        {this.state.loaded && <Logo width={logoWidth} mainPage={menu.mainPage}/>}
                    </div>
                    <div className="filters">
                        <Filter name="category" allText={language === 'en' ? 'All' : 'Alle'} label={language === 'en' ? 'Category' : 'Kategori'} filters={getCategories(language)}/>
                    </div>
                </Menu>
                <div className="titleContainer">
                    <h1>{language === 'en' ? 'Works & Productions': 'Arbeider og produksjoner'}</h1>
                    {work && <WorkMini {...work}/>}
                </div>
            </header>
        );
    }
}

module.exports = ArbeiderHeader;
