const React = require('react');
// const Image = require('../../Image');
const Image = require('../../../../webapp/components/Image');

class MiniInterview extends React.Component {
    render() {
        return (
            <div className="mini-interview">
                <Image sources={this.props.images}/>
                <div className="interview-content" dangerouslySetInnerHTML={{__html: this.props.content}}/>
            </div>
        );
    }
}

module.exports = MiniInterview;