const isNode = (typeof process !== 'undefined');
const React = require('react');
const NewsMini = require('../../news-mini/Component');
const queryString = isNode ? require('query-string') : () => ({});
const RandomGrid = require('../../../../webapp/components/RandomGrid');
const Logo = require('../../../../webapp/components/Logo');
const Menu = require('../../../../webapp/components/Menu');

function parseQuery() {
    return isNode ? queryString.parse(window.location.search) : {};
}

const texts = {
    all: {
        no: 'Alle',
        en: 'All'
    },
    general: {
        no: 'Nyheter',
        en: 'News'
    },
    pressRelease: {
        no: 'Pressemeldinger',
        en: 'Press releases'
    },
    mediaCoverage: {
        no: 'Medieomtaler',
        en: 'Media coverage'
    }
};


class NewsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loaded: false, pathname: ''};
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.setState({loaded: true, pathname: window.location.pathname})
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({innerWidth: window.innerWidth});
    }
    render() {
        const {innerWidth} = this.state;
        const currentType = parseQuery()['type'];
        const language = this.props.menu.language;
        const getText = id => texts[id][language] || texts[id].no;
        return (
            <div className="news-list">
                <div className="logo">
                    {this.state.loaded && <Logo width={innerWidth < 1000 ? innerWidth : innerWidth / 8} mainPage={this.props.menu.mainPage}/>}
                </div>
                <Menu {...this.props.menu}>

                </Menu>
                <h1 className="header">{this.props.menu.language === 'en' ? 'News' : 'Nyheter'}</h1>
                <div className="content news">
                    <RandomGrid>
                        {this.props.news.map(n => <NewsMini key={n._id} {...n}/>)}
                    </RandomGrid>
                </div>
            </div>
        );
    }
}

module.exports = NewsList;