const React = require('react');
// const Image = require('../../Image');
const Image = require('../../../../webapp/components/Image');

class ImageLink extends React.Component {
    render() {
        const {text, href, images, imageText} = this.props;
        return (
            <a href={href} className="image-link">
                <Image sources={images} className="image" alt={imageText}/>
                <div className="text">
                    <span className="large-link">{text}</span>
                </div>
            </a>
        )
    }
}

module.exports = ImageLink;