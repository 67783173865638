const React = require('react');

class Collaborator extends React.Component {

    constructor(props) {
        super(props);
        this.renderInfo = this.renderSecondaryBio.bind(this);
        this.renderContent = this.renderMainBio.bind(this);
    }

    renderSecondaryBio() {
        return (
            <div className="secondary-bio">
                <div
                    ref="description"
                    className="description"
                    dangerouslySetInnerHTML={{ __html: this.props.secondaryDescription }} />
            </div>
        );
    }

    renderMainBio() {
        if (!this.props.description) {
            return null;
        }

        return (
            <div className="main-bio">
                <h2 className="main-heading">
                    {this.props.name}
                </h2>
                <h2 className="title">{this.props.title}</h2>
                <div
                    ref="description"
                    className="description"
                    dangerouslySetInnerHTML={{ __html: this.props.description }} />
            </div>
        );

    }

    render() {
        return (
            <div ref="container" className={('collaboration') + (this.props.description ? ' withDescription' : '')}>
                <div className="collaboration-inner">
                    <button className="close" onClick={() => { history.back() }}>x</button>
                    {this.renderMainBio()}
                    {this.renderSecondaryBio()}
                </div>
            </div>
        );
    }
}


module.exports = Collaborator;