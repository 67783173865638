const isNode = (typeof process !== 'undefined');
const React = require('react');
const ReactDOM = isNode ? require('react-dom') : {};

const Image = require('../../../../webapp/components/Image');
const handleAnchor = require('../../handleAnchor');

const kunstLogo = require('./Logo/Art');
const musikkLogo = require('./Logo/Music');
const designLogo = require('./Logo/Design');

const logos = {
    KUNST: kunstLogo,
    MUSIKK: musikkLogo,
    DESIGN: designLogo,
    HIDE: null,
};

// Object.entries
function objectEntries(obj) {
    var ownProps = Object.keys(obj),
        i = ownProps.length,
        resArray = new Array(i); // preallocate the Array
    while (i--)
        resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
};

function scrollTop() {
    if (window.pageYOffset) return window.pageYOffset;
    return document.documentElement.clientHeight
        ? document.documentElement.scrollTop
        : document.body.scrollTop;
}
function getSupportedLanguage(lang) {
    return lang === 'no' || lang === 'en';
}

class SpecialEventHeader extends React.Component {
    constructor(props) {
        super(props);

        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.isMobile = this.isMobile.bind(this);
        this.createAnchors = this.createAnchors.bind(this);
        this.calcTop = this.calcTop.bind(this);
        this.calcTextOnImageStyle = this.getTextOnImageStyle.bind(this);
        this.renderSideInfo = this.renderSideInfo.bind(this);
        this.renderLanguageLinks = this.renderLanguageLinks.bind(this);
        this.renderImageOrVideo = this.renderImageOrVideo.bind(this);

        this.state = {
            anchors: [],
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('DOMContentLoaded', this.createAnchors);
        this.handleScroll();
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('DOMContentLoaded', this.createAnchors);
    }

    handleResize() {
        if (!this.refs.image) return;
        const imageHeight = ReactDOM.findDOMNode(this.refs.image).getBoundingClientRect().height;
        this.setState({
            innerWidth: window.innerWidth,
            imageHeight,
        });
    }

    handleScroll() {
        this.setState({
            scrollTop: scrollTop(),
        });
    }

    createAnchors() {
        const elements = [...document.querySelectorAll('h2, h3')];
        elements.forEach((elem, i) => elem.id = `anchor-${i}`);
        const anchors = elements.map(({ tagName, innerHTML }, i) => ({ text: innerHTML, href: `#anchor-${i}`, indent: tagName === 'H2' ? 0 : 20 }));
        this.setState({ anchors });
        handleAnchor();
    }

    isMobile() {
        return this.state.innerWidth < 1000;
    }

    calcTop() {
        if (!this.state.imageHeight || !this.state.scrollTop) {
            return 0;
        }

        const diff = this.state.imageHeight - this.state.scrollTop;

        if (diff < 0) {
            return 0;
        }

        return diff + 77;
    }

    getTextOnImageStyle() {
        const color = this.props.bannerContent.color;
        switch (this.props.bannerContent.placement) {
            case '0':
                return {
                    top: 0,
                    left: 0,
                    color,
                };
            case '1':
                return {
                    top: 0,
                    right: 0,
                    color,
                };
            case '2':
                return {
                    bottom: 0,
                    left: 0,
                    color,
                };
            case '3':
                return {
                    bottom: 0,
                    right: 0,
                    color,
                };
            default:
                return {
                    color,
                };
        }
    }

    renderSideInfo(top) {
        return (
            <aside className="side-info" style={{ top }}>
                {logos[this.props.logoType]}
                {this.props.extraLogo && <img alt="" className="extra-logo" src={this.props.extraLogo} />}
                {this.props.anchors && (
                    <ul className="anchors">
                        {this.state.anchors.map(({ text, href, indent }) => <li className="list-item" key={href} style={{ paddingLeft: indent }}><a href={href} dangerouslySetInnerHTML={{ __html: text }} /></li>)}
                    </ul>
                )}
            </aside>
        );
    }

    renderLanguageLinks(top) {
        if (!this.props.couplingLink) {
            return null;
        }

        if (!getSupportedLanguage(this.props.language)) {
            return null;
        }

        return (
            <div className="language-links" style={{ top }}>
                {['no', 'en'].map(lang => {
                    if (lang === this.props.language) {
                        return <span key={lang}>{lang}</span>
                    }
                    return <a href={this.props.couplingLink} key={lang} className="small-link">{lang}</a>;
                })}
            </div>
        );
    }

    renderImageOrVideo() {
        switch (this.props.bannerType) {
            case 'image':
                const portraitImageSet = objectEntries(
                    this.props.bannerContent.portraitImage
                ).length !== 0 && this.props.bannerContent.portraitImage.constructor === Object;

                return (
                    <Image
                        className="image"
                        // Only render portrait if screen size + portrait image
                        sources={(this.isMobile() && portraitImageSet) ? this.props.bannerContent.portraitImage : this.props.bannerContent.image}
                        text={this.props.bannerContent.text}
                        textStyle={this.getTextOnImageStyle()}
                        onLoad={() => this.setState({ imageHeight: ReactDOM.findDOMNode(this.refs.image).getBoundingClientRect().height })}
                    />
                );
            case 'vimeo':
                const loop = this.props.bannerContent.loop || 0;
                const autoplay = this.props.bannerContent.autoplay || 0;
                const autopause = this.props.bannerContent.autopause || 0;
                return (
                    <div className="video-wrapper" style={{ paddingBottom: `${100 * this.props.bannerContent.height / this.props.bannerContent.width}%` }}>
                        <iframe src={`https://player.vimeo.com/video/${this.props.bannerContent.id}?title=0&byline=0&portrait=0&badge=0&autoplay=${autoplay}&autopause=${autopause}&loop=${loop}`} frameBorder="0" />
                    </div>
                );
            default:
                return null;
        }
    }

    render() {
        const top = this.calcTop();

        return (
            <div className="special-event-header">
                {this.renderSideInfo(top)}
                {this.renderLanguageLinks(top)}
                <div ref="image" className="special-event-header__image">
                    {this.renderImageOrVideo()}
                </div>
                {/*this.props.ingress && */(
                    <p className="ingress">
                        {this.props.ingress}
                    </p>
                )}
            </div>
        );
    }
}

module.exports = SpecialEventHeader;
