const React = require('react');
const WorkMini = require('../../../../webapp/containers/WorkMini');
const RandomGrid = require('../../../../webapp/components/RandomGrid');

class PersonWorkList extends React.Component {
    render() {
        return this.props.works.length > 0 && (
            <div className="person-work-list">
                <h3 className="content title">{this.props.language === 'en' ? 'Works' : 'Arbeider'}</h3>
                <RandomGrid>
                    {this.props.works.map(work => <WorkMini key={work._id} {...work}/>)}
                </RandomGrid>
            </div>
        );
    }
}

module.exports = PersonWorkList;