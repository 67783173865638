const isNode = (typeof process !== 'undefined');
const React = require('react');
const queryString = isNode ? require('query-string') : () => ({});

function parseQuery() {
    return isNode ? queryString.parse(window.location.search) : {};
}

function allHref(name) {
    if (!isNode) {
        return '';
    }
    const query = parseQuery();
    delete query[name];
    return window.location.pathname + '?' + queryString.stringify(query);
}

function makeHref(name, filter) {
    if (!isNode) {
        return '';
    }
    const query = parseQuery();
    query[name] = filter;
    return window.location.pathname + '?' + queryString.stringify(query);
}

class Filter extends React.Component {
    render() {
        const current = parseQuery()[this.props.name];
        return (
            <div>
                {this.props.label && <p className="small filterHeader">{this.props.label}:</p>}
                <ul className="list">
                    <li>{current ? <a className="small-link" href={allHref(this.props.name)}>{this.props.allText}</a> : <span>{this.props.allText}</span>}</li>
                    {this.props.filters.map((filter, index) => {
                        return (
                            <li key={index}>
                                {current == filter.id ? <span>{filter.name}</span> : <a className="small-link" href={makeHref(this.props.name, filter.id)}>{filter.name}</a>}
                            </li>
                        )
                    })}
                </ul>
            </div>
        );
    }
}

Filter.defaultProps = {
    allText: 'Alle'
};

module.exports = Filter;