const isNode = (typeof process !== 'undefined');
const React = require('react');
const getImage = require('../../getImage');
const moment = isNode ? require('moment') : () => ({format: () => ''});

class NewsMini extends React.Component {
    render() {
        const {images, focalPoint, header, ingress, createdTime, href, hideIngress} = this.props;
        
        return (
            <a href={href} className="news-mini">
                <div className="image" style={{backgroundImage: `url(${getImage(images)})`, backgroundPosition: `${100 * focalPoint.x}% ${100 * focalPoint.y}%`}}></div>
                <div className="textBox">
                    <p className="created">{moment(createdTime).format('DD/MM - YYYY')}</p>
                    <span className="large-link" style={{fontWeight: 300}}>{header}</span>
                    {!hideIngress && <p className="text">{ingress}</p>}
                </div>
            </a>
        )
    }
}

module.exports = NewsMini;