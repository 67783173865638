const React = require('react');

function getNavText(lang) {
    const txt = (lang == 'no') ? 'Tilbake til hovedmeny' : 'Back to main menu';
    return '← ' + txt;
}

const closeText = {
    en: 'Close',
    no: 'Lukk',
}

const AnchorMenu = ({ nodes, onClickMainMenuNav, title, language, onClickAnchor }) => {
    return (
        <div className="anchor-menu" style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="menu-buttons" style={{ marginRight: '15px', marginBottom: '15px' }}>
                <button onClick={onClickMainMenuNav} className="toggleLink" style={{ color: 'white' }}>{closeText[language]}</button>
            </div>
            <div className="title">
                <h1>{title}</h1>
            </div>
            <div className="nodes" onClick={(e) => {
                // If <a> element is clicked, close the menu
                if (e.target.nodeName === 'A') {
                    onClickAnchor();
                }
            }}>
                {nodes}
            </div>
        </div>
    );
}

module.exports = AnchorMenu;
