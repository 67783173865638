const isNode = (typeof process !== 'undefined');
const React = require('react');
const ReactDOM = isNode ? require('react-dom') : {};
const moment = isNode ? require('moment') : () => ({format: () => '', isSame: () => false});
const getImage = require('../../getImage');

const Logo = require('../../../../webapp/components/Logo');
const Menu = require('../../../../webapp/components/Menu');

function scrollTop(){
    if (window.pageYOffset) return window.pageYOffset;
    return document.documentElement.clientHeight
        ? document.documentElement.scrollTop
        : document.body.scrollTop;
}

function getLogoWidth({innerWidth, scrollTop, imageHeight}) {
    if (innerWidth <= 1000) {
        return innerWidth;
    }
    if (scrollTop > imageHeight - 60) {
        return innerWidth / 8;
    }
    return innerWidth / 4;
}

const locale = {
    no: 'nb',
    en: 'en'
};

class EventSite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loaded: false, imageHeight: 500};
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        moment.locale && moment.locale(locale[props.menu.language]);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);

        this.handleResize();

        const imageHeight = ReactDOM.findDOMNode(this.refs.imageBox).getBoundingClientRect().height;

        this.setState({
            loaded: true,
            imageHeight,
        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    }

    handleScroll() {
        this.setState({scrollTop: scrollTop()});
    }

    handleResize() {
        this.setState({
            innerWidth: window.innerWidth,
        });
    }

    render() {
        const {images, focalPoint, header, intro, sideInfo, category, startsAt, endsAt} = this.props;
        const from = moment(this.props.from);
        const to = moment(this.props.to);

        const eventTypes = category.join(', ');

        return (
            <div className="event-site">
                <Menu {...this.props.menu}>
                    <div className="logo">
                        {this.state.loaded && <Logo width={getLogoWidth(this.state)} mainPage={this.props.menu.mainPage}/>}
                    </div>

                    <div className="sideInfo" dangerouslySetInnerHTML={{__html: sideInfo}} aria-hidden />

                </Menu>
                <div className="event">
                    <div className="topBox">
                        <div className="dateBox">
                            {
                               eventTypes.length ? <p className="eventType">{eventTypes}</p> : null
                            }
                            <div className="dateBoxText">
                              <div className="fromDate">
                                <p className="day">{from.format('D')}</p>
                                <p className="month">{from.format('MMM')}</p>
                              </div>
                              {!to.isSame(from) && (
                                  <div className="toDateWrapper">
                                      <div className="arrow" aria-label="til">↓</div>
                                      <div className="toDate">
                                          <p className="day">{to.format('D')}</p>
                                          <p className="month">{to.format('MMM')}</p>
                                      </div>
                                  </div>
                              )}
                            </div>
                        </div>
                        <div ref="imageBox" className="imgBox" style={{backgroundImage: `url(${getImage(images)})`, backgroundPosition: `${100 * focalPoint.x}% ${100 * focalPoint.y}%`}}/>
                    </div>
                    <div className="textSection">
                        <h1>{header}</h1>
                        <div className="mobileSideInfo" dangerouslySetInnerHTML={{__html: sideInfo}} />
                        <p className="ingress">{intro}</p>
                    </div>
                </div>
            </div>
        );
    }
}

module.exports = EventSite;
