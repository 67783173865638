module.exports = function (map) {
    let acc = 0;

    const values = Object.keys(map);
    const randomValue = Math.random();
    
    for (let i = 0; i < values.length; i++) {
        const value = values[i];
        acc += map[value];
        if (acc > randomValue) {
            return value;
        }
    }
};