const React = require('react');

const Logo = require('../../../../webapp/components/Logo');
const Menu = require('../../../../webapp/components/Menu');
const Filter = require('./Filter');
const Dropdown = require('./Dropdown');

const allText = {
    no: 'Alle',
    en: 'All'
};

const upcomingText = {
    no: 'Kommende',
    en: 'Upcoming'
};

const previousText = {
    no: 'Tidligere',
    en: 'Previous'
};

const categoryText = {
    no: 'Kategori',
    en: 'Category'
};

const locationText = {
    no: 'Sted',
    en: 'Location'
};

function getLanguageText(lang) {
    return text => text[lang] || text.no;
}

class CalendarListHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            innerWidth: 0,
            loaded: false, 
            query: {            
            },
        };

        this.handleResize = this.handleResize.bind(this);
        this.getLogoWidth = this.getLogoWidth.bind(this);
        this.renderDropdown = this.renderDropdown.bind(this);
        this.renderFilters = this.renderFilters.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);

        this.handleResize();

        this.setState({
            loaded: true,
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({
            innerWidth: window.innerWidth,
        });
    }

    getLogoWidth() {
        if (this.state.innerWidth < 1000) {
            return this.state.innerWidth;
        }

        return innerWidth / 8;
    }

    renderDropdown() {
        const getText = getLanguageText(this.props.menu.language);
        return (
            <Dropdown
                categories={this.props.categories}
                placeholder={getText(categoryText)}
                name='category'
                upcomingText={getText(upcomingText)}
                previousText={getText(previousText)}
            />
        );
    }

    renderFilters() {
        const getText = getLanguageText(this.props.menu.language);
        return (
            <div className="filters">
                <Filter name="time" allText={getText(upcomingText)} filters={[{id: 'past', name: getText(previousText)}]}/>
                <Filter name="category" allText={getText(allText)} label={getText(categoryText)} filters={this.props.categories}/>
                <Filter name="location" allText={getText(allText)} label={getText(locationText)} filters={this.props.locations}/>
            </div>
        )
    }

    render() {
        return (
            <div className="calendar-list-header">
                <div>
                    <div className="logo">
                        {this.state.loaded && <Logo width={this.getLogoWidth()} mainPage={this.props.menu.mainPage}/>}
                    </div>
                    <Menu {...this.props.menu}>                        
                        {this.state.innerWidth >= 1000 && this.renderFilters()}
                    </Menu>
                    <h1 className="header">{this.props.menu.language === 'en' ? 'Calendar' : 'Kalender'}</h1>
                    {this.state.innerWidth < 1000 && this.renderDropdown()}
                </div>
            </div>
        );
    }
}

module.exports = CalendarListHeader;