const React = require('react');
const Newsletter = require('../../../../webapp/components/Newsletter');

const uibLogo = (
    <svg x="0px" y="0px" viewBox="0 0 70.9 70.9" aria-label="Universitetet i Bergen">
        <title>Universitetet i Bergen</title>
        <g>
            <g>
                <path d="M6.4,27.9c-0.6-0.1-1.8-0.3-2.2-0.2C4,27.8,3.9,28,3.9,28.2l-0.1,0.3c-0.1,0.1-0.2,0-0.2-0.1c0.1-0.4,0.2-0.8,0.2-1.2
			c0.1-0.4,0.1-0.7,0.2-1.1c0.1-0.1,0.2,0,0.3,0l0,0.3c0,0.3,0,0.5,0.2,0.6c0.3,0.2,1.5,0.4,2.1,0.5l1.2,0.2
			c0.9,0.2,1.8,0.5,2.3,1.2c0.4,0.5,0.5,1.3,0.4,2.1c-0.1,0.7-0.4,1.4-0.9,1.8c-0.5,0.5-1.3,0.6-2.6,0.4l-2.4-0.4
			c-1.1-0.2-1.3-0.2-1.4,0.4L3,33.4c-0.1,0.1-0.2,0-0.2-0.1C2.8,32.8,2.9,32.5,3,32c0.1-0.4,0.1-0.8,0.2-1.3c0-0.1,0.2,0,0.3,0
			l0,0.3c-0.1,0.6,0.1,0.7,1.2,0.8l2.2,0.4c1.7,0.3,2.8,0,3.1-1.6c0.3-1.5-0.8-2.3-2.4-2.5L6.4,27.9z"/>
                <path d="M12.9,19.6c0.3,0.2,1.4,0.8,1.6,0.9c0,0,0.1,0.1,0,0.2c-0.2,0-0.7,0.1-2,0.4l-3.6,0.8c-0.4,0.1-1.5,0.3-1.8,0.4l0,0
			c0.1,0.1,0.3,0.2,0.6,0.4l2,1.1c0.4,0.2,1.6,0.9,2,0.9c0.1,0,0.3-0.2,0.4-0.4l0.2-0.3c0.1,0,0.2,0.1,0.2,0.1
			c-0.2,0.4-0.4,0.7-0.6,1.1c-0.2,0.4-0.3,0.6-0.5,1c-0.1,0-0.2,0-0.2-0.1l0.1-0.3c0.1-0.2,0.1-0.4,0-0.5c-0.3-0.3-1.4-0.9-1.8-1.2
			l-2.6-1.5c-0.3-0.1-0.5-0.2-0.8-0.2c-0.2,0.1-0.4,0.2-0.5,0.4L5.3,23c-0.1,0-0.2-0.1-0.2-0.1c0.2-0.4,0.5-0.9,0.6-1
			c0.1-0.1,0.2-0.3,0.2-0.4c0.6,0.1,2.4-0.3,2.9-0.4l1.5-0.3c1.1-0.2,1.8-0.4,2.5-0.6l0,0c0-0.1-0.2-0.1-0.4-0.3l-2-1.1
			c-0.4-0.2-1.6-0.9-2-0.9c-0.1,0-0.2,0.1-0.5,0.5l-0.1,0.1c-0.1,0-0.2-0.1-0.2-0.1c0.3-0.4,0.4-0.7,0.6-1.1c0.2-0.4,0.3-0.6,0.5-1
			c0.1,0,0.2,0,0.2,0.1L9,16.5c-0.1,0.3-0.1,0.5-0.1,0.6c0.2,0.3,1.4,1,1.8,1.2L12.9,19.6z"/>
                <path d="M12.8,14.2c-0.8-0.8-0.9-0.9-1.4-0.5l-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0.4-0.4,0.6-0.6,0.9-0.9c0.3-0.3,0.5-0.6,0.9-1
			c0.1,0,0.2,0.1,0.2,0.2l-0.2,0.2c-0.4,0.5-0.3,0.6,0.5,1.4l2.7,2.6c0.8,0.8,0.9,0.9,1.4,0.5l0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
			c-0.4,0.4-0.6,0.6-0.9,0.9c-0.3,0.3-0.5,0.6-0.9,1c-0.1,0-0.2-0.1-0.2-0.2l0.2-0.2c0.4-0.5,0.3-0.6-0.5-1.4L12.8,14.2z"/>
                <path d="M16.6,10.1c-0.5-0.4-0.8-0.5-1.2-0.3l-0.2,0.1c-0.1,0-0.2-0.1-0.1-0.2c0.3-0.1,0.6-0.3,0.9-0.6c0.4-0.2,0.7-0.5,1.1-0.7
			c0.1,0,0.2,0.1,0.2,0.2l-0.1,0.1c-0.3,0.3-0.3,0.4-0.3,0.4c0.1,0.1,0.5,0.5,1.2,1c1.1,0.8,2.1,1.5,3.2,2.3
			c-0.1-0.8-0.3-1.9-0.4-2.5c-0.1-0.8-0.3-2-0.4-2.5c0-0.2-0.1-0.3-0.1-0.4c0-0.1-0.2-0.1-0.5,0.1l-0.2,0.1c-0.1,0-0.2-0.2-0.1-0.2
			c0.3-0.1,0.6-0.3,0.9-0.6C20.7,6.2,21,6,21.3,5.8c0.1,0,0.2,0.1,0.2,0.2l-0.3,0.2c-0.2,0.2-0.3,0.3-0.3,0.6c0,0.6,0.1,1.5,0.3,2.7
			l0.2,1.4c0.2,1,0.4,2.3,0.5,2.9c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c-0.3-0.3-0.8-0.6-1.2-0.9L16.6,10.1z"/>
                <path d="M25.3,6.1C25,5,25,4.9,24.4,5l-0.2,0c-0.1,0-0.1-0.2-0.1-0.3c0.4-0.1,0.8-0.2,1.2-0.3l1.9-0.5c0.6-0.2,1.2-0.4,1.4-0.4
			c0.1,0.2,0.4,0.9,0.5,1.3c0,0.1-0.2,0.1-0.2,0.1c-0.3-0.4-0.5-0.8-1-0.7c-0.2,0-0.6,0.1-1,0.2l-0.7,0.2c-0.3,0.1-0.3,0.1-0.2,0.5
			l0.6,2c0.1,0.3,0.1,0.3,0.4,0.2l0.6-0.2c0.4-0.1,0.7-0.2,0.8-0.3c0.1-0.1,0.2-0.2,0.1-0.4l0-0.4c0-0.1,0.2-0.1,0.3-0.1
			c0.1,0.2,0.1,0.6,0.2,1c0.1,0.4,0.3,0.7,0.3,1c0,0.1-0.2,0.1-0.3,0.1l-0.2-0.4c-0.1-0.2-0.2-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.8,0.2
			L27,7.6c-0.3,0.1-0.3,0.1-0.3,0.4l0.4,1.4c0.2,0.5,0.3,0.9,0.5,1c0.2,0.1,0.4,0.1,1.3-0.1c0.8-0.2,1.1-0.3,1.2-0.5
			c0.2-0.2,0.3-0.6,0.4-1.2c0.1-0.1,0.2-0.1,0.3,0c0,0.4,0,1.2-0.1,1.5c-0.9,0.2-1.8,0.5-2.7,0.7l-0.9,0.3c-0.4,0.1-0.8,0.2-1.4,0.4
			c-0.1,0-0.1-0.2-0.1-0.2l0.3-0.1c0.6-0.2,0.6-0.4,0.3-1.4L25.3,6.1z"/>
                <path d="M33.6,4.4c0-1,0-1.2-0.5-1.2l-0.3,0c-0.1-0.1-0.1-0.2,0-0.3c0.6,0,1.3-0.1,2.3-0.1c0.7,0,1.3,0.1,1.7,0.3
			c0.5,0.3,0.9,0.7,0.9,1.5c0,1-0.8,1.5-1.3,1.7c-0.1,0.1,0,0.2,0.1,0.3c0.8,1.4,1.4,2.2,2.1,2.8c0.2,0.2,0.4,0.3,0.7,0.3
			c0.1,0,0.1,0.1,0,0.1c-0.1,0-0.3,0.1-0.5,0c-0.9,0-1.4-0.3-2.2-1.3c-0.3-0.4-0.7-1.1-1-1.6c-0.2-0.2-0.3-0.3-0.7-0.3
			c-0.5,0-0.5,0-0.5,0.2l0,1.3c0,1.1,0,1.2,0.6,1.3l0.2,0c0.1,0.1,0,0.2,0,0.3c-0.5,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.3,0
			c-0.1,0-0.1-0.2,0-0.3l0.3,0c0.6-0.1,0.6-0.2,0.6-1.3L33.6,4.4z M34.5,5.9c0,0.2,0,0.3,0,0.3c0,0,0.2,0.1,0.7,0.1
			c0.4,0,0.8,0,1.1-0.3c0.3-0.2,0.5-0.6,0.5-1.3c0-0.8-0.5-1.6-1.7-1.6c-0.7,0-0.7,0-0.7,0.3L34.5,5.9z"/>
                <path d="M42.9,11.1c-0.8-0.3-1.3-0.7-1.4-0.9c-0.1-0.3,0-1,0.2-1.5c0.1-0.1,0.2,0,0.3,0c0,0.6,0.1,1.7,1.2,2
			c0.8,0.3,1.4-0.2,1.6-0.8c0.1-0.4,0.2-1-0.4-1.7l-0.8-0.9c-0.4-0.5-0.8-1.2-0.5-2.1c0.3-1,1.3-1.5,2.6-1.1
			c0.3,0.1,0.7,0.3,0.9,0.4c0.1,0.1,0.2,0.1,0.3,0.2c0,0.3-0.1,0.9-0.2,1.3c-0.1,0-0.2,0-0.3,0c0-0.5,0-1.3-1-1.6
			c-1-0.3-1.4,0.3-1.5,0.7c-0.2,0.6,0.2,1.2,0.5,1.5L45,7.6c0.5,0.6,0.9,1.4,0.6,2.3C45.3,11,44.2,11.5,42.9,11.1z"/>
                <path d="M51.2,8.8c0.6-0.9,0.7-1.1,0.2-1.5l-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.4,0.3,0.7,0.5,1.1,0.7c0.4,0.2,0.7,0.4,1.1,0.7
			c0,0.1,0,0.2-0.1,0.2l-0.2-0.1c-0.6-0.3-0.7-0.1-1.2,0.8l-2,3.2c-0.6,0.9-0.6,1.1-0.1,1.5l0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
			c-0.4-0.3-0.7-0.5-1.1-0.7c-0.4-0.2-0.7-0.4-1.1-0.7c0-0.1,0-0.2,0.1-0.2l0.2,0.1c0.6,0.3,0.7,0.1,1.2-0.8L51.2,8.8z"/>
                <path d="M55.7,17.3c-0.8,0.7-0.9,0.9-0.5,1.4l0.2,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.4-0.5-0.6-0.7-0.9-1c-0.3-0.3-0.5-0.6-1-1
			c0-0.1,0.1-0.2,0.2-0.2l0.3,0.3c0.4,0.4,0.6,0.3,1.4-0.4l3.4-3.2c0.2-0.2,0.2-0.2,0-0.5l-0.4-0.4c-0.3-0.3-0.7-0.7-1.1-0.8
			c-0.3,0-0.6,0.1-0.9,0.1c-0.1,0-0.2-0.2-0.1-0.3c0.5-0.2,1.1-0.5,1.4-0.8c0,0,0.1,0.1,0.1,0.1c-0.2,0.3,0,0.5,0.4,0.9l2.9,3.1
			c0.4,0.4,0.5,0.5,0.8,0.4c0.1,0,0.1,0.1,0.1,0.2c-0.4,0.2-1.1,0.7-1.3,1c-0.1,0-0.2-0.1-0.2-0.2c0.2-0.2,0.4-0.5,0.4-0.7
			c0-0.3-0.2-0.6-0.6-1.1l-0.5-0.5c-0.2-0.2-0.2-0.2-0.5,0L55.7,17.3z"/>
                <path d="M61.2,24.3c0.2-0.2,0.2-0.2,0.1-0.5l-0.7-1.4c-0.1-0.2-0.2-0.3-0.4-0.3l-1.2,0.1c-0.5,0.1-0.8,0.1-0.9,0.2
			c-0.1,0.1-0.1,0.1-0.1,0.4l0.1,0.3c0,0.1-0.2,0.2-0.2,0.1c-0.1-0.3-0.2-0.5-0.4-1c-0.1-0.3-0.3-0.6-0.5-0.9c0-0.1,0.2-0.2,0.2-0.1
			l0.2,0.3c0.1,0.2,0.3,0.4,0.7,0.3c0.5,0,1.3-0.1,2.5-0.2l3-0.2c0.4,0,0.6-0.1,0.7-0.2c0.2,0.2,0.4,0.3,0.6,0.3c0,0.1,0,0.1,0,0.2
			c-0.4,0.3-0.7,0.7-1.1,1l-3.2,3.1c-0.7,0.7-0.8,0.9-0.6,1.3l0.1,0.3c0,0.1-0.2,0.1-0.2,0.1c-0.2-0.4-0.3-0.7-0.5-1.2
			c-0.2-0.5-0.4-0.8-0.5-1.1c0-0.1,0.1-0.2,0.2-0.1l0.2,0.3c0.1,0.2,0.2,0.3,0.3,0.3c0.1-0.1,0.3-0.2,0.4-0.4L61.2,24.3z M61.2,22
			c-0.3,0-0.3,0-0.1,0.3l0.6,1.2c0.1,0.3,0.2,0.3,0.4,0.1l1.3-1.2c0.2-0.2,0.4-0.4,0.5-0.5l0,0c-0.1,0-0.3,0-0.7,0L61.2,22z"/>
                <path d="M60.6,31.5c-0.1-0.9,0.1-1.5,0.1-1.7c0.2-0.2,0.9-0.4,1.4-0.5c0.1,0,0.1,0.2,0.1,0.2c-0.5,0.2-1.5,0.8-1.3,2
			c0.1,0.9,0.8,1.2,1.4,1.1c0.5-0.1,1-0.2,1.3-1.1l0.5-1.1c0.3-0.6,0.8-1.3,1.6-1.4c1-0.1,1.9,0.5,2.1,1.9c0,0.3,0,0.7,0,1
			c0,0.1,0,0.3,0,0.4c-0.2,0.1-0.8,0.3-1.2,0.4c-0.1,0-0.1-0.2-0.1-0.3c0.5-0.2,1.1-0.6,1-1.6c-0.2-1-0.9-1.1-1.3-1.1
			c-0.6,0.1-1,0.7-1.1,1.1l-0.4,0.9c-0.3,0.7-0.8,1.4-1.8,1.6C61.7,33.5,60.8,32.8,60.6,31.5z"/>
            </g>
            <g>
                <path d="M9.5,43.1c0.7-0.3,0.9-0.4,0.8-0.8l-0.1-0.3c0-0.1,0.2-0.1,0.2-0.1c0.2,0.5,0.5,1.2,0.9,2.1c0.2,0.6,0.4,1.2,0.3,1.8
			c-0.1,0.5-0.3,1-1,1.2c-0.7,0.3-1.2,0-1.7-0.5c-0.1,0-0.1,0.1-0.1,0.2c0.1,0.6-0.1,1.5-1.2,1.9c-1.1,0.4-2.2-0.1-2.9-1.9
			c-0.1-0.3-0.3-0.8-0.4-1.2C4.3,45,4.1,44.7,4,44.3c0-0.1,0.2-0.1,0.2-0.1l0.1,0.2c0.3,0.5,0.4,0.5,1.4,0.1L9.5,43.1z M6.2,45.2
			c-0.4,0.1-0.8,0.3-1,0.6c-0.2,0.3-0.1,0.6,0,0.9c0.3,0.9,1,1.4,1.9,1c0.8-0.3,1.5-1,1-2.6c-0.2-0.5-0.3-0.5-0.5-0.5L6.2,45.2z
			 M8.5,44.4c-0.3,0.1-0.3,0.1-0.1,0.6c0.4,1,0.9,1.4,1.8,1.1c1-0.4,1.1-1.2,0.8-2c-0.1-0.2-0.2-0.4-0.2-0.4c-0.1-0.1-0.1-0.1-0.3,0
			L8.5,44.4z"/>
                <path d="M12.6,50.4c0.9-0.7,1-0.8,0.7-1.3l-0.1-0.1c0-0.1,0.2-0.2,0.2-0.1c0.3,0.4,0.5,0.7,0.7,1l1.2,1.6c0.4,0.5,0.8,1,0.9,1.1
			c-0.1,0.2-0.7,0.7-1,1c-0.1,0-0.2-0.1-0.2-0.2c0.3-0.4,0.5-0.7,0.3-1.2c-0.1-0.2-0.3-0.5-0.6-0.9l-0.5-0.6c-0.2-0.3-0.2-0.2-0.5,0
			l-1.7,1.3c-0.2,0.2-0.2,0.2,0,0.5l0.4,0.5c0.3,0.4,0.5,0.6,0.6,0.7c0.1,0.1,0.2,0.1,0.4,0l0.4-0.2c0.1,0,0.2,0.1,0.2,0.2
			c-0.2,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.6,0.5-0.8,0.6c-0.1,0-0.2-0.1-0.2-0.2l0.3-0.3c0.1-0.1,0.2-0.3,0.1-0.5
			c-0.1-0.1-0.2-0.4-0.4-0.7l-0.4-0.5c-0.2-0.3-0.2-0.3-0.4-0.1l-1.2,0.9c-0.4,0.3-0.7,0.6-0.8,0.8c0,0.2,0,0.4,0.6,1.2
			c0.5,0.6,0.7,0.9,0.9,1c0.2,0.1,0.7,0.1,1.3,0c0.1,0,0.1,0.2,0.1,0.3c-0.3,0.2-1.1,0.4-1.5,0.5c-0.5-0.8-1.1-1.5-1.7-2.3l-0.6-0.8
			c-0.3-0.4-0.5-0.6-0.9-1.1c0-0.1,0.1-0.2,0.2-0.2l0.2,0.3c0.4,0.4,0.6,0.4,1.4-0.3L12.6,50.4z"/>
                <path d="M17.7,56.2c0.6-0.8,0.7-1,0.4-1.3l-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.5,0.3,1.1,0.7,1.9,1.3c0.5,0.4,1,0.8,1.2,1.3
			c0.3,0.5,0.3,1.1-0.2,1.7c-0.6,0.8-1.5,0.8-2.1,0.6c-0.1,0-0.1,0.1-0.1,0.2c-0.1,1.6-0.2,2.6,0.1,3.5c0,0.2,0.2,0.5,0.4,0.7
			c0,0,0,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.4-0.2c-0.7-0.5-1-1.1-1-2.3c0-0.5,0.1-1.3,0.1-1.9c0-0.3-0.1-0.5-0.4-0.7
			c-0.4-0.3-0.4-0.3-0.5-0.1l-0.8,1.1c-0.7,0.9-0.7,1-0.3,1.4l0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.4-0.3-0.7-0.5-1-0.8
			c-0.4-0.3-0.7-0.5-1.1-0.7c0-0.1,0-0.2,0.1-0.2l0.2,0.1c0.5,0.3,0.6,0.2,1.3-0.7L17.7,56.2z M17.5,57.9c-0.1,0.2-0.2,0.2-0.2,0.3
			c0,0,0.1,0.2,0.6,0.5c0.3,0.2,0.7,0.4,1,0.4c0.4,0,0.8-0.2,1.2-0.7c0.5-0.7,0.5-1.6-0.4-2.3c-0.5-0.4-0.6-0.4-0.7-0.1L17.5,57.9z"
                />
                <path d="M28.4,66.3c-0.1,0.4-0.1,0.7,0,0.8c0,0,0,0.1,0,0.1c-0.1,0-0.3-0.1-0.5-0.1c-0.6,0-1.3-0.1-1.9-0.3
			c-1.2-0.4-2.2-1.1-2.7-2.1c-0.4-0.8-0.5-1.7-0.2-2.6c0.3-0.8,0.8-1.6,1.7-2.1c0.9-0.5,2-0.6,3.5-0.1c0.6,0.2,1.1,0.5,1.2,0.6
			c0.2,0.1,0.5,0.3,0.6,0.3c-0.1,0.3-0.2,0.9-0.3,1.5c-0.1,0.1-0.2,0-0.3-0.1c0.1-1.2-0.6-1.8-1.6-2.1c-1.9-0.6-3.2,0.5-3.7,2
			c-0.6,1.9-0.1,3.7,1.9,4.4c0.6,0.2,1,0.2,1.2,0.1c0.1-0.1,0.2-0.2,0.3-0.5l0.1-0.4c0.3-0.8,0.3-0.9-0.3-1.2l-0.4-0.2
			c-0.1-0.1,0-0.2,0.1-0.2c0.3,0.1,0.8,0.3,1.4,0.5c0.4,0.1,0.7,0.2,1.1,0.3c0.1,0.1,0,0.2-0.1,0.3l-0.2,0c-0.4-0.1-0.5,0.2-0.7,0.7
			L28.4,66.3z"/>
                <path d="M33.7,62.6c0-1.1,0-1.2-0.6-1.3l-0.2,0c-0.1,0,0-0.2,0-0.3c0.5,0,0.8,0,1.2,0l2,0c0.7,0,1.3,0,1.4,0
			c0.1,0.2,0.1,0.9,0.2,1.4c0,0.1-0.2,0.1-0.3,0c-0.2-0.5-0.2-0.9-0.8-1c-0.2-0.1-0.6-0.1-1-0.1l-0.8,0c-0.3,0-0.3,0-0.3,0.4l0,2.1
			c0,0.3,0,0.3,0.3,0.3l0.6,0c0.4,0,0.8,0,0.9-0.1c0.1,0,0.2-0.1,0.3-0.4l0.1-0.4c0.1-0.1,0.2-0.1,0.3,0c0,0.3,0,0.7,0,1.1
			c0,0.4,0,0.8,0,1c0,0.1-0.2,0.1-0.3,0L36.6,65c0-0.2-0.1-0.3-0.3-0.4c-0.1,0-0.4-0.1-0.8-0.1l-0.6,0c-0.3,0-0.3,0-0.3,0.3l0,1.5
			c0,0.6,0,0.9,0.2,1.1c0.1,0.1,0.3,0.2,1.3,0.2c0.8,0,1.1,0,1.3-0.2c0.2-0.1,0.5-0.5,0.7-1c0.1-0.1,0.2,0,0.3,0.1
			c-0.1,0.4-0.3,1.2-0.5,1.5c-0.9,0-1.9,0-2.8,0l-0.9,0c-0.5,0-0.8,0-1.4,0c-0.1,0-0.1-0.2,0-0.3l0.3,0c0.6-0.1,0.7-0.2,0.7-1.3
			L33.7,62.6z"/>
                <path d="M48,60.9c-0.2-0.5-0.6-1.7-0.6-2.1c0-0.1,0.2-0.2,0.5-0.4l0.1-0.1c0-0.1,0-0.2-0.1-0.2c-0.3,0.1-0.6,0.2-1,0.4
			c-0.4,0.1-0.7,0.3-1.2,0.4c-0.1,0.1,0,0.2,0.1,0.3l0.2,0c0.4-0.1,0.6-0.1,0.7,0c0.3,0.2,0.8,1.5,1,2l0.8,2.1
			c0.1,0.2,0.1,0.4,0.1,0.4l0,0c-0.6-0.3-1.3-0.7-2.3-1.2l-1.4-0.7c-0.5-0.2-2.1-1.1-2.5-1.5c-0.1,0.1-0.3,0.1-0.4,0.2
			c-0.2,0.1-0.7,0.3-1.1,0.4c-0.1,0.1,0,0.2,0.1,0.3l0.2,0c0.2-0.1,0.5-0.1,0.7,0c0.3,0.1,0.4,0.4,0.5,0.6l1.1,2.8
			c0.2,0.5,0.6,1.7,0.6,2.1c0,0.1-0.1,0.2-0.4,0.4L43.3,67c0,0.1,0,0.2,0.1,0.2c0.3-0.1,0.6-0.3,1-0.4c0.4-0.1,0.7-0.3,1.2-0.4
			c0.1-0.1,0-0.2-0.1-0.3l-0.3,0.1c-0.3,0.1-0.5,0.1-0.6,0c-0.3-0.2-0.8-1.5-0.9-2l-0.8-2.1c-0.1-0.3-0.2-0.5-0.2-0.7l0,0
			c0.3,0.1,1.3,0.7,1.6,0.9l3.2,1.7c1.2,0.7,1.6,0.9,1.8,1c0.1,0,0.1-0.1,0.1-0.2c-0.1-0.2-0.6-1.4-0.7-1.7L48,60.9z"/>
                <path d="M56.2,60.7c-0.7,0.6-1.3,0.7-1.5,0.8c-0.2-0.1-0.8-0.6-1.2-0.9c0-0.1,0.1-0.2,0.2-0.2c0.5,0.3,1.5,0.8,2.4,0
			c0.7-0.6,0.6-1.3,0.2-1.8c-0.3-0.4-0.7-0.7-1.6-0.6l-1.2,0.1c-0.6,0.1-1.5,0-2-0.7c-0.6-0.8-0.6-1.9,0.5-2.8
			c0.2-0.2,0.6-0.4,0.8-0.5c0.1-0.1,0.3-0.1,0.3-0.2c0.2,0.1,0.7,0.5,1,0.9c0,0.1-0.1,0.2-0.2,0.2c-0.4-0.3-1.1-0.6-1.9,0
			c-0.8,0.7-0.5,1.3-0.2,1.7c0.4,0.5,1.1,0.5,1.5,0.4l1-0.1c0.8-0.1,1.7-0.1,2.3,0.7C57.4,58.7,57.3,59.8,56.2,60.7z"/>
                <path d="M57.4,51.6c-0.9-0.6-1.1-0.7-1.5-0.3l-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0.3-0.4,0.5-0.7,0.8-1.1c0.2-0.3,0.4-0.6,0.7-1.1
			c0.1,0,0.2,0.1,0.2,0.1l-0.1,0.2c-0.3,0.5-0.2,0.7,0.7,1.3l3,2.2c0.9,0.6,1,0.7,1.5,0.2l0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
			c-0.3,0.4-0.5,0.7-0.8,1c-0.3,0.4-0.5,0.7-0.7,1.1c-0.1,0-0.2,0-0.2-0.1l0.1-0.2c0.3-0.5,0.2-0.6-0.7-1.3L57.4,51.6z"/>
                <path d="M66,47.1c-0.3,0.8-0.8,1.2-0.9,1.4c-0.3,0-1-0.1-1.5-0.3c-0.1-0.1,0-0.2,0.1-0.2c0.6,0,1.7,0,2.1-1.1
			c0.3-0.8-0.1-1.4-0.7-1.7c-0.4-0.2-0.9-0.3-1.7,0.3l-1,0.7c-0.5,0.4-1.3,0.7-2.1,0.4c-0.9-0.4-1.4-1.4-0.9-2.6
			c0.1-0.3,0.3-0.6,0.5-0.9c0.1-0.1,0.2-0.2,0.2-0.3c0.3,0,0.9,0.1,1.3,0.3c0,0.1,0,0.2-0.1,0.3c-0.5,0-1.3,0-1.6,0.9
			c-0.4,1,0.2,1.4,0.7,1.6c0.6,0.2,1.2-0.1,1.5-0.4l0.8-0.6c0.6-0.5,1.4-0.9,2.3-0.5C66.1,44.8,66.5,45.9,66,47.1z"/>
            </g>
        </g>
        <path d="M35.4,0C15.9,0,0,15.9,0,35.4C0,55,15.9,70.9,35.4,70.9C55,70.9,70.9,55,70.9,35.4C70.9,15.9,55,0,35.4,0z M35.4,69.8
	c-19,0-34.4-15.4-34.4-34.4c0-19,15.4-34.4,34.4-34.4c19,0,34.4,15.4,34.4,34.4C69.8,54.4,54.4,69.8,35.4,69.8z"/>
        <g>
            <path d="M43.8,52.6c-0.7,0.5-1.2,1.3-1.5,2c0.7,0.5,2.2,1.2,4.7,1.6c1.1-0.6,2-1.2,3-2c-0.9-0.2-1.8-0.7-2.4-1
		C46.6,52.6,45.2,51.6,43.8,52.6z"/>
            <path d="M27.2,47.9c-0.9-0.2-1.3-0.2-2.6,0.2c-2.2,0.7-5.7,4.6-5.7,4.6c1,1,2.2,1.9,3.4,2.7c1-1.2,3.5-4.1,5.9-4.1
		c1.3,0,2,0.5,3,1.2c0.1-0.1,0.2-0.3,0.3-0.4C29.6,51.1,27.2,47.9,27.2,47.9z"/>
            <path d="M34.8,51.3c-1.1,0.1-1.8,1-2.3,1.9c2,1.4,3.8,3,6.4,3.7c2,0.6,2.8,0.8,4.9,0.9c0.9-0.3,1.8-0.7,2.7-1.2
		c-1.3-0.2-3-0.8-4.4-1.4C39.6,54.1,36.9,51,34.8,51.3z"/>
        </g>
        <path d="M28.7,52.5c-3,0-4.2,2.5-5.1,3.6c3.5,2,7.6,3.2,11.9,3.2c2.3,0,4.5-0.3,6.6-0.9c-1.3-0.1-3-0.4-4.4-1
	C34.3,56.1,30.8,52.5,28.7,52.5z"/>
        <path d="M49.9,50.5c-2-2.5-3.5-3-4.3-2.8c-0.8,0.2-0.6,0.8-1.5,1.8c-0.8,1-2.7,2.1-3.1,2.3c0.3,0.3,0.7,0.8,0.8,0.9
	c0.9-0.9,2.2-1.7,3.3-1.7c2.1,0,2.7,1.7,5.5,2.7c0.6-0.5,1.2-1,1.7-1.5C52.4,52.2,51.1,52,49.9,50.5z"/>
        <g>
            <path d="M45.3,29.9c-0.1-0.1,0-0.3-0.1-0.4c-0.1-0.4-0.3-0.8-0.3-1.3c-0.1-0.3,0.2-0.4,0.4-0.5c0.1-0.3,0.1-0.6,0-0.8
		c-0.1-0.4-0.7-0.5-0.7-1c0-0.3-0.2-0.6-0.2-1c-0.2-0.5-0.1-1.1-0.2-1.6c-0.1-0.6-0.1-1.2-0.2-1.8c-0.1-0.7-0.2-1.4-0.4-2.1
		c-0.1-0.6-0.3-1.2-0.6-1.7c0-0.4-0.4-0.7-0.2-1.1c0.3-0.5,0.6-1,0.8-1.5c0.2-0.3,0.1-0.7,0.2-1.1c0-0.3,0.1-0.6,0-0.9
		c-0.1,0-0.1,0-0.1,0c-0.1,0.5-0.7,0.7-1.1,1c-0.6,0.2-1.2,0.1-1.9,0.2c-0.4,0-0.7,0.4-1.2,0.1c-0.2-0.1-0.4-0.2-0.7-0.3
		c-0.5,0-1,0-1.4,0c-0.7,0-1.2-0.4-1.9-0.3c-0.9,0-1.6,0.4-2.5,0.3c-0.8-0.2-1.6,0.1-2.2,0.4c-0.2,0-0.5-0.2-0.8-0.2
		c-0.7-0.3-1.6,0.2-2.1-0.3c-0.3-0.3-0.5-0.6-0.5-1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.3-0.3,0.6-0.3,0.9c-0.1,0.4,0,0.9,0.1,1.3
		c0.1,0.7,0.6,1.3,0.5,2c-0.1,0.3-0.3,0.7-0.4,1C27,19,27.1,19.5,27,19.9c-0.3,0.8-0.1,1.6,0,2.5c-0.3,1-0.4,2-0.5,3
		c-0.1,0.7,0,1.4-0.3,2c-0.4,0.4-0.7,0.7-0.6,1.3c0,0.3,0.2,0.5,0.3,0.7c0.1,0.5,0.1,1.1,0,1.6c0,0.4-0.4,0.6-0.5,0.9
		c-0.1,0.4,0.2,0.6,0.4,0.8c0.1,0.2,0.1,0.6,0.2,0.8c0.1,0.7,0.2,1.3,0.3,2c0.2,1,0.3,2,0.5,2.9c0.2,1.2,0.5,2.3,0.8,3.4
		c0.4,0.8,0.5,1.7,0.8,2.5c0.2,0.7,0.6,1.3,0.6,2c0,0.1,0,0.2,0.1,0.2c0,0.5-0.4,0.8-0.3,1.3c0,0,1.5,2.6,3.2,3.7
		c0.7-0.8,1.7-1.5,3-1.5c2.1,0,4,2.1,6,3.7c0.1-0.2,0.3-0.3,0.5-0.5c-0.7-0.9-3.6-4.3-6-4.7c0,0,0.2-0.5,0-0.7
		c-0.2-0.3-0.2-0.5-0.2-0.8c0-0.1-0.1-0.4,0-0.5c0.4,0.1,0.5,0.5,0.7,0.8c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0-0.4,0.5-0.6,0.7-0.8
		c0.1,0,0.1,0,0.2,0.1c0.1,0.4,0,0.9,0.3,1.2c0.1,0.1,0,0.2-0.1,0.3c0,0.3-0.2,0.2-0.2,0.2c1.8,0.9,2.8,1.9,3.8,3.1
		c0.7-0.5,3.2-2.1,3.1-3.7c0-0.5-0.3-0.5-0.4-0.8c-0.2-0.2-0.4-0.4-0.5-0.7c-0.3-0.5-0.5-1.1-0.4-1.8c0.2-0.5,0.2-1,0.4-1.5
		c0.3-0.9,0.5-1.8,0.7-2.7c0.3-1.2,0.7-2.5,0.8-3.8c0.2-0.4,0.2-0.9,0.3-1.4c0.2-0.4,0.1-1,0.2-1.5c0.1-0.4,0-0.8,0.2-1.1
		c0.1-0.2,0-0.4,0.1-0.6c0-0.3,0.3-0.2,0.3-0.5c0.2-0.1,0.3-0.4,0.4-0.5C45.9,30.6,45.5,30.3,45.3,29.9z M44.1,26.4
		c0.2,0.1,0.4,0.1,0.5,0.3c0.2,0.1,0.2,0.3,0.3,0.5c0,0.1,0,0.3-0.1,0.3c-0.4,0-0.7-0.2-0.9-0.4C43.8,26.8,44,26.6,44.1,26.4z
		 M42,31.3c0,0.6,0,1.3-0.1,1.8c-0.1,0.1,0,0.2,0,0.3c-0.1,0.6,0,1.3-0.1,1.9l-0.1,0C41.4,35,41,34.9,41,34.5c0.3-1,0.2-2.2,0.3-3.3
		c0.1-0.9,0.1-1.9,0-2.8c0,0,0-0.1,0.1-0.1c0.3,0.1,0.4,0.4,0.7,0.5C42.1,29.7,41.9,30.5,42,31.3z M28.3,23.4
		c-0.3,0-0.3-0.3-0.4-0.5c-0.1-0.1-0.1-0.3-0.1-0.4c0.1-0.1,0.1-0.2,0.1-0.3c-0.1-0.1-0.3,0-0.3-0.2c0-0.2-0.1-0.5,0.2-0.6
		c0.2-0.1,0.5,0,0.8-0.2c0,0,0-0.1-0.1-0.1c-0.3,0-0.5,0.1-0.7,0.2c-0.1,0-0.1-0.1-0.1-0.2c0-0.7-0.1-1.3,0.1-1.8
		c0-0.1-0.1-0.3,0-0.4c0.3-0.1,0.4,0.4,0.7,0.3v0c-0.2-0.2-0.4-0.4-0.6-0.4c-0.1-0.2,0-0.5,0-0.7c0.1-0.1,0.1-0.2,0.2-0.1
		c0.2,0.1,0.3,0.3,0.5,0.4c0.1,0,0.1-0.1,0.1-0.2c-0.2-0.3-0.8-0.3-0.5-0.8c0-0.2,0.1-0.4,0.3-0.4c0.2,0.1,0.3,0.3,0.5,0.4l0.1,0
		c-0.2-0.2-0.3-0.4-0.2-0.6c0.1-0.1,0.3,0.1,0.4,0.1l0.2,0.1c0.2,0.1,0.5,0.2,0.5,0.5c0.2,0.3,0.2,0.6,0.5,0.8
		c0.1,0.2,0.2,0.3,0.3,0.4c-0.1,0.1-0.2,0.1-0.2,0c-0.2-0.1-0.3-0.4-0.5-0.6c-0.1,0-0.1,0-0.1,0.1c0.1,0.2,0.1,0.5,0.3,0.6l0,0.2
		c-0.3,0-0.7-0.3-0.9-0.5c0,0-0.1-0.1-0.2,0c-0.1,0.1,0,0.2,0,0.2c0.2,0.3,0.3,0.6,0.6,0.7c0.1,0,0.1,0.2,0,0.2
		c0,0.2-0.2,0.1-0.3,0.1c-0.2-0.2-0.4-0.4-0.6-0.5l-0.1,0c0,0.2,0.2,0.3,0.2,0.4c0.2,0.3,0.6,0.3,0.5,0.7c0,0.1,0,0.2-0.1,0.2
		c-0.5-0.1-1.2-0.3-1.6,0.2c0,0.1,0,0.1,0.1,0.1c0.5,0,0.9,0,1.3,0.3c0.1,0.1,0.2,0.2,0.1,0.3c-0.1,0-0.2,0-0.3,0.1
		c-0.1,0.1-0.3,0.2-0.5,0.3c0,0,0,0.1,0,0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.1,0,0.2,0,0.2,0.1c0,0.1-0.1,0.1-0.2,0.2
		c-0.2,0.2-0.5,0.3-0.5,0.6l0.1,0c0.3,0,0.5-0.5,0.8-0.4c0.1,0,0.1,0.1,0.1,0.2c-0.4,0.3-1,0.5-1.1,1.1c0,0,0,0.1,0,0.1
		c0.1,0,0.1-0.1,0.2-0.2c0.4-0.3,0.7-0.6,1.2-0.7c0,0,0.1,0,0.1,0.1c0,0.2-0.4,0.4-0.4,0.6c-0.1,0.2-0.1,0.4-0.2,0.6
		c0,0,0.1,0.1,0.1,0c0.2-0.3,0.4-0.8,0.8-1c0,0.1,0.1,0,0.2,0.1c0.2,0,0.5,0.1,0.6,0.3c0,0.5-0.2,1.1,0,1.5c0.1-0.1,0-0.2,0.1-0.3
		c0-0.4,0.2-0.6,0.3-1c0-0.1,0.2-0.1,0.2-0.1c0.2,0.1,0.2,0.3,0.4,0.4c0.1-0.1,0-0.4,0-0.5c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.2,0
		c0.1-0.1,0-0.2,0-0.2c0.2-0.2,0.3-0.6,0.7-0.5l0.1,0.1c0,0.2,0,0.5-0.1,0.7c-0.1,0-0.1-0.2-0.3-0.1c-0.1,0.1,0,0.2,0,0.3
		c0.1,0.2,0.2,0.4,0.3,0.6c0,0.1,0.1,0.1,0,0.2c-0.3,0-0.4-0.3-0.5-0.4c-0.1-0.1-0.2,0-0.2,0.1c-0.1,0.3,0.2,0.6,0.4,0.8
		c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2,0,0.3l0.1,0.2c-0.1,0.1-0.3,0-0.5-0.1c-0.3-0.2-0.6-0.6-0.7-1c0,0-0.1-0.1-0.1,0
		c-0.1,0.3,0,0.5,0.2,0.7c0.2,0.1,0.3,0.3,0.3,0.5c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3-0.2-0.3-0.6-0.3-0.9c0,0-0.1-0.1-0.1,0
		c-0.1,0.2-0.1,0.4-0.1,0.5c0,0.1,0.1,0.4-0.1,0.5c-0.2,0.1-0.4,0-0.6,0.1c-0.2-0.1-0.2-0.4-0.2-0.6c0-0.1-0.1,0-0.1,0
		c-0.2,0.1-0.2,0.6-0.5,0.5c-0.2-0.1-0.5-0.2-0.7-0.5c-0.1-0.3,0.2-0.4,0.2-0.7c0.1-0.2,0.3-0.4,0.4-0.6c-0.1-0.1-0.3,0.1-0.4,0.2
		c-0.2,0.3-0.4,0.6-0.5,0.9c0,0.1-0.1,0.1-0.2,0.1c-0.3-0.3-1-0.5-0.9-1c0.1-0.1,0.1-0.2,0.2-0.3C28.5,23.2,28.5,23.4,28.3,23.4z
		 M35.3,24.5c0,0,0.1,0,0.1,0c0.1-0.2,0.1-0.6,0.1-0.8c0-0.7,0.1-1.4,0.2-2c0-0.1,0-0.3,0.2-0.3c0.2,0,0.5,0.1,0.6,0.3
		c0.1,0.9,0,1.9-0.3,2.7c-0.1,0.4-0.4,0.7-0.6,1.1c-0.1,0.1-0.1,0.4-0.4,0.4c-0.6-0.8-1-1.8-1-2.9c0-0.4-0.2-1,0.1-1.3
		c0.3-0.1,0.5-0.4,0.8-0.3l0,0.1C35.3,22.4,35,23.5,35.3,24.5z M37.9,27.4c-0.5-0.2-1-0.7-1-1.3c0-0.1,0.1-0.1,0.1,0l0.5,0.5
		c0.4,0.2,0.8,0.5,1,0.9c0,0.1,0.1,0.3-0.1,0.4c-0.2,0.2-0.4,0.3-0.7,0.3c-0.5-0.1-1-0.5-1.2-0.9c-0.1-0.3-0.1-0.7,0-1
		c0.2,0,0.1,0.2,0.2,0.3c0.2,0.4,0.6,1,1.1,1.1C38,27.5,38,27.4,37.9,27.4z M37.7,22.7c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.1-0.2,0.1-0.4,0.1-0.5c0,0,0-0.1,0-0.1c-0.1-0.1-0.2,0-0.2-0.2c0-0.1,0-0.1,0-0.2l0.2,0c0.2,0.2,0.5,0.5,0.8,0.7
		c0,0.2,0,0.4-0.1,0.6c0,0.2-0.2,0.4-0.2,0.6c0,0.1,0.1,0.1,0.1,0c0.2-0.3,0.3-0.6,0.6-0.9c0.3-0.1,0.6,0,0.9,0
		c0.2,0.1,0.1,0.4,0.2,0.6c0,0.1,0,0.3,0.1,0.4c0.2,0,0.2-0.3,0.2-0.4c0.1-0.3,0-0.5,0-0.8c0-0.1,0-0.1,0-0.2c0.1-0.1,0.2-0.1,0.3,0
		c0.2,0.1,0.3,0.4,0.5,0.5c0.1,0,0-0.1,0-0.2c0-0.2-0.3-0.4-0.3-0.6c0-0.1,0.1-0.1,0.2-0.1c0.2,0.1,0.1,0.4,0.3,0.5
		c0.1,0,0-0.1,0-0.2c-0.1-0.2-0.2-0.4-0.2-0.6c0.1-0.1,0.2,0,0.2,0c0.3,0.1,0.5,0.2,0.8,0.4c0.3,0.2,0.3,0.6,0.4,0.9
		c-0.1,0.3,0.2,0.7-0.1,0.9c-0.2,0.1-0.4,0.3-0.6,0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.3-0.7-0.6-0.6c-0.1,0.1,0.1,0.3,0.1,0.5
		c0,0.3,0.3,0.6,0.1,0.9c-0.3-0.1-0.4,0.3-0.7,0.2c-0.3-0.1-0.6,0.5-0.9,0c0-0.4,0-0.8-0.1-1.3c0-0.1-0.1-0.3-0.2-0.4
		c-0.1,0.1,0,0.3-0.1,0.5c0,0.4-0.1,0.8-0.3,1.1c-0.1,0.1-0.2,0.2-0.3,0.1c0,0-0.1,0-0.1-0.1c0-0.2,0.1-0.4,0.1-0.6
		c0-0.1-0.1,0-0.1,0c-0.2,0.3-0.5,0.7-0.9,0.6c-0.1-0.1-0.1-0.1-0.2-0.1c0.1-0.2,0.4-0.4,0.4-0.6c-0.2,0-0.3,0.2-0.5,0.3
		c-0.1-0.7,0.6-1.1,0.7-1.8C37.8,22.7,37.8,22.7,37.7,22.7z M37.7,19.4c0.3-0.4,0.8-0.5,1.3-0.6c0.5,0,1.1,0.1,1.4,0.5
		c0.4,0.5,0.5,1.2,0.3,1.8c-0.3,0.6-0.9,1.1-1.6,1.1c-0.3,0.1-0.5-0.1-0.8-0.2c-0.4-0.2-0.5-0.6-0.7-0.9
		C37.3,20.6,37.5,19.9,37.7,19.4z M42.9,27.4c0,0,0.1-0.1,0.2-0.1c0.2,0,0.3,0,0.5,0.1c0.1,0.2,0.2,0.5,0.1,0.7
		c-0.3,0.1-0.5,0-0.7-0.2C42.8,27.7,43,27.6,42.9,27.4z M43.2,27.1c-0.3-0.1-0.6-0.4-0.8-0.7c-0.1-0.3-0.3-0.5-0.3-0.8
		c0-0.2-0.1-0.4,0-0.6h0c0.2,0.5,0.6,0.9,1,1.3c0.1,0.1,0.3,0.1,0.4,0.3C43.4,26.8,43.3,27,43.2,27.1z M40.8,33.2
		c-0.3,0.1-0.5-0.3-0.8-0.4c-0.3-0.4-0.6-0.8-0.6-1.3c0,0,0.1-0.1,0.1-0.1c0.2,0.3,0.4,0.7,0.8,0.8c0.1,0,0.1,0,0.1-0.1
		c-0.1-0.3-0.3-0.6-0.2-0.9c0.2,0,0.3,0.3,0.6,0.3c0.1,0.1,0.1,0.4,0.1,0.5C40.8,32.5,40.9,32.9,40.8,33.2z M40.7,39.7
		c0-0.8,0.2-1.7,0.2-2.5c0.1-0.1,0.3-0.2,0.5-0.2c0,0.1,0,0.3,0,0.5c0,0.8-0.1,1.6-0.1,2.5c0,0.3-0.1,0.7-0.1,1
		c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.7-0.2,1.3-0.3,1.9c-0.1,0.1-0.1,0.4-0.3,0.4c-0.2-0.2-0.1-0.5-0.1-0.7c0.1-0.2,0-0.5,0.1-0.7
		C40.4,41.6,40.8,40.7,40.7,39.7z M40.4,45.6c0.2,0.4,0.2,0.8,0.3,1.1c0,0.2,0.1,0.4-0.1,0.5c-0.5-0.1-0.9,0.1-1.3,0.4
		c-0.1,0-0.2,0-0.3-0.1c-0.1-0.2,0-0.4,0-0.6c0.1-0.2,0-0.5,0.2-0.7c0.1-0.1,0.3-0.1,0.3-0.3c-0.2-0.1-0.5,0-0.6-0.2
		c-0.2-0.3-0.4-0.6-0.4-1c0.3-0.3,0.8-0.4,1.2-0.3c0.1,0.1,0.3,0.1,0.3,0.2C40.3,45,40.3,45.3,40.4,45.6z M39.7,47.9
		c0.1-0.3,0.4-0.3,0.6-0.4c0.1,0,0.2,0,0.2,0.1c-0.1,0.6-0.2,1.2-0.6,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0,0-0.1,0-0.2
		C40,48.8,39.9,48.3,39.7,47.9z M38.7,49c-0.4-0.1-0.9-0.4-0.9-0.9c0-0.1-0.1-0.2-0.1-0.3c0.2-0.2,0.5-0.2,0.8-0.2l0.1,0.1
		C38.5,48,38.4,48.6,38.7,49z M38.1,47.3c-0.2,0-0.4,0.2-0.6,0.2c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.7,0-1.4,0.6-1.8
		c0.1-0.1,0.2,0,0.3,0c0.2,0.2,0.3,0.5,0.4,0.7c0.1,0.2,0.2,0.3,0.3,0.6c0.1,0.3,0,0.6,0,0.9C38.6,47.3,38.4,47.1,38.1,47.3z
		 M33.5,48.2c0.2-0.1,0.4-0.3,0.7-0.1c0.2,0.2,0.2,0.4,0.2,0.6c-0.2,0.4-0.6,0.8-1,0.9c0,0-0.1,0-0.1-0.1c0-0.3,0.2-0.5,0.1-0.8
		C33.3,48.5,33.4,48.4,33.5,48.2z M34.3,47.9c-0.2,0-0.4-0.1-0.5-0.2c-0.2,0-0.2,0.2-0.4,0.3c-0.1,0-0.1,0.1-0.2,0.1
		c-0.1-0.2-0.1-0.4-0.1-0.6c-0.1-0.2,0-0.5,0.1-0.7c0.2-0.1,0.3-0.3,0.5-0.3c0.1-0.1,0.1-0.1,0.1-0.2c-0.1-0.1-0.3-0.1-0.4-0.1
		c-0.2,0.1-0.3,0.2-0.5,0.4c-0.2-0.3-0.2-0.6-0.2-1c0-0.3,0.1-0.7,0.4-0.8c0.2,0.1,0.6,0.1,0.7,0.4c0.4,0.5,0.8,1,0.9,1.7
		c0,0.4,0.1,0.8-0.1,1.2C34.5,48,34.4,47.9,34.3,47.9z M33.1,44c-0.6-0.3-0.7-1-0.6-1.6c0-0.1,0-0.3,0.2-0.3
		c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.6,1,1,1.5,1.5c0.4,0.2,0.6,0.7,0.6,1.2c0.1,0.1,0,0.3,0,0.4c0,0-0.1,0-0.1-0.1
		C34.4,44.8,33.8,44.2,33.1,44z M30.3,26.6c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.2,0.3-0.4,0.5-0.6c0.1,0,0.2,0,0.2,0.1
		c0,0.3-0.1,0.5-0.2,0.8c-0.3,0.6-0.7,1.2-1.4,1.3c-0.2,0-0.4-0.1-0.5-0.2c0-0.3-0.1-0.5-0.1-0.7c0-0.5,0.4-0.9,0.7-1.4
		c0.1-0.1,0.3-0.2,0.3,0c-0.1,0.3-0.1,0.7-0.1,1C30.2,26.5,30.2,26.6,30.3,26.6z M31.8,30.3c0.4-0.3,0.7-0.8,1-1.1c0,0,0.1,0,0.1,0
		c0,0.7-0.4,1.3-0.9,1.9c-0.3,0.3-0.8,0.5-1.2,0.4c-0.3-0.1-0.4-0.4-0.5-0.6c0-0.3,0.1-0.5,0.2-0.7c0.4-0.3,0.9-0.6,1.1-1
		c0-0.1,0.1-0.1,0.2-0.1c0,0.5-0.3,0.9-0.5,1.3C31.5,30.5,31.6,30.4,31.8,30.3z M32.9,27.6c0.4-0.1,0.6-0.4,0.8-0.7
		c0-0.2,0.2-0.3,0.3-0.5c0,0,0,0,0.1,0c0,0.7-0.3,1.4-0.9,1.9c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2-0.1-0.5-0.2-0.5-0.4
		c0-0.5,0.1-0.9,0.5-1.1c0.1-0.1,0.2-0.3,0.4-0.4c0,0.3-0.1,0.6-0.2,0.8C33,27.4,32.8,27.5,32.9,27.6z M34.8,34.5
		C34.8,34.5,34.8,34.6,34.8,34.5c0.2-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2,0,0.2,0c0.3,0.2,0.1,0.6,0.2,0.9c0,0.3-0.1,0.7-0.2,1
		c0.1,0.1,0.2,0,0.2,0c0.3-0.4,0.2-0.8,0.3-1.3c0-0.1,0-0.2,0.1-0.3c0.1,0,0.1,0,0.1,0.1c0,0.3,0,0.6,0,0.9l0.1,0
		c0.3-0.4,0.1-0.9,0.3-1.4c0.1-0.2,0-0.5,0.1-0.8c0.2,0,0.2,0.2,0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,1,0.4,2.4-0.6,3
		c-0.3,0.2-0.8,0.1-1.1,0c-0.3-0.1-0.4-0.5-0.5-0.7c-0.2-0.6-0.4-1.2-0.3-1.9c0.1-0.6,0.2-1.3,0.5-1.8c0.1,0,0,0.2,0,0.3
		C34.7,33.5,34.6,34,34.8,34.5z M37.7,32.4c-0.1-0.2-0.1-0.5-0.2-0.8c0-0.2,0.2,0,0.2,0.1c0.2,0.6,1.1,0.7,1.1,1.4
		c0.1,0.2,0.1,0.4,0,0.6c-0.2,0.2-0.5,0.3-0.9,0.3c-0.5-0.2-0.8-0.7-1.2-1.1c0-0.3-0.3-0.5-0.1-0.8c0.1,0,0.2,0.1,0.2,0.1
		c0.3,0.3,0.6,0.6,0.9,0.8c0.1,0,0.1,0,0.2-0.1C38,32.7,37.7,32.6,37.7,32.4z M37.2,38.3c-0.1-0.2-0.4-0.4-0.4-0.6
		c0-0.1-0.1-0.2,0.1-0.3c0.5,0.2,0.9,0.5,1.2,0.9c0.1,0.3,0.1,0.7,0,0.9c0,0.1-0.1,0.3-0.2,0.3c-0.1,0.1-0.3,0.1-0.5,0.1
		c-0.5-0.3-0.8-0.9-0.9-1.5h0.1c0.2,0.2,0.3,0.7,0.7,0.5C37.2,38.5,37.2,38.4,37.2,38.3z M38,37.1c-0.3-0.4-0.6-0.8-0.6-1.4
		c0,0,0.1-0.1,0.1,0c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.2,0.5,0.5,0.5c0.1,0,0-0.2,0-0.2c0-0.3-0.2-0.5-0.1-0.8h0
		c0.4,0.4,0.9,0.8,0.7,1.4c0,0.2-0.2,0.3-0.3,0.3C38.4,37.2,38.2,37.2,38,37.1z M32.9,36.5c0.3-0.3,0.5-0.6,0.7-0.9
		c0.1,0,0.1,0,0.1,0.1c0.1,0.8-0.3,1.4-0.9,1.8c-0.2,0.1-0.4,0.1-0.5,0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.6,0.1-1.1,0.5-1.5
		c0.2-0.2,0.2-0.4,0.4-0.5c0.2,0.1,0.1,0.5,0.1,0.7c0,0.3-0.2,0.4-0.2,0.7C32.8,36.7,32.8,36.6,32.9,36.5z M33.5,37.7
		c0.1-0.3,0.4-0.4,0.6-0.7c0.1,0,0.2,0,0.2,0.1c0,0.5-0.3,0.9-0.5,1.3c0.1,0.1,0.2-0.1,0.4-0.1c0.3-0.2,0.3-0.6,0.5-0.9
		c0.1,0,0.2,0.1,0.2,0.1c0.3,0.7-0.1,1.3-0.4,1.8c-0.2,0.4-0.6,0.2-0.9,0.3c-0.2,0-0.4-0.1-0.4-0.3C32.9,38.7,33.3,38.3,33.5,37.7z
		 M34.3,31.9c0,0.3-0.1,0.5-0.2,0.8l-0.1,0.1c0.1,0.1,0,0.2,0,0.3c-0.1,0.6-0.6,1-1.2,1.1c-0.2,0-0.4-0.1-0.6-0.2
		c-0.3-0.2-0.2-0.6-0.2-1c0.2-0.6,0.6-1.2,1-1.8c0,0,0.1-0.1,0.1,0c0.1,0.6,0,1.1-0.2,1.6c0,0.1,0,0.2,0.1,0.3
		c0.2-0.1,0.2-0.3,0.4-0.4l0-0.1c0.2-0.4,0.5-0.8,0.6-1.3c0-0.1,0.1-0.1,0.1-0.1C34.4,31.6,34.3,31.8,34.3,31.9z M31.3,28.9
		c-0.2,0.2-0.5,0.4-0.8,0.4c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.6,0.4-0.9,0.5-1.4c0.1-0.1,0.2-0.3,0.3-0.3c0.1,0.3-0.1,0.6-0.1,1
		c0.1,0.1,0.2-0.1,0.3-0.2c0.2-0.4,0.4-0.9,0.5-1.3c0-0.1,0.1,0,0.1,0C31.9,27.6,31.7,28.3,31.3,28.9z M30.2,32.5l0.5-0.5
		c0.1,0,0.1,0,0.1,0.1c0.1,0.3-0.1,0.7-0.2,0.9c0,0.1-0.1,0.3,0,0.4c0.2,0,0.3-0.2,0.4-0.3c0.2-0.3,0.4-0.6,0.7-0.8c0,0,0.1,0,0.1,0
		c0,0.5-0.3,0.9-0.5,1.3c-0.2,0.4-0.6,0.7-1.1,0.6l-0.1-0.1C30.1,33.6,29.9,33,30.2,32.5z M31.9,38.6c0.1-0.1,0.1-0.2,0.2-0.2
		c0,0.2-0.2,0.3-0.2,0.5c-0.1,0.2-0.2,0.4-0.2,0.6c0.1,0.1,0.2,0,0.3,0c0.2-0.2,0.4-0.4,0.6-0.6c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
		c-0.2,0.4-0.4,1-1,1.1c-0.2,0-0.4,0-0.5-0.1c-0.1-0.3,0-0.7,0.2-1C31.5,38.9,31.6,38.6,31.9,38.6z M31.8,42.4
		c0,0.4,0.1,0.9-0.1,1.2c-0.1,0.1-0.2,0.2-0.3,0.1c-0.2-0.1-0.2-0.2-0.2-0.4c0-0.4-0.1-0.8,0.1-1.2c0.1-0.3,0.1-0.5,0.2-0.8
		c0-0.2,0.1-0.4,0.1-0.6c0.1,0.1,0,0.3,0.1,0.4C31.8,41.6,31.6,42,31.8,42.4z M31.1,45.4c0.1-0.2,0.2-0.5,0.4-0.7
		c0.1-0.2,0.4-0.1,0.6-0.2c0.2,0.1,0.6-0.1,0.5,0.2c-0.2,0.4-0.4,0.9-0.2,1.3c-0.1,0.2-0.6,0-0.5,0.3c0.2,0.3,0.5,0.1,0.6,0.4
		c0.1,0.3,0.1,0.7,0.2,1c0,0.1-0.1,0-0.1,0c-0.3-0.2-0.6-0.4-0.9-0.2c-0.2,0.1-0.3,0.3-0.5,0.5C31.1,48.1,31,48,31,47.9
		C30.8,47.1,30.9,46.2,31.1,45.4z M33.6,42.8c-0.2-0.3-0.3-0.7-0.5-1c0-0.4-0.1-0.7,0-1.1c0-0.1,0-0.2,0.1-0.3l0,0
		c0,0.8,0.5,1.3,0.9,1.9c0.3,0.3,1,0.4,0.9,1c0,0.3,0.2,0.5,0,0.8c-0.1,0-0.1,0-0.1-0.1C34.6,43.5,34.1,43.2,33.6,42.8z M35.1,41.9
		c-0.4-0.4-0.7-0.9-0.5-1.5c0-0.4,0.2-0.8,0.5-1c0.1-0.1,0-0.3,0.2-0.3c0.1,0.1,0,0.2-0.1,0.2c0.1,0.4,0.3,1-0.1,1.3
		c0,0.1,0,0.2,0.1,0.2c0.4,0.1,0.3-0.4,0.5-0.5c0,0.3-0.2,0.6-0.4,0.8c0,0.1,0.2,0.1,0.2,0.2c0.2,0,0.3-0.3,0.4-0.4
		c0.1-0.5,0.1-0.9-0.2-1.3c0-0.1-0.1-0.3,0.1-0.5c0.3,0,0.4,0.3,0.5,0.5c0.2,0.4,0.3,1,0.1,1.4c0.1,0.5-0.4,0.6-0.6,0.9
		C35.6,41.9,35.3,42,35.1,41.9z M36.1,42.8c0-0.2,0.2-0.3,0.3-0.5c0.2-0.5,0.4-0.9,0.7-1.3c0.2-0.2,0.1-0.5,0.2-0.8
		c0.1,0,0.1,0.2,0.2,0.3c0.1,0.5,0.1,1.1-0.1,1.5c-0.3,0.6-0.8,1.1-1,1.6c-0.1,0.1-0.1,0.3-0.2,0.4C35.9,43.8,36,43.2,36.1,42.8z
		 M36.5,44.8c0.1-0.5,0.4-0.9,0.6-1.3c0.4-0.5,0.6-1,0.8-1.5c0-0.1-0.1-0.2,0-0.3c0.2,0.3,0.3,0.7,0.2,1.1c0,1-1.1,1.6-1.2,2.5
		c-0.1,0.1-0.2,0.3-0.3,0.4C36.4,45.4,36.5,45.1,36.5,44.8z M38.8,42.8c0-0.6,0.2-1.1,0.2-1.7c0-0.1,0.1-0.2,0.1-0.1
		c0.1,0.6,0,1.2,0.2,1.7c0,0.4,0.1,0.9-0.3,1.1c-0.1,0.1-0.3-0.1-0.3-0.2C38.6,43.3,38.9,43.1,38.8,42.8z M39.7,39.3
		c-0.1,0.1-0.2,0.2-0.4,0.1c-0.6-0.2-0.8-0.8-0.9-1.3c0.1,0,0.2,0.2,0.3,0.2c0.2,0.1,0.3,0.5,0.6,0.3c0-0.3-0.3-0.4-0.4-0.7
		c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.1,0.2,0.3,0.3c0.3,0.1,0.6,0.4,0.7,0.7C39.9,38.8,39.8,39.1,39.7,39.3z M38.5,30.6
		c-0.4-0.3-0.5-0.7-0.6-1.2c0-0.1-0.1-0.3,0-0.4c0.1,0,0.1,0.1,0.1,0.1c0.2,0.2,0.4,0.6,0.7,0.7c0.1,0.1,0.1,0.1,0.1,0.2
		c0.1,0,0.2,0,0.2-0.1c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.3-0.4-0.5-0.3-0.9c0.1-0.1,0.2,0.1,0.4,0.2c0.4,0.1,1,0.4,1.1,0.9
		c0,0.3,0,0.7-0.2,0.9C39.4,30.8,38.9,30.8,38.5,30.6z M39.7,27.6c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.2,0.4,0.5,0.3,0.8
		c-0.1,0.1-0.1,0.3-0.2,0.4c-0.3,0-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.4-0.2-0.7c0-0.1,0.1,0,0.2,0c0.2,0.1,0.3,0.4,0.5,0.2
		C39.8,27.9,39.6,27.8,39.7,27.6z M40,27.4c-0.3-0.5-0.6-0.9-0.6-1.5c0,0,0.1,0,0.1,0c0.2,0.3,0.5,0.8,1,0.8
		c0.1-0.1-0.1-0.3-0.1-0.4c0-0.2-0.1-0.3-0.1-0.5c0.1,0,0.1,0.1,0.2,0.2c0.3,0.2,0.6,0.4,0.7,0.8c0,0.3,0,0.7-0.3,1
		C40.6,27.7,40.2,27.6,40,27.4z M36.8,31.2c-0.3,0.4-0.9,0.8-1.5,0.8c-0.3-0.1-0.6-0.1-0.8-0.4c0-0.2-0.3-0.3-0.2-0.6
		c-0.2-0.5-0.4-1-0.3-1.6c-0.1-0.6,0.1-1.3,0.2-1.9c0.1-0.1,0.2-0.4,0.3-0.2c0,0.6-0.2,1.1,0.1,1.6c0.1,0,0.1,0.1,0.2,0.1
		c0.2-0.1,0-0.4,0.1-0.6c0.1-0.1,0.1,0,0.2,0.1c0.1,0.6,0,1.3,0.3,1.8c0.1,0.1,0.2,0.2,0.3,0.1c0.1-0.1,0-0.1,0.1-0.2
		c-0.3-0.8-0.3-1.8,0-2.6c0.1,0,0.1,0,0.1,0.1c0.1,0.5-0.1,1.1,0.1,1.5c0.2-0.1,0.2-0.3,0.3-0.5c0.1-0.5,0-1.1,0.1-1.6
		c0.1,0,0,0.1,0.1,0.1C36.8,28.5,37.6,30,36.8,31.2z M34.4,19.9c0-0.1,0.1-0.3-0.1-0.3c-0.1,0-0.3,0.1-0.4,0.1c0,0-0.1-0.1-0.1-0.1
		c0,0,0,0,0-0.1c0.2-0.1,0.5-0.3,0.7-0.4c0.1-0.1,0.2-0.2,0.3-0.1C34.9,19.3,34.7,19.6,34.4,19.9C34.4,20,34.5,19.9,34.4,19.9z
		 M33.7,19c-0.2,0-0.4,0.1-0.6-0.1c0.1-0.2,0.3-0.1,0.4-0.2c0.3-0.1,0.5,0.2,0.7,0.2c0,0,0,0,0,0.1C34.1,19.1,33.9,19,33.7,19z
		 M34.4,17.4c-0.1,0-0.1-0.2-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0.1-0.2,0.3-0.3,0.5l0,0.1c-0.2,0-0.2-0.2-0.4-0.3
		c-0.1-0.3,0.2-0.5,0.3-0.7c0.2,0,0.5,0.1,0.6,0.3C34.3,17,34.4,17.2,34.4,17.4z M33.5,18.1c-0.1,0.3,0.3,0.2,0.4,0.3l0,0.1
		c0,0.1-0.2,0.1-0.3,0.1c-0.3-0.1-0.6,0-0.8,0.1c-0.1,0-0.2,0-0.3-0.1c0-0.2,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2
		c-0.3-0.2-0.7,0-1,0c-0.2,0.1-0.5,0.1-0.7,0c0,0,0-0.1,0.1-0.1c0.3,0.1,0.5-0.2,0.8-0.2c0.2-0.1,0.5,0,0.8,0
		C33.3,18,33.4,18,33.5,18.1z M32.8,17.5c-0.5,0-0.9,0.3-1.5,0.2c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.1-0.2,0.4-0.1,0.5-0.2
		c0.1,0,0.3,0,0.4-0.1c0.3,0,0.7,0,0.9,0.3l0,0.1C33,17.6,32.9,17.5,32.8,17.5z M31.4,18.9c0.6-0.2,1.2,0,1.6,0.3
		c0.4,0.5,0.5,1.3,0.2,1.9c-0.2,0.4-0.5,0.9-1,0.9c-0.6,0.3-1.2,0-1.7-0.4c-0.3-0.4-0.6-0.9-0.4-1.4C30.3,19.7,30.8,19.1,31.4,18.9z
		 M29.5,28.7C29.6,28.7,29.6,28.7,29.5,28.7c0,1.2,0.1,2.3,0.2,3.6c0.1,1.1,0.1,2.2,0.2,3.2c0,0.2-0.2,0.3-0.3,0.5
		c-0.1,0.1-0.2,0.3-0.4,0.4C29,36,29.1,35.6,29,35.2c-0.1-1.7-0.3-3.6-0.3-5.4c0.1-0.2,0.2-0.4,0.3-0.7C29.2,29,29.4,28.9,29.5,28.7
		z M30.1,35.8L30.1,35.8c0.3-0.3,0.6-0.6,0.8-0.8c0,0,0.1,0,0.1,0c-0.1,0.5-0.2,1-0.6,1.5c0,0.1,0,0.1,0.1,0.1
		c0.2-0.3,0.4-0.5,0.7-0.8c0.2-0.2,0.3-0.4,0.5-0.6c0.1,0.2,0,0.4,0,0.5c-0.1,0.8-0.4,1.5-1.2,1.9c-0.3,0-0.4,0.2-0.7,0.1
		c-0.2,0-0.4-0.2-0.5-0.4C29.2,36.7,29.6,36.2,30.1,35.8z M29.7,38.1L30,38c0,0,0.1,0,0.1,0.1c0.1,0.9,0.1,1.8,0.2,2.7
		c0,0.7,0.1,1.2,0.2,1.9c0.1,0.4,0,0.9,0.2,1.3c0.2,0.4-0.1,0.6-0.2,0.9c-0.3,0,0-0.4-0.2-0.6c-0.3-1.2-0.6-2.3-0.7-3.6
		c-0.1-0.6-0.1-1.2-0.2-1.8c-0.1-0.3,0-0.6-0.1-0.9C29.4,37.9,29.5,38.1,29.7,38.1z M29.4,46.6c0-0.4,0.1-0.8,0.3-1.1
		c0-0.2,0.2-0.2,0.4-0.3c0.2,0,0.4-0.2,0.6,0c0,0.1,0.2,0.2,0.1,0.4c-0.1,0.2-0.2,0.4-0.5,0.6c-0.1,0-0.3-0.1-0.3,0.1
		c0,0.3,0.4,0.1,0.5,0.3c0,0.2,0.1,0.5,0,0.7c-0.2,0-0.5,0-0.7,0.1c-0.1,0.1-0.2,0.2-0.3,0.1C29.3,47.3,29.3,46.9,29.4,46.6z
		 M29.9,47.6c0.2-0.1,0.4-0.1,0.6,0c0,0.2-0.1,0.5-0.1,0.7c0,0.3,0.2,0.6,0.5,0.7v0c-0.2,0.1-0.4-0.1-0.6-0.2
		c-0.3-0.2-0.3-0.5-0.4-0.7C29.9,48,29.7,47.8,29.9,47.6z M31.9,47.8c0.2,0,0.4,0,0.6,0.2c0,0.2-0.1,0.4-0.1,0.7
		c0,0.3,0,0.6,0.2,0.8l0,0.1c-0.2,0-0.4-0.2-0.5-0.3c-0.3-0.3-0.6-0.6-0.6-1C31.5,48.1,31.7,47.9,31.9,47.8z M41.3,47.6
		c-0.1,0,0-0.1,0-0.2c0.2-0.3,0.5-0.1,0.8-0.2l0,0c0.1,0.3-0.3,0.5-0.4,0.8c-0.1,0.1-0.1,0.2-0.1,0.2c-0.2,0.2-0.3,0.4-0.5,0.6
		l-0.1,0C41.1,48.5,41.5,48.1,41.3,47.6z M42.2,46.9C42.2,47,42.1,47,42,47c-0.3-0.1-0.5,0.1-0.8,0.1c-0.1-0.2-0.2-0.5-0.2-0.7
		c0.2-0.2,0.4-0.3,0.5-0.5c0-0.1,0-0.1-0.1-0.1c-0.2,0-0.4,0.4-0.6,0.1c-0.1-0.2-0.2-0.5-0.3-0.8c0.2-0.1,0.4,0,0.5,0.1l0.3,0.1
		c0.5,0.1,0.5,0.7,0.8,1C42.2,46.4,42.5,46.7,42.2,46.9z M41.1,36.8c-0.6,0.1-1.2-0.2-1.6-0.6c-0.2-0.3-0.4-0.6-0.4-1
		c0-0.1,0.1-0.1,0.2-0.1c0.3,0.3,0.7,0.6,1.1,0.7l0.1-0.1c0.1,0,0.1,0.2,0.2,0.1c0,0,0.1-0.1,0-0.1c-0.5-0.4-0.8-0.9-0.9-1.4
		c0-0.1,0.1-0.1,0.2-0.1c0.5,0.3,1,0.7,1.5,1.1c0.2,0.3,0.4,0.6,0.3,0.9C41.5,36.5,41.4,36.7,41.1,36.8z M41.4,27c0-0.1,0.1,0,0.1,0
		c0.2,0.2,0.3,0.4,0.5,0.6c0.4,0.3,0.7,0.7,1.2,0.8c0.1,0.2,0.4,0.2,0.5,0.3c0,0.2-0.1,0.4-0.3,0.4c-0.6,0-0.9-0.4-1.3-0.8
		C41.8,28,41.6,27.5,41.4,27z M43.7,26.1c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.2-0.6-0.3-1c0-0.3-0.1-0.6,0.1-0.8c0.1,0,0.1,0.1,0.1,0.1
		C43.5,24.9,43.8,25.5,43.7,26.1z M43.4,22.2c0,0.1-0.1,0-0.2,0c-0.1-0.2-0.4-0.3-0.5-0.6c0-0.1-0.2-0.1-0.2,0c0,0-0.1,0.1,0,0.1
		c0.3,0.4,0.4,0.8,0.7,1.2c0,0.2-0.2,0.4-0.3,0.6c0,0-0.1,0-0.1,0c-0.2-0.1-0.2-0.3-0.3-0.5c0-0.1-0.1-0.3-0.1-0.4
		c0.1-0.4-0.3-0.6-0.4-0.9c-0.2-0.2-0.5-0.2-0.6-0.4c0-0.1,0-0.2,0.1-0.2c0.3-0.3,0.6,0.1,0.9-0.1c0.1,0.1,0.3,0,0.4,0
		c0.2,0.1,0.4,0.3,0.6,0.3c0.2,0.1,0.2,0.3,0.2,0.5C43.4,22,43.3,22.1,43.4,22.2z M42.8,18.6c0,0.1-0.1,0.3,0,0.4
		c0.1,0.1,0.3,0.1,0.3,0.3c0,0.1,0,0.2-0.1,0.2c-0.3,0-0.6,0.1-0.8,0.4c0,0.1,0,0.1,0.1,0.1c0.2,0,0.2-0.3,0.5-0.3
		c0.2,0,0.3-0.1,0.5,0c0,0.2,0.2,0.3,0.2,0.4c-0.1,0.3,0.1,0.7-0.1,1c-0.4,0-0.7-0.4-1.1-0.1c-0.2,0-0.5-0.2-0.7-0.1
		c-0.2-0.1-0.3-0.4-0.2-0.6c0.1-0.2,0.3-0.1,0.4-0.3c0.1,0,0.3,0.1,0.3,0c0-0.2-0.3-0.1-0.4-0.1c-0.1,0-0.2,0.1-0.4,0.1
		c-0.1-0.1-0.1-0.2-0.1-0.4c0.2-0.4,0.7-0.5,1-0.6c0,0,0.1-0.1,0-0.1c-0.4-0.3-0.9,0.1-1.2,0.3c-0.1,0-0.1,0-0.1-0.1
		c0.1-0.3,0.2-0.6,0.5-0.7c0,0,0.1-0.1,0.1-0.1c-0.1-0.1-0.2,0-0.3,0c-0.1,0.1-0.3,0.3-0.5,0.4c0-0.2,0.3-0.4,0.4-0.7
		c0,0,0-0.1-0.1-0.1c-0.3,0.2-0.5,0.5-0.9,0.7c-0.1,0-0.2,0-0.3,0c-0.1,0,0-0.1,0-0.2c0.2-0.3,0.5-0.6,0.6-0.9
		c0.2-0.6,0.9-0.5,1.3-0.9c0.2,0.1,0.4-0.1,0.5,0.1c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0.2-0.6,0.3-0.7,0.6c0.2,0.1,0.2-0.1,0.4-0.2
		c0.1-0.1,0.2-0.2,0.3-0.1c0.2,0.2,0.3-0.3,0.4,0.1c-0.1,0.2-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.3-0.3,0.5c0,0.1,0.1,0,0.2,0
		c0.2-0.2,0.4-0.4,0.7-0.4C42.7,17.9,42.9,18.2,42.8,18.6z M39.3,15.6c0.2-0.3,0.6-0.2,0.8-0.4c0.7-0.2,1.4,0.1,2-0.4l0.7-0.5
		c0.3,0,0.4-0.3,0.6-0.4c0.1,0,0.2,0,0.2,0.1c-0.1,0.3-0.2,0.6-0.3,1c-0.1,0.5-0.6,0.6-1,0.9c-0.2,0.2-0.5,0.2-0.8,0.2
		c-0.6,0-1.1,0.3-1.4,0.8c0.1,0.2-0.1,0.3-0.2,0.3c-0.1,0.1-0.3,0.1-0.4,0.2c0.1,0.1,0.2,0,0.2,0.2c-0.3,0.3-0.8,0-1.1,0.3
		c0,0-0.1,0.1-0.1,0.1c0.1,0.1,0.3,0,0.3,0.1c-0.2,0.2-0.6,0.1-0.8,0.3c0,0.1,0,0.1,0.1,0.1c0.1,0,0.2-0.1,0.3,0
		c0,0.1-0.1,0.1-0.2,0.2c-0.3,0.1-0.6,0-0.8,0.2c0.1,0.1,0.3,0.1,0.4,0.2c-0.3,0.2-0.7,0.2-0.9,0.4c-0.2,0.1,0.1,0.7-0.3,0.5
		c-0.2-0.2-0.3-0.4-0.4-0.7c0.1-0.1,0-0.4,0.1-0.3c0.2,0.1,0.2,0.3,0.3,0.4c0.1,0,0.2-0.1,0.3-0.2c-0.2-0.3,0.3-0.3,0.4-0.5
		c0-0.3,0.3-0.2,0.4-0.3c0.3-0.1,0.5-0.1,0.8-0.2c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0-0.1c0.3-0.4,0.8-0.3,1.2-0.4
		c0-0.1-0.2-0.1-0.1-0.2c0.3-0.2,0.8-0.1,0.9-0.5c0-0.1,0.1-0.2,0-0.2c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.6,0.4
		c0-0.1,0.1-0.2,0.2-0.3c0.3-0.3,0.5-0.7,0.7-1.2c0-0.1-0.1,0-0.2,0c-0.3,0.3-0.7,0.5-1,0.8c-0.1,0-0.2,0.1-0.2,0
		c-0.1-0.2,0.2-0.2,0.2-0.4C39,16.1,39.1,15.8,39.3,15.6z M36.1,17.4C36,17.3,36,17.1,36,16.9c0,0-0.1,0-0.1,0
		c-0.1,0.1-0.2,0.3-0.2,0.4c0,0-0.1,0-0.1,0c-0.1-0.3,0-0.6,0.1-0.8c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.2,0.2,0.3,0.3
		C36.2,16.9,36.3,17.2,36.1,17.4z M37.1,17c-0.1-0.1-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0-0.1,0-0.1-0.1
		c0-0.3,0.1-0.5,0.2-0.8c0.1-0.2,0.4-0.2,0.5-0.4c0.1-0.1,0.4-0.2,0.4,0.1c0,0.2,0.1,0.4,0.2,0.6c0,0.2-0.2,0.4-0.2,0.6
		c0,0-0.1,0.1-0.2,0.1C37.3,17.4,37.1,17.2,37.1,17z M37.3,14.7c0-0.3,0.3-0.2,0.5-0.3c0.3,0,0.7,0,0.9,0.3c0.1,0.1,0.1,0.2,0.1,0.4
		c-0.2,0.1-0.1,0.4-0.3,0.5c-0.3-0.1-0.2-0.4-0.4-0.6c-0.3-0.2-0.5,0.1-0.8,0.2C37.2,15,37.3,14.9,37.3,14.7z M37.7,15.3
		c0.1,0,0.1,0,0.2,0.1c0,0.4,0.5,0.7,0.1,1c-0.3,0-0.2-0.3-0.3-0.5c-0.2-0.2-0.4,0.2-0.6,0.1c-0.1,0-0.2-0.1-0.1-0.2
		C36.9,15.5,37.4,15.4,37.7,15.3z M35.5,14.7c0.2-0.4,0.6-0.4,1-0.4c0.2,0.2,0.2,0.4,0.4,0.5c0,0.1,0,0.3-0.1,0.4
		c-0.1,0-0.2-0.2-0.3-0.2c-0.2-0.1-0.4-0.2-0.6-0.1c-0.1,0-0.2,0.2-0.3,0.1C35.5,14.9,35.5,14.8,35.5,14.7z M36,15.2
		c0.2-0.1,0.3,0.2,0.4,0.4c0,0.1,0.3,0.2,0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2-0.1-0.3-0.4-0.5-0.3c-0.1,0.1-0.2,0.2-0.3,0.2
		c-0.1-0.1-0.1-0.3-0.1-0.4C35.6,15.5,35.8,15.3,36,15.2z M35.4,19.1c0.2-0.1,0.4,0.1,0.5-0.1l0,0c0,0.2,0.2,0.5,0.1,0.7
		c0,0.1,0.1,0.2,0.1,0.4c-0.1,0-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.1,0.5-0.3,0.2c-0.1-0.2,0.1-0.4-0.1-0.5
		c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0-0.2-0.1-0.2-0.2c0.3-0.1-0.1-0.5,0.3-0.6C35.3,19,35.4,19.1,35.4,19.1z M35.2,17.1
		c-0.1,0-0.1-0.1-0.1-0.2c0-0.1-0.1-0.2-0.2-0.1c-0.1,0.1-0.1,0.3-0.1,0.4l0,0c-0.2-0.2-0.3-0.4-0.2-0.7c0-0.2,0.2-0.3,0.3-0.4
		c0.1,0,0.2,0.1,0.3,0.2C35.3,16.6,35.3,16.9,35.2,17.1z M34.5,15.9c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.1
		c-0.1-0.1,0-0.2,0-0.3c0.1-0.2,0.2-0.5,0.4-0.6c0.2,0,0.4,0.3,0.6,0.3c0.1,0.1,0.1,0.2,0.1,0.3C35,15.9,34.6,15.6,34.5,15.9z
		 M34.5,14.3c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.3,0.3,0.5c0,0.1,0,0.2-0.1,0.2c-0.4,0.1-0.4-0.4-0.8-0.3c-0.1,0.1-0.3,0.3-0.4,0.5
		c-0.1,0-0.1,0.1-0.2,0.1c-0.1-0.1-0.2-0.2-0.2-0.3C33.8,14.6,34.1,14.3,34.5,14.3z M33.7,15.8c0,0.1,0.1,0.3,0,0.4
		c-0.2,0-0.5,0-0.6,0.3c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1-0.1-0.2-0.2-0.3-0.4c0.1-0.2,0.2-0.5,0.3-0.7c0,0,0.1-0.1,0.1-0.1
		C33.3,15.8,33.5,15.9,33.7,15.8z M32.4,14.6c0.2,0,0.4,0,0.6,0.2c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0,0.1,0.1,0.1,0.2
		c-0.1,0.1-0.2,0-0.3,0c-0.2,0-0.5-0.3-0.7,0.1c-0.1,0.1-0.1,0.3-0.2,0.3c-0.1-0.1-0.2-0.3-0.2-0.5C32.1,15,32.2,14.8,32.4,14.6z
		 M27.3,14.7c0-0.3,0-0.5,0-0.8c0,0,0.1-0.1,0.1-0.1c0.3-0.1,0.2,0.3,0.4,0.4c0.1,0.3,0.4,0.6,0.7,0.6c0.6,0,1.2-0.1,1.8,0.1
		c0.4,0.1,0.8,0.4,1.1,0.8c0.1,0,0.1-0.1,0.2-0.1c0.1,0.1,0.4,0.2,0.3,0.4c0.3,0,0.4,0.4,0.7,0.6c-0.2,0.2,0.5,0.5-0.1,0.5
		c-0.4-0.2-0.8,0.1-1.2,0.1c-0.1,0-0.2,0-0.3-0.1l0-0.1c0.2-0.2,0.5-0.3,0.8-0.3c0.1,0,0-0.2,0.2-0.1c0.1,0.1,0.1,0.2,0.2,0.2
		c0,0,0-0.1,0-0.1c-0.2,0-0.2-0.3-0.4-0.3c-0.2,0-0.3,0-0.4-0.2c-0.1-0.3-0.4-0.4-0.6-0.5c-0.1-0.1-0.2-0.1-0.3-0.1
		c-0.1,0.1-0.1,0.1-0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.8v0c-0.3,0-0.5-0.4-0.7-0.3c0,0-0.1,0.1,0,0.2c0.1,0.2,0.4,0.4,0.2,0.6
		c-0.2,0-0.3-0.2-0.4-0.3c0-0.3-0.3-0.3-0.5-0.4c-0.2-0.1-0.4,0-0.7,0c-0.2-0.1-0.4,0.1-0.6-0.1C27.8,16,27.3,15.4,27.3,14.7z
		 M27.1,25.9c0.1-0.4,0-0.8,0.3-1.1c0.2,0,0.1,0.3,0.1,0.4c0.1,0.3,0.1,0.7,0.2,1c-0.1,0.2-0.3,0.3-0.5,0.3
		C27.1,26.3,27.1,26.1,27.1,25.9z M28.8,25.3c0.1-0.1,0.2-0.1,0.2,0c0,0.3-0.1,0.6-0.2,0.9c-0.3,0.4-0.4,0.9-0.7,1.3
		c-0.2,0.1-0.4,0.4-0.7,0.4c-0.1-0.2-0.3-0.4-0.3-0.7C27.7,26.6,28.4,26,28.8,25.3z M27.6,29.1c-0.1,0.1-0.2,0.2-0.4,0.1
		c0-0.2-0.1-0.6,0.1-0.7c0.1-0.1,0.3-0.3,0.4-0.1C27.8,28.6,27.9,28.9,27.6,29.1z M28,28.9c0.3-0.4,0.7-0.7,0.9-1.2
		c0,0,0.1-0.1,0.1-0.1c-0.1,0.8-0.4,1.6-0.9,2.2c-0.2,0.3-0.6,0.4-0.9,0.5c-0.1,0-0.2-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.3
		C27.3,29.6,27.7,29.3,28,28.9z M25.9,28.5c0-0.3,0.1-0.5,0.4-0.7c0.1-0.1,0.3-0.2,0.5-0.2l0.2,0.3c0,0.4-0.4,0.5-0.7,0.8
		c-0.1,0-0.1,0.1-0.2,0.1C25.9,28.8,25.9,28.6,25.9,28.5z M26.2,32.4c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.3-0.2-0.5
		c0.1-0.2,0.4-0.3,0.5-0.5c0.3-0.2,0.5-0.5,0.8-0.8c0.1,0,0.3-0.1,0.4,0C27.1,31.2,26.7,31.9,26.2,32.4z M27.9,41.8
		c-0.4-1.3-0.8-2.6-1-4c-0.2-1.2-0.3-2.4-0.5-3.6c0.1-0.5,0-1.1,0-1.6c0.1-0.2,0.3-0.3,0.5-0.4c0,0,0.1,0,0.1,0.1
		c0,0.9,0.2,1.7,0.2,2.6c0.1,0.7,0.2,1.4,0.2,2c0,0.7,0.1,1.4,0.1,2.1c0.1,0.8,0.2,1.5,0.3,2.2C27.9,41.4,27.9,41.6,27.9,41.8
		L27.9,41.8z M28.2,40.8c-0.1-0.6-0.1-1.3-0.2-1.9c-0.2-1.2-0.2-2.5-0.3-3.7c-0.1-0.3-0.1-0.7-0.1-1.1c-0.1-0.8-0.1-1.6-0.2-2.3
		c-0.1-0.5,0.1-0.9,0.2-1.2c0.1-0.1,0.3-0.3,0.4-0.2c0.1,0.8,0,1.6,0.1,2.4c0.1,0.3,0.1,0.7,0.1,1c0.1,1.3,0.1,2.5,0.3,3.7
		c0.2,1.7,0.4,3.4,0.6,5.2c0.1,0.6,0.2,1.2,0.3,1.9c0,0-0.1,0.1-0.1,0C28.7,43.4,28.4,42.2,28.2,40.8z M42.3,41.2
		c-0.2,0.9-0.3,1.8-0.6,2.7c0,0.3-0.1,0.6-0.2,0.8c0,0-0.1,0-0.1,0c0-0.7,0.3-1.2,0.3-1.8c0.1-0.5,0.1-1,0.2-1.5
		c0.1-0.7,0.1-1.3,0.1-2c0.1-0.2,0.1-0.5,0.1-0.7c-0.1-0.5,0.1-1,0.1-1.5c0.1-0.5,0.2-1.1,0.2-1.6c0.1-1,0.2-1.9,0.2-2.9
		c0.1-0.3,0-0.7,0.1-0.9c0-0.3,0-0.5,0-0.8c0.1-0.2-0.2-0.7,0.2-0.8c0.1,0.1,0.3,0.2,0.4,0.3c0,0.3-0.1,0.6-0.1,0.9
		c0,0.5-0.1,1-0.1,1.6c0,0.8,0,1.5-0.1,2.2c-0.1,0.9-0.2,1.8-0.3,2.6C42.8,38.9,42.5,40,42.3,41.2z M44.5,31.7
		c-0.1,0.6,0,1.1-0.1,1.7c0,0.5-0.2,1-0.2,1.5c0,0.8-0.3,1.5-0.3,2.3c-0.1,0.8-0.3,1.5-0.5,2.3c-0.2,0.9-0.5,1.8-0.8,2.6
		c0,0-0.1,0-0.1,0c0.1-0.8,0.3-1.7,0.4-2.5c0.2-1,0.4-2.1,0.6-3.1c0.1-0.8,0.1-1.6,0.2-2.4c0.1-1,0.2-2,0.3-2.9
		c0.1-0.1,0.3,0.1,0.4,0.1C44.5,31.4,44.5,31.6,44.5,31.7z M45.1,31.2c-0.5-0.4-1-0.7-1.3-1.2c-0.1-0.1-0.2-0.3-0.2-0.5
		c0.1-0.1,0.1-0.2,0.2-0.1c0.2,0,0.3,0.3,0.6,0.3c0.1,0.1,0.1,0.2,0.1,0.3c0.2,0.2,0.5,0.5,0.7,0.7c0.1,0.1,0.2,0.2,0.1,0.4
		C45.3,31.1,45.2,31.3,45.1,31.2z"/>
            <path d="M28,14.9c0.2,0.2,0.5,0.2,0.8,0.2c0.3,0.1,0.6,0,0.8,0.2c0.1,0.1,0.3,0.2,0.3,0.5l-0.1,0c-0.6-0.3-1.3,0-1.8-0.5
		c-0.2,0-0.3-0.2-0.3-0.4c0-0.1,0-0.1,0-0.2C27.8,14.8,27.9,14.9,28,14.9z"/>
            <path d="M43.2,14.8c0,0.1-0.1,0.3-0.3,0.4c-0.5,0.3-1.2,0.5-1.9,0.4c0,0,0-0.1,0-0.1c0.4-0.1,0.9-0.2,1.3-0.4
		C42.6,15,42.9,14.9,43.2,14.8L43.2,14.8z"/>
            <path d="M31.1,19.7c-0.1,0-0.8,1.1-0.1,1.7c0.9,0.8,2-0.6,1.8-0.9c-0.1-0.1-0.4,0.8-1.2,0.6C30.6,20.9,31.1,19.8,31.1,19.7z"/>
            <path d="M38.3,21.2c0,0.3,1.1,0.8,1.7,0.1c0.7-1.1-0.3-1.7-0.6-1.4c-0.1,0.1,0.6,0.4,0.3,1.1C39.2,21.7,38.3,21,38.3,21.2z"/>
        </g>
    </svg>
);

const kmdLogoNorwegian = (
     <svg x="0px" y="0px" viewBox="0 0 904.7 198.4">
         <title>Universitetet i Bergen - Fakultet for kunst, musikk og design</title>
         <g>
             <g>
                 <path d="M290.8,116.6l0.7,67.5l-9.9-15.5V92.3h-17.2V34.1c3,5.3,5,11.3,6.3,18l10.4-1.5c-2.5-12.8-7.7-24.1-16.7-32.7v-7l7.9-8.4
			h-19.8l-2.3,5.6c-1.6-0.8-3.3-1.5-5.1-2.2l3.1-3.4h-14.5c-7.3-1.6-15.6-2.5-25-2.5c-2.9,0-5.8,0.1-8.6,0.3
			c-3.4-0.2-6.9-0.3-10.6-0.3c-5.1,0-10.1,0.3-14.9,1c-5.5-0.7-11.6-1-18.2-1c-4.2,0-8.3,0.2-12.3,0.7c-4.7-0.4-9.7-0.7-15.1-0.7
			c-7.8,0-15.6,0.8-22.8,2.5l-50.4,0v15.4L45.8,2.5H33.7V95l-2.4,2.6V2.5H21.1v106.2L9.9,120.8V2.5H0v193.5h9.9v-62l11.2-12v9.7
			c0,17.9,3.7,32.9,12.6,44.2v20h9.7v-10.9c12,8.6,29.4,13.4,53.9,13.4c5.6,0,10.7-0.3,15.6-0.7c4.9,0.5,10.2,0.7,16,0.7
			c0.6,0,1.2,0,1.7,0c0.5,0,1,0,1.5,0c4.1,0,8.1-0.2,11.8-0.4c3.9,0.3,8,0.4,12.3,0.4c5.1,0,10.2-0.3,15.2-0.9
			c5.5,0.6,11.4,0.9,18,0.9c3.3,0,6.6-0.1,9.9-0.4c3.1,0.2,6.2,0.4,9.5,0.4c12,0,22.7-1.2,32.3-3.7l1,1.3h22.4v-2l1.7,2h13.1
			l-11.6-14c2.6-1.9,5-4,7.4-6.3l13.3,20.2h12.2V2.5h-9.7V116.6z M33.7,153.9c-1.7-6.2-2.4-13-2.4-20.4v-22.3l2.4-2.6V153.9z
			 M88,149l1.9,2.3l-1.9,0.3V149z M42.6,14.4l13.3,20.8v35.6L43.4,84.5v-2.6L42.6,14.4z M55.9,84.6v10.4l-4.6-5.5L55.9,84.6z
			 M78.1,188c-16.2-2.3-27.4-7.9-34.7-15.9V98.1l1.5-1.6l11,13.4v21.8c0,7.3,0.6,14.1,1.9,20.3l0,0c2,14.6,7.6,26.9,20.3,35.1V188z
			 M78.1,171.9c-8.7-9.6-12-22.8-12-38.5v-11.1l12,14.6V171.9z M78.1,121.7l-12-14.4V73.8l2.1-2.3c2.3,5.1,5.6,9.2,9.9,12.6V121.7z
			 M78.1,46.7l-1.9,2.1l-1-1.6c0.4-4.8,1.4-9.1,2.9-12.8V46.7z M78.1,16.8c-5,4.8-8.8,10.8-11.1,18l-0.9-1.4v-22h12V16.8z
			 M90.4,181.2c-0.8-0.4-1.6-0.9-2.4-1.3v-15.7c0.7,2,1.5,4,2.4,5.8V181.2z M90.4,136.6l-2.4-2.9V89.8c0.8,0.3,1.6,0.7,2.4,1V136.6z
			 M90.4,70.5L88,66.9V50.3l2.4-2.6V70.5z M90.4,33.4L88,36V21c0.8-0.6,1.6-1.3,2.4-1.8V33.4z M110.6,179.4v-2.8l4.6,5.6
			C113.6,181.3,112,180.4,110.6,179.4z M115.6,167l-4.9-6v-41.1l4.9,7.6V167z M115.6,109.1l-4.9-7.6v-0.3h4.9V109.1z M115.6,79.3
			c-1.9-1.1-3.5-2.3-4.9-3.6V47.2l4.9,12V79.3z M115.6,34.7l-4-9.7l3.6-3.9c0.1-0.1,0.3-0.2,0.4-0.3V34.7z M127.7,181.6l-2.2-2.7
			v-5.3c0.7,1.1,1.4,2.3,2.2,3.3V181.6z M127.7,150.7l-2.2,0.3v-8.2l2.2,3.4V150.7z M127.7,118.4l-2.2,2.4v-19.6h1.4
			c0.1,5.7,0.3,11.2,0.8,16.5V118.4z M127.7,34.2c-1,3.1-1.8,6.5-2.2,10.2V14.8c0.7-0.3,1.5-0.6,2.2-0.9V34.2z M139.7,175.9
			c-0.7-0.7-1.4-1.4-2-2.2v-12.2l2,3.1V175.9z M139.7,129c-0.5-2.9-0.9-5.8-1.3-8.9l1.3-1.3V129z M139.7,86.9
			c-0.7-0.1-1.3-0.2-2-0.4c0.1-1,0.1-2.1,0.2-3.1c0.6,0.5,1.2,0.9,1.8,1.4V86.9z M139.7,37.3c-0.7,1.3-1.4,2.6-2,4v-4.1
			c0.6-1.7,1.2-3.4,2-4.9V37.3z M139.7,15.9c-0.7,0.6-1.4,1.3-2,1.9v-6.4h2V15.9z M149.6,20.2c2.7-2.1,5.6-3.8,8.7-5.2
			c-3.1,2.4-6,5.1-8.7,8V20.2z M155.1,166.8c-2-2.6-3.9-5.3-5.5-8.3v-16.8l8.3,20.1C157.1,163.5,156.1,165.2,155.1,166.8z
			 M161.3,145.6l-2-5l-4.9-11.9l6.9-7.4V145.6z M161.3,108l-10.7,11.6l-1.1-2.6v-8.9l5.3-5.6c2.3,0.5,4.5,1.1,6.5,1.7V108z
			 M161.3,81.9l-1.8,1.9c-3.9-1.2-7.2-2.5-10-4.1V40.2c3.2-5.7,7.1-10.8,11.7-15V81.9z M233,18.8c2.2,1.5,4.1,3.2,5.8,5l-6.5,7.1
			c-1.5-2.2-3.1-4.2-4.8-6.1L233,18.8z M237.6,92.3h-5.3l-4-6.2l7.4-17.9C236.7,75.4,237.3,83.4,237.6,92.3z M227.1,50.9l3.2-3.4
			c0.6,1.6,1.2,3.2,1.7,4.9l-4.9,11.9V50.9z M227.1,106c1.2,0.4,2.3,0.9,3.3,1.4l6.6,10.1c-0.7,8.7-1.8,16.4-3.5,23.2l-6.4-7.7V106z
			 M208.2,26.7c0.6,0.8,1.1,1.6,1.6,2.4l-1.6,1.8V26.7z M208.2,45.4l5.9-6.3c0.6,2.5,1.3,6.1,1.8,9.6l-7.1,7.7l-0.6-0.9V45.4z
			 M208.2,101.2h0.8c0.9,0.2,1.8,0.3,2.6,0.5l-0.9,2.2c-0.8-0.6-1.6-1.1-2.5-1.7V101.2z M208.2,134.7l2.6-6.3l5.9,7.2
			c0.3,2.7,0.4,5.6,0.4,8.8c0,1.8-0.1,3.5-0.3,5.2L208,139C208.1,137.6,208.1,136.1,208.2,134.7z M183.4,12.7
			c2.6,0.4,5.1,0.8,7.5,1.4c2.6,1,5,2.2,7.1,3.5V40l-0.7-1.1c-2.3-10.3-7.2-19.1-15.6-25.6C182.3,13.1,182.8,12.9,183.4,12.7z
			 M173.4,19.4c0,0,0.1,0.1,0.1,0.1l18.5,29l-18.7,20.3V19.4z M173.4,82.6l24-25.7l0.6,1v10.2L179.8,88c-2.2-0.3-4.4-0.7-6.4-1V82.6
			z M192.3,89.5c-0.4,0-0.8-0.1-1.2-0.1L198,82v8.2C196.2,90,194.3,89.7,192.3,89.5z M198,112.9v14c-2.7-11.3-8.3-19.1-17.3-24.7
			c4.1,0.9,7.7,1.9,10.9,3L198,112.9z M174,177.1l-1.2-2.9V143c0.4-3.6,0.5-7.4,0.5-11.3v-19.5l15.1,18.4c0.8,4.1,1.1,8.6,1.1,13.7
			C189.6,159.6,183.3,170,174,177.1z M187.7,184.4l0.4-0.9c9.7-7.8,15.5-18.4,18.2-31.2l7.6,9.2
			C208.9,172.9,199.1,180.2,187.7,184.4z M217.2,185.6c-1.7,0.5-3.5,1-5.2,1.4h-2.4c2.7-1.3,5.2-2.8,7.6-4.4V185.6z M217.2,88.3
			l-1.7,4H212c-1.2-0.2-2.5-0.4-3.8-0.7V73.5l9,13.7V88.3z M217.2,69.2l-3-4.5l3-3.2V69.2z M217.2,15.8c-2.4-1.6-5-3.1-7.6-4.4h5.7
			c0.7,0.1,1.3,0.3,1.9,0.5V15.8z M227.1,181.6v-4l2.3,2.8C228.6,180.8,227.9,181.3,227.1,181.6z M227.1,158v-9.6c0,0,0,0,0-0.1
			l2.9,3.5C229.1,154,228.1,156.1,227.1,158z M237.5,174.6L232,168c1.7-2.4,3.3-5.1,4.7-7.9l6.5,7.9
			C241.6,170.5,239.6,172.6,237.5,174.6z M241.1,149.9c2-5.7,3.5-12,4.7-19l4.1,6.3c0.2,2.3,0.3,4.6,0.3,7.2c0,5.2-0.7,9.8-2.1,13.9
			L241.1,149.9z M243.7,182c2.2-1.9,4.2-3.9,6-6.1l3.4,4.1c-2.5,1.4-5.2,2.6-8,3.7L243.7,182z M254.8,195.4l-3.5-4.2
			c1.2-0.5,2.3-1,3.5-1.5V195.4z M254.8,116.7c-1.8-3.5-4.1-6.6-6.9-9.3c0.1-2,0.1-4.1,0.1-6.1h6.8V116.7z M254.8,81.9v10.4H248
			c-0.4-15.9-2.4-29.1-5.7-40.2l5.1-12.4c0.6,2.1,1,4.2,1.3,6.5l6.6-1L254.8,81.9z M255.4,31.1c-0.7-1.8-1.5-3.5-2.4-5.2l1.6-3.9
			c0.3,0.3,0.6,0.5,1,0.8L255.4,31.1z M264.5,172.2v-12.7l5.1,7.8C268,169,266.3,170.6,264.5,172.2z M271.7,153l-7.2-11.4v-40.4h7.2
			V153z"/>
             </g>
         </g>
         <g>
             <g>
                 <path d="M339.1,40.1V13.8h2.1v26.7c0,6.8,3.1,11.4,13.4,11.4s13.4-4.5,13.4-11.4V13.8h2.1v26.3c0,8.3-3.8,13.6-15.5,13.6
			S339.1,48.4,339.1,40.1z"/>
                 <path d="M379.7,31.5c1.7-3.2,5.5-5.6,9.9-5.6c5.2,0,9.2,2.9,9.2,7.9v19.4h-1.9V34.6c0-4.4-3.2-6.9-7.8-6.9c-5.3,0-8.3,4-9.4,6.7
			v18.9h-1.9V31.9l-0.3-5.5h1.5L379.7,31.5z"/>
                 <path d="M408.9,18.7h-3v-3h3V18.7z M406.4,26.4h1.9v26.8h-1.9V26.4z"/>
                 <path d="M426.4,53.7h-2.1l-10.5-27.3h2.3l5.7,15.4l3.5,9.8l3.5-9.8l5.8-15.4h2.2L426.4,53.7z"/>
                 <path d="M441.8,40.5c0.3,7,4.3,11.4,9.9,11.4c5.1,0,7.7-2.1,8.4-5.5l2.1,0.3c-0.8,4.1-4.3,6.9-10.6,6.9c-7.1,0-11.9-5.6-11.9-13.9
			c0-8.3,4.8-13.9,11.9-13.9c8.2,0,11,5.5,11,13.9v0.8H441.8z M441.8,38.8h18.7c-0.1-6.8-2.3-11.1-8.8-11.1
			C446.2,27.7,442.2,32,441.8,38.8z"/>
                 <path d="M482.9,26.1l-0.3,2c-0.5-0.1-1.2-0.3-2-0.3c-5.9,0-9.5,4.6-9.6,8.3v17h-1.9V31.9l-0.3-5.5h1.5l0.7,5.6
			c1.7-3.8,5.9-6.1,9.6-6.1C481.4,25.9,482.2,26,482.9,26.1z"/>
                 <path d="M488.6,33.6c0,3.8,2.6,4.1,8.4,4.8c4.9,0.6,10.6,1.3,10.6,7.2c0,5.7-5,8.1-11.3,8.1c-6.3,0-9.8-2.8-10.6-6.9l2.1-0.3
			c0.7,3.4,3.3,5.5,8.4,5.5s9.3-1.7,9.3-6.3c0-4.5-4-5-9.5-5.6c-4.3-0.5-9.5-1.1-9.5-6.5c0-5.5,4.7-7.7,9.9-7.7
			c6.3,0,9.8,2.8,10.6,6.9l-2.1,0.3c-0.7-3.4-3.4-5.5-8.5-5.5C493,27.7,488.6,29.2,488.6,33.6z"/>
                 <path d="M516.5,18.7h-3v-3h3V18.7z M514.1,26.4h1.9v26.8h-1.9V26.4z"/>
                 <path d="M537.7,51.4l0.4,1.8c-0.6,0.3-1.8,0.5-2.9,0.5c-7.9,0-8.4-5-8.4-10.5V28.1h-5.4v-1.7h5.4v-7.1l1.9-0.7v7.7h8.7v1.7h-8.7
			v16.4c0,6.2,2.4,7.3,6.5,7.3C536.5,51.9,537.4,51.6,537.7,51.4z"/>
                 <path d="M542.7,40.5c0.3,7,4.3,11.4,9.9,11.4c5.1,0,7.7-2.1,8.4-5.5l2.1,0.3c-0.8,4.1-4.3,6.9-10.6,6.9c-7.1,0-11.9-5.6-11.9-13.9
			c0-8.3,4.8-13.9,11.9-13.9c8.2,0,11,5.5,11,13.9v0.8H542.7z M542.7,38.8h18.7c-0.1-6.8-2.3-11.1-8.8-11.1
			C547.1,27.7,543.1,32,542.7,38.8z"/>
                 <path d="M583,51.4l0.4,1.8c-0.6,0.3-1.8,0.5-2.9,0.5c-7.9,0-8.4-5-8.4-10.5V28.1h-5.4v-1.7h5.4v-7.1l1.9-0.7v7.7h8.7v1.7h-8.7
			v16.4c0,6.2,2.4,7.3,6.5,7.3C581.8,51.9,582.7,51.6,583,51.4z"/>
                 <path d="M588,40.5c0.3,7,4.3,11.4,9.9,11.4c5.1,0,7.7-2.1,8.4-5.5l2.1,0.3c-0.8,4.1-4.3,6.9-10.6,6.9c-7.1,0-11.9-5.6-11.9-13.9
			c0-8.3,4.8-13.9,11.9-13.9c8.2,0,11,5.5,11,13.9v0.8H588z M588,38.8h18.7c-0.1-6.8-2.3-11.1-8.8-11.1
			C592.4,27.7,588.4,32,588,38.8z"/>
                 <path d="M628.4,51.4l0.4,1.8c-0.6,0.3-1.8,0.5-2.9,0.5c-7.9,0-8.4-5-8.4-10.5V28.1h-5.4v-1.7h5.4v-7.1l1.9-0.7v7.7h8.7v1.7h-8.7
			v16.4c0,6.2,2.4,7.3,6.5,7.3C627.1,51.9,628,51.6,628.4,51.4z"/>
                 <path d="M650.4,18.7h-3v-3h3V18.7z M647.9,26.4h1.9v26.8h-1.9V26.4z"/>
                 <path d="M672,53.2V13.8h15.2c6.5,0,10.8,1.5,10.8,10.2c0,5.6-3.2,8.2-5.4,8.8c3.3,0.7,6.7,3.6,6.7,9.6c0,9.2-5.1,10.8-11.4,10.8
			H672z M674,15.6v16.5h14.2c4.4,0,7.6-2.3,7.6-8.1c0-6.7-3-8.3-7.2-8.3H674z M688.8,51.4c4.2,0,8.3-1.4,8.3-9
			c0-7.2-4.6-8.5-8.3-8.5H674v17.5H688.8z"/>
                 <path d="M706.9,40.5c0.3,7,4.3,11.4,9.9,11.4c5.1,0,7.7-2.1,8.4-5.5l2.1,0.3c-0.8,4.1-4.3,6.9-10.6,6.9c-7.1,0-11.9-5.6-11.9-13.9
			c0-8.3,4.8-13.9,11.9-13.9c8.2,0,11,5.5,11,13.9v0.8H706.9z M706.9,38.8h18.7c-0.1-6.8-2.3-11.1-8.8-11.1
			C711.3,27.7,707.3,32,706.9,38.8z"/>
                 <path d="M748,26.1l-0.3,2c-0.5-0.1-1.2-0.3-2-0.3c-5.9,0-9.5,4.6-9.6,8.3v17h-1.9V31.9l-0.3-5.5h1.5l0.7,5.6
			c1.7-3.8,5.9-6.1,9.6-6.1C746.5,25.9,747.3,26,748,26.1z"/>
                 <path d="M773.5,54.7c0,5.9-4.4,9.1-11.6,9.1c-7.4,0-9.6-3.5-10.4-7l2-0.3c0.7,3.3,2.9,5.7,8.3,5.7c5.4,0,9.7-2,9.7-7.2v-7.6
			c-1.9,3.6-6.2,5.3-9.9,5.3c-7,0-11.2-5.1-11.2-13.4s4.1-13.4,11.2-13.4c3.7,0,8,2,9.9,5.6l0.7-5.1h1.5l-0.3,5.5V54.7z M771.5,34.4
			c-1-2.7-3.8-6.7-9.8-6.7c-5.7,0-9.1,4.2-9.1,11.6c0,7.4,3.4,11.6,9.1,11.6c5.9,0,8.7-3.8,9.8-6.5V34.4z"/>
                 <path d="M781.8,40.5c0.3,7,4.3,11.4,9.9,11.4c5.1,0,7.7-2.1,8.4-5.5l2.1,0.3c-0.8,4.1-4.3,6.9-10.6,6.9c-7.1,0-11.9-5.6-11.9-13.9
			c0-8.3,4.8-13.9,11.9-13.9c8.2,0,11,5.5,11,13.9v0.8H781.8z M781.8,38.8h18.7c-0.1-6.8-2.3-11.1-8.8-11.1
			C786.2,27.7,782.2,32,781.8,38.8z"/>
                 <path d="M811,31.5c1.7-3.2,5.5-5.6,9.9-5.6c5.2,0,9.2,2.9,9.2,7.9v19.4h-1.9V34.6c0-4.4-3.2-6.9-7.8-6.9c-5.3,0-8.3,4-9.4,6.7
			v18.9h-1.9V31.9l-0.3-5.5h1.5L811,31.5z"/>
                 <path d="M341.9,123.4h-2V83.9h25.3v1.8h-23.3v16.5h21.2v1.8h-21.2V123.4z"/>
                 <path d="M378.3,108.2l9.7-1.5v-2.1c0-5.2-3-6.8-8.2-6.9c-4.8-0.1-7,1.8-7.7,5.1l-2-0.3c0.8-3.7,3.5-6.5,9.7-6.5
			c6.5,0,10.2,2.9,10.2,8.8v13l0.3,5.5h-1.5l-0.7-5.1c-2.2,4-6.7,5.6-10.7,5.6c-5.8,0-8.6-3.1-8.6-7.5
			C368.7,111.2,372.5,109.1,378.3,108.2z M377.3,122.1c6.1,0,9.3-3.1,10.7-6.7v-6.9l-10,1.6c-4.1,0.7-7.3,1.9-7.3,6.4
			C370.8,120.1,373.1,122.1,377.3,122.1z"/>
                 <path d="M397.3,123.4V82.9h1.9v28.7l6.3-6.3l8.7-8.7h2.8l-11.6,11.3l13.5,15.5h-2.6l-12.2-14.3l-4.9,4.9v9.4H397.3z"/>
                 <path d="M441.7,118.3c-1.7,3.2-5.5,5.6-9.9,5.6c-5.2,0-9.2-2.9-9.2-7.9V96.6h1.9v18.7c0,4.4,3.2,6.9,7.8,6.9c5.3,0,8.3-4,9.4-6.7
			V96.6h1.9v21.3l0.3,5.5h-1.5L441.7,118.3z"/>
                 <path d="M451.7,82.9h1.9v40.4h-1.9V82.9z"/>
                 <path d="M475.4,121.6l0.4,1.8c-0.6,0.3-1.8,0.5-2.9,0.5c-7.9,0-8.4-5-8.4-10.5V98.3h-5.4v-1.7h5.4v-7.1l1.9-0.7v7.7h8.7v1.7h-8.7
			v16.4c0,6.2,2.4,7.3,6.5,7.3C474.1,122.1,475,121.7,475.4,121.6z"/>
                 <path d="M480.4,110.7c0.3,7,4.3,11.4,9.9,11.4c5.1,0,7.7-2.1,8.4-5.5l2.1,0.3c-0.8,4.1-4.3,6.9-10.6,6.9
			c-7.1,0-11.9-5.6-11.9-13.9c0-8.3,4.8-13.9,11.9-13.9c8.2,0,11,5.5,11,13.9v0.8H480.4z M480.4,109h18.7
			c-0.1-6.8-2.3-11.1-8.8-11.1C484.7,97.8,480.8,102.2,480.4,109z"/>
                 <path d="M520.7,121.6l0.4,1.8c-0.6,0.3-1.8,0.5-2.9,0.5c-7.9,0-8.4-5-8.4-10.5V98.3h-5.4v-1.7h5.4v-7.1l1.9-0.7v7.7h8.7v1.7h-8.7
			v16.4c0,6.2,2.4,7.3,6.5,7.3C519.5,122.1,520.4,121.7,520.7,121.6z"/>
                 <path d="M544.4,91.6v5h7.7v1.7h-7.7v25.1h-1.9V98.3h-5.4v-1.7h5.4v-3.6c0-5.6,0.5-10.5,8.4-10.5c1.1,0,2.3,0.2,2.9,0.5l-0.4,1.8
			c-0.4-0.2-1.3-0.5-2.5-0.5C546.8,84.2,544.4,85.4,544.4,91.6z"/>
                 <path d="M554.9,110c0-8.7,4.3-13.9,11.9-13.9c7.6,0,11.9,5.2,11.9,13.9c0,8.7-4.3,13.9-11.9,13.9
			C559.3,123.9,554.9,118.7,554.9,110z M557.1,110c0,7.7,3.5,12.1,9.8,12.1c6.3,0,9.8-4.4,9.8-12.1c0-7.7-3.5-12.1-9.8-12.1
			C560.6,97.8,557.1,102.3,557.1,110z"/>
                 <path d="M598.9,96.3l-0.3,2c-0.5-0.1-1.2-0.3-2-0.3c-5.9,0-9.5,4.6-9.6,8.3v17h-1.9V102l-0.3-5.5h1.5l0.7,5.6
			c1.7-3.8,5.9-6.1,9.6-6.1C597.4,96.1,598.2,96.1,598.9,96.3z"/>
                 <path d="M616.3,123.4V82.9h1.9v28.7l6.3-6.3l8.7-8.7h2.8l-11.6,11.3l13.5,15.5h-2.6l-12.2-14.3l-4.9,4.9v9.4H616.3z"/>
                 <path d="M660.7,118.3c-1.7,3.2-5.5,5.6-9.9,5.6c-5.2,0-9.2-2.9-9.2-7.9V96.6h1.9v18.7c0,4.4,3.2,6.9,7.8,6.9c5.3,0,8.3-4,9.4-6.7
			V96.6h1.9v21.3l0.3,5.5h-1.5L660.7,118.3z"/>
                 <path d="M672,101.6c1.7-3.2,5.5-5.6,9.9-5.6c5.2,0,9.2,2.9,9.2,7.9v19.4h-1.9v-18.7c0-4.4-3.2-6.9-7.8-6.9c-5.3,0-8.3,4-9.4,6.7
			v18.9h-1.9V102l-0.3-5.5h1.5L672,101.6z"/>
                 <path d="M699.5,103.8c0,3.8,2.6,4.1,8.4,4.8c4.9,0.6,10.6,1.3,10.6,7.2c0,5.7-5,8.1-11.3,8.1c-6.3,0-9.8-2.8-10.6-6.9l2.1-0.3
			c0.7,3.4,3.3,5.5,8.4,5.5s9.3-1.7,9.3-6.3c0-4.5-4-5-9.5-5.6c-4.3-0.5-9.5-1.1-9.5-6.5c0-5.5,4.7-7.7,9.9-7.7
			c6.3,0,9.8,2.8,10.6,6.9l-2.1,0.3c-0.7-3.4-3.4-5.5-8.5-5.5C703.9,97.8,699.5,99.4,699.5,103.8z"/>
                 <path d="M737.9,121.6l0.4,1.8c-0.6,0.3-1.8,0.5-2.9,0.5c-7.9,0-8.4-5-8.4-10.5V98.3h-5.4v-1.7h5.4v-7.1l1.9-0.7v7.7h8.7v1.7h-8.7
			v16.4c0,6.2,2.4,7.3,6.5,7.3C736.6,122.1,737.5,121.7,737.9,121.6z"/>
                 <path d="M746.6,120.7l-3,10.9h-1.3l1.6-10.9H746.6z"/>
                 <path d="M340.7,158.8c1.7-3.2,5.3-5.6,9.1-5.6c4.1,0,7.1,2.1,7.9,5.8c2.2-3.9,5.9-5.8,9.4-5.8c4.7,0,8.2,2.9,8.2,7.9v19.4h-1.9
			v-18.7c0-4.4-2.7-6.9-6.8-6.9c-4.8,0-7.6,4-8.6,6.7v18.9h-1.9v-18.7c0-4.4-2.4-6.9-6.8-6.9c-4.8,0-7.6,4-8.6,6.7v18.9h-1.9v-21.3
			l-0.3-5.5h1.5L340.7,158.8z"/>
                 <path d="M401.4,175.5c-1.7,3.2-5.5,5.6-9.9,5.6c-5.2,0-9.2-2.9-9.2-7.9v-19.4h1.9v18.7c0,4.4,3.2,6.9,7.8,6.9c5.3,0,8.3-4,9.4-6.7
			v-18.9h1.9v21.3l0.3,5.5h-1.5L401.4,175.5z"/>
                 <path d="M412.1,160.9c0,3.8,2.6,4.1,8.4,4.8c4.9,0.6,10.6,1.3,10.6,7.2c0,5.7-5,8.1-11.3,8.1c-6.3,0-9.8-2.8-10.6-6.9l2.1-0.3
			c0.7,3.4,3.3,5.5,8.4,5.5s9.3-1.7,9.3-6.3c0-4.5-4-5-9.5-5.6c-4.3-0.5-9.5-1.1-9.5-6.5c0-5.5,4.7-7.7,9.9-7.7
			c6.3,0,9.8,2.8,10.6,6.9l-2.1,0.3c-0.7-3.4-3.4-5.5-8.5-5.5C416.5,155,412.1,156.5,412.1,160.9z"/>
                 <path d="M440,146h-3v-3h3V146z M437.5,153.7h1.9v26.8h-1.9V153.7z"/>
                 <path d="M447.3,180.5v-40.4h1.9v28.7l6.3-6.3l8.7-8.7h2.8L455.4,165l13.5,15.5h-2.6l-12.2-14.3l-4.9,4.9v9.4H447.3z"/>
                 <path d="M473.9,180.5v-40.4h1.9v28.7l6.3-6.3l8.7-8.7h2.8L482,165l13.5,15.5h-2.6l-12.2-14.3l-4.9,4.9v9.4H473.9z"/>
                 <path d="M511.8,167.1c0-8.7,4.3-13.9,11.9-13.9c7.6,0,11.9,5.2,11.9,13.9s-4.3,13.9-11.9,13.9C516.2,181,511.8,175.8,511.8,167.1z
			 M514,167.1c0,7.7,3.5,12.1,9.8,12.1c6.3,0,9.8-4.4,9.8-12.1c0-7.7-3.5-12.1-9.8-12.1C517.4,155,514,159.4,514,167.1z"/>
                 <path d="M563.9,182c0,5.9-4.4,9.1-11.6,9.1c-7.4,0-9.6-3.5-10.4-7l2-0.3c0.7,3.3,2.9,5.7,8.3,5.7c5.4,0,9.7-2,9.7-7.2v-7.6
			c-1.9,3.6-6.2,5.3-9.9,5.3c-7,0-11.2-5.1-11.2-13.4c0-8.3,4.1-13.4,11.2-13.4c3.7,0,8,2,9.9,5.6l0.7-5.1h1.5l-0.3,5.5V182z
			 M562,161.7c-1-2.7-3.8-6.7-9.8-6.7c-5.7,0-9.1,4.2-9.1,11.6s3.4,11.6,9.1,11.6c5.9,0,8.7-3.8,9.8-6.5V161.7z"/>
                 <path d="M604,140.1h1.9v35l0.3,5.5h-1.5l-0.7-5.1c-1.9,3.6-6,5.6-9.7,5.6c-7,0-11.2-5.6-11.2-13.9s4.1-13.9,11.2-13.9
			c3.7,0,7.8,2,9.7,5.6V140.1z M604,161.7c-1-2.7-3.7-6.7-9.6-6.7c-5.7,0-9.2,4.8-9.2,12.1s3.4,12.1,9.2,12.1c5.9,0,8.6-4,9.6-6.7
			V161.7z"/>
                 <path d="M614.2,167.8c0.3,7,4.3,11.4,9.9,11.4c5.1,0,7.7-2.1,8.4-5.5l2.1,0.3c-0.8,4.1-4.3,6.9-10.6,6.9
			c-7.1,0-11.9-5.6-11.9-13.9s4.8-13.9,11.9-13.9c8.2,0,11,5.5,11,13.9v0.8H614.2z M614.2,166.1h18.7c-0.1-6.8-2.3-11.1-8.8-11.1
			C618.6,155,614.6,159.3,614.2,166.1z"/>
                 <path d="M643,160.9c0,3.8,2.6,4.1,8.4,4.8c4.9,0.6,10.6,1.3,10.6,7.2c0,5.7-5,8.1-11.3,8.1c-6.3,0-9.8-2.8-10.6-6.9l2.1-0.3
			c0.7,3.4,3.3,5.5,8.4,5.5c5.1,0,9.3-1.7,9.3-6.3c0-4.5-4-5-9.5-5.6c-4.3-0.5-9.5-1.1-9.5-6.5c0-5.5,4.7-7.7,9.9-7.7
			c6.3,0,9.8,2.8,10.6,6.9l-2.1,0.3c-0.7-3.4-3.4-5.5-8.5-5.5C647.4,155,643,156.5,643,160.9z"/>
                 <path d="M670.9,146h-3v-3h3V146z M668.4,153.7h1.9v26.8h-1.9V153.7z"/>
                 <path d="M699.8,182c0,5.9-4.4,9.1-11.6,9.1c-7.4,0-9.6-3.5-10.4-7l2-0.3c0.7,3.3,2.9,5.7,8.3,5.7c5.4,0,9.7-2,9.7-7.2v-7.6
			c-1.9,3.6-6.2,5.3-9.9,5.3c-7,0-11.2-5.1-11.2-13.4c0-8.3,4.1-13.4,11.2-13.4c3.7,0,8,2,9.9,5.6l0.7-5.1h1.5l-0.3,5.5V182z
			 M697.9,161.7c-1-2.7-3.8-6.7-9.8-6.7c-5.7,0-9.1,4.2-9.1,11.6s3.4,11.6,9.1,11.6c5.9,0,8.7-3.8,9.8-6.5V161.7z"/>
                 <path d="M709.2,158.8c1.7-3.2,5.5-5.6,9.9-5.6c5.2,0,9.2,2.9,9.2,7.9v19.4h-1.9v-18.7c0-4.4-3.2-6.9-7.8-6.9c-5.3,0-8.3,4-9.4,6.7
			v18.9h-1.9v-21.3l-0.3-5.5h1.5L709.2,158.8z"/>
             </g>
         </g>
     </svg>
);

const kmdLogoEnglish = (
     <svg x="0px" y="0px" viewBox="0 0 904.7 198.4">
         <title>University of Bergen - Faculty of Fine Art, Music and Design</title>
         <g>
             <g>
                 <path d="M290.8,116.6l0.7,67.5l-9.9-15.5V92.3h-17.2V34.1c3,5.3,5,11.3,6.3,18l10.4-1.5c-2.5-12.8-7.7-24.1-16.7-32.7v-7l7.9-8.4
			h-19.8l-2.3,5.6c-1.6-0.8-3.3-1.5-5.1-2.2l3.1-3.4h-14.5c-7.3-1.6-15.6-2.5-25-2.5c-2.9,0-5.8,0.1-8.6,0.3
			c-3.4-0.2-6.9-0.3-10.6-0.3c-5.1,0-10.1,0.3-14.9,1c-5.5-0.7-11.6-1-18.2-1c-4.2,0-8.3,0.2-12.3,0.7c-4.7-0.4-9.7-0.7-15.1-0.7
			c-7.8,0-15.6,0.8-22.8,2.5l-50.4,0v15.4L45.8,2.5H33.7V95l-2.4,2.6V2.5H21.1v106.2L9.9,120.8V2.5H0v193.5h9.9v-62l11.2-12v9.7
			c0,17.9,3.7,32.9,12.6,44.2v20h9.7v-10.9c12,8.6,29.4,13.4,53.9,13.4c5.6,0,10.7-0.3,15.6-0.7c4.9,0.5,10.2,0.7,16,0.7
			c0.6,0,1.2,0,1.7,0c0.5,0,1,0,1.5,0c4.1,0,8.1-0.2,11.8-0.4c3.9,0.3,8,0.4,12.3,0.4c5.1,0,10.2-0.3,15.2-0.9
			c5.5,0.6,11.4,0.9,18,0.9c3.3,0,6.6-0.1,9.9-0.4c3.1,0.2,6.2,0.4,9.5,0.4c12,0,22.7-1.2,32.3-3.7l1,1.3h22.4v-2l1.7,2h13.1
			l-11.6-14c2.6-1.9,5-4,7.4-6.3l13.3,20.2h12.2V2.5h-9.7V116.6z M33.7,153.9c-1.7-6.2-2.4-13-2.4-20.4v-22.3l2.4-2.6V153.9z
			 M88,149l1.9,2.3l-1.9,0.3V149z M42.6,14.4l13.3,20.8v35.6L43.4,84.5v-2.6L42.6,14.4z M55.9,84.6v10.4l-4.6-5.5L55.9,84.6z
			 M78.1,188c-16.2-2.3-27.4-7.9-34.7-15.9V98.1l1.5-1.6l11,13.4v21.8c0,7.3,0.6,14.1,1.9,20.3l0,0c2,14.6,7.6,26.9,20.3,35.1V188z
			 M78.1,171.9c-8.7-9.6-12-22.8-12-38.5v-11.1l12,14.6V171.9z M78.1,121.7l-12-14.4V73.8l2.1-2.3c2.3,5.1,5.6,9.2,9.9,12.6V121.7z
			 M78.1,46.7l-1.9,2.1l-1-1.6c0.4-4.8,1.4-9.1,2.9-12.8V46.7z M78.1,16.8c-5,4.8-8.8,10.8-11.1,18l-0.9-1.4v-22h12V16.8z
			 M90.4,181.2c-0.8-0.4-1.6-0.9-2.4-1.3v-15.7c0.7,2,1.5,4,2.4,5.8V181.2z M90.4,136.6l-2.4-2.9V89.8c0.8,0.3,1.6,0.7,2.4,1V136.6z
			 M90.4,70.5L88,66.9V50.3l2.4-2.6V70.5z M90.4,33.4L88,36V21c0.8-0.6,1.6-1.3,2.4-1.8V33.4z M110.6,179.4v-2.8l4.6,5.6
			C113.6,181.3,112,180.4,110.6,179.4z M115.6,167l-4.9-6v-41.1l4.9,7.6V167z M115.6,109.1l-4.9-7.6v-0.3h4.9V109.1z M115.6,79.3
			c-1.9-1.1-3.5-2.3-4.9-3.6V47.2l4.9,12V79.3z M115.6,34.7l-4-9.7l3.6-3.9c0.1-0.1,0.3-0.2,0.4-0.3V34.7z M127.7,181.6l-2.2-2.7
			v-5.3c0.7,1.1,1.4,2.3,2.2,3.3V181.6z M127.7,150.7l-2.2,0.3v-8.2l2.2,3.4V150.7z M127.7,118.4l-2.2,2.4v-19.6h1.4
			c0.1,5.7,0.3,11.2,0.8,16.5V118.4z M127.7,34.2c-1,3.1-1.8,6.5-2.2,10.2V14.8c0.7-0.3,1.5-0.6,2.2-0.9V34.2z M139.7,175.9
			c-0.7-0.7-1.4-1.4-2-2.2v-12.2l2,3.1V175.9z M139.7,129c-0.5-2.9-0.9-5.8-1.3-8.9l1.3-1.3V129z M139.7,86.9
			c-0.7-0.1-1.3-0.2-2-0.4c0.1-1,0.1-2.1,0.2-3.1c0.6,0.5,1.2,0.9,1.8,1.4V86.9z M139.7,37.3c-0.7,1.3-1.4,2.6-2,4v-4.1
			c0.6-1.7,1.2-3.4,2-4.9V37.3z M139.7,15.9c-0.7,0.6-1.4,1.3-2,1.9v-6.4h2V15.9z M149.6,20.2c2.7-2.1,5.6-3.8,8.7-5.2
			c-3.1,2.4-6,5.1-8.7,8V20.2z M155.1,166.8c-2-2.6-3.9-5.3-5.5-8.3v-16.8l8.3,20.1C157.1,163.5,156.1,165.2,155.1,166.8z
			 M161.3,145.6l-2-5l-4.9-11.9l6.9-7.4V145.6z M161.3,108l-10.7,11.6l-1.1-2.6v-8.9l5.3-5.6c2.3,0.5,4.5,1.1,6.5,1.7V108z
			 M161.3,81.9l-1.8,1.9c-3.9-1.2-7.2-2.5-10-4.1V40.2c3.2-5.7,7.1-10.8,11.7-15V81.9z M233,18.8c2.2,1.5,4.1,3.2,5.8,5l-6.5,7.1
			c-1.5-2.2-3.1-4.2-4.8-6.1L233,18.8z M237.6,92.3h-5.3l-4-6.2l7.4-17.9C236.7,75.4,237.3,83.4,237.6,92.3z M227.1,50.9l3.2-3.4
			c0.6,1.6,1.2,3.2,1.7,4.9l-4.9,11.9V50.9z M227.1,106c1.2,0.4,2.3,0.9,3.3,1.4l6.6,10.1c-0.7,8.7-1.8,16.4-3.5,23.2l-6.4-7.7V106z
			 M208.2,26.7c0.6,0.8,1.1,1.6,1.6,2.4l-1.6,1.8V26.7z M208.2,45.4l5.9-6.3c0.6,2.5,1.3,6.1,1.8,9.6l-7.1,7.7l-0.6-0.9V45.4z
			 M208.2,101.2h0.8c0.9,0.2,1.8,0.3,2.6,0.5l-0.9,2.2c-0.8-0.6-1.6-1.1-2.5-1.7V101.2z M208.2,134.7l2.6-6.3l5.9,7.2
			c0.3,2.7,0.4,5.6,0.4,8.8c0,1.8-0.1,3.5-0.3,5.2L208,139C208.1,137.6,208.1,136.1,208.2,134.7z M183.4,12.7
			c2.6,0.4,5.1,0.8,7.5,1.4c2.6,1,5,2.2,7.1,3.5V40l-0.7-1.1c-2.3-10.3-7.2-19.1-15.6-25.6C182.3,13.1,182.8,12.9,183.4,12.7z
			 M173.4,19.4c0,0,0.1,0.1,0.1,0.1l18.5,29l-18.7,20.3V19.4z M173.4,82.6l24-25.7l0.6,1v10.2L179.8,88c-2.2-0.3-4.4-0.7-6.4-1V82.6
			z M192.3,89.5c-0.4,0-0.8-0.1-1.2-0.1L198,82v8.2C196.2,90,194.3,89.7,192.3,89.5z M198,112.9v14c-2.7-11.3-8.3-19.1-17.3-24.7
			c4.1,0.9,7.7,1.9,10.9,3L198,112.9z M174,177.1l-1.2-2.9V143c0.4-3.6,0.5-7.4,0.5-11.3v-19.5l15.1,18.4c0.8,4.1,1.1,8.6,1.1,13.7
			C189.6,159.6,183.3,170,174,177.1z M187.7,184.4l0.4-0.9c9.7-7.8,15.5-18.4,18.2-31.2l7.6,9.2
			C208.9,172.9,199.1,180.2,187.7,184.4z M217.2,185.6c-1.7,0.5-3.5,1-5.2,1.4h-2.4c2.7-1.3,5.2-2.8,7.6-4.4V185.6z M217.2,88.3
			l-1.7,4H212c-1.2-0.2-2.5-0.4-3.8-0.7V73.5l9,13.7V88.3z M217.2,69.2l-3-4.5l3-3.2V69.2z M217.2,15.8c-2.4-1.6-5-3.1-7.6-4.4h5.7
			c0.7,0.1,1.3,0.3,1.9,0.5V15.8z M227.1,181.6v-4l2.3,2.8C228.6,180.8,227.9,181.3,227.1,181.6z M227.1,158v-9.6c0,0,0,0,0-0.1
			l2.9,3.5C229.1,154,228.1,156.1,227.1,158z M237.5,174.6L232,168c1.7-2.4,3.3-5.1,4.7-7.9l6.5,7.9
			C241.6,170.5,239.6,172.6,237.5,174.6z M241.1,149.9c2-5.7,3.5-12,4.7-19l4.1,6.3c0.2,2.3,0.3,4.6,0.3,7.2c0,5.2-0.7,9.8-2.1,13.9
			L241.1,149.9z M243.7,182c2.2-1.9,4.2-3.9,6-6.1l3.4,4.1c-2.5,1.4-5.2,2.6-8,3.7L243.7,182z M254.8,195.4l-3.5-4.2
			c1.2-0.5,2.3-1,3.5-1.5V195.4z M254.8,116.7c-1.8-3.5-4.1-6.6-6.9-9.3c0.1-2,0.1-4.1,0.1-6.1h6.8V116.7z M254.8,81.9v10.4H248
			c-0.4-15.9-2.4-29.1-5.7-40.2l5.1-12.4c0.6,2.1,1,4.2,1.3,6.5l6.6-1L254.8,81.9z M255.4,31.1c-0.7-1.8-1.5-3.5-2.4-5.2l1.6-3.9
			c0.3,0.3,0.6,0.5,1,0.8L255.4,31.1z M264.5,172.2v-12.7l5.1,7.8C268,169,266.3,170.6,264.5,172.2z M271.7,153l-7.2-11.4v-40.4h7.2
			V153z"/>
             </g>
         </g>
         <g>
             <g>
                 <path d="M339.1,40.1V13.8h2.1v26.7c0,6.8,3.1,11.4,13.4,11.4s13.4-4.5,13.4-11.4V13.8h2.1v26.3c0,8.3-3.8,13.6-15.5,13.6
			S339.1,48.4,339.1,40.1z"/>
                 <path d="M379.7,31.5c1.7-3.2,5.5-5.6,9.9-5.6c5.2,0,9.2,2.9,9.2,7.9v19.4h-1.9V34.6c0-4.4-3.2-6.9-7.8-6.9c-5.3,0-8.3,4-9.4,6.7
			v18.9h-1.9V31.9l-0.3-5.5h1.5L379.7,31.5z"/>
                 <path d="M408.9,18.7h-3v-3h3V18.7z M406.4,26.4h1.9v26.8h-1.9V26.4z"/>
                 <path d="M426.4,53.7h-2.1l-10.5-27.3h2.3l5.7,15.4l3.5,9.8l3.5-9.8l5.8-15.4h2.2L426.4,53.7z"/>
                 <path d="M441.8,40.5c0.3,7,4.3,11.4,9.9,11.4c5.1,0,7.7-2.1,8.4-5.5l2.1,0.3c-0.8,4.1-4.3,6.9-10.6,6.9c-7.1,0-11.9-5.6-11.9-13.9
			c0-8.3,4.8-13.9,11.9-13.9c8.2,0,11,5.5,11,13.9v0.8H441.8z M441.8,38.8h18.7c-0.1-6.8-2.3-11.1-8.8-11.1
			C446.2,27.7,442.2,32,441.8,38.8z"/>
                 <path d="M482.9,26.1l-0.3,2c-0.5-0.1-1.2-0.3-2-0.3c-5.9,0-9.5,4.6-9.6,8.3v17h-1.9V31.9l-0.3-5.5h1.5l0.7,5.6
			c1.7-3.8,5.9-6.1,9.6-6.1C481.4,25.9,482.2,26,482.9,26.1z"/>
                 <path d="M488.6,33.6c0,3.8,2.6,4.1,8.4,4.8c4.9,0.6,10.6,1.3,10.6,7.2c0,5.7-5,8.1-11.3,8.1c-6.3,0-9.8-2.8-10.6-6.9l2.1-0.3
			c0.7,3.4,3.3,5.5,8.4,5.5s9.3-1.7,9.3-6.3c0-4.5-4-5-9.5-5.6c-4.3-0.5-9.5-1.1-9.5-6.5c0-5.5,4.7-7.7,9.9-7.7
			c6.3,0,9.8,2.8,10.6,6.9l-2.1,0.3c-0.7-3.4-3.4-5.5-8.5-5.5C493,27.7,488.6,29.2,488.6,33.6z"/>
                 <path d="M516.5,18.7h-3v-3h3V18.7z M514.1,26.4h1.9v26.8h-1.9V26.4z"/>
                 <path d="M537.7,51.4l0.4,1.8c-0.6,0.3-1.8,0.5-2.9,0.5c-7.9,0-8.4-5-8.4-10.5V28.1h-5.4v-1.7h5.4v-7.1l1.9-0.7v7.7h8.7v1.7h-8.7
			v16.4c0,6.2,2.4,7.3,6.5,7.3C536.5,51.9,537.4,51.6,537.7,51.4z"/>
                 <path d="M555,50.8l-5,12.5h-2.2l4.6-11.1l-10.5-25.8h2.3l5.7,14.4l3.5,9.3l3.5-9.3l5.8-14.4h2.2L555,50.8z"/>
                 <path d="M581.5,39.8c0-8.7,4.3-13.9,11.9-13.9c7.6,0,11.9,5.2,11.9,13.9c0,8.7-4.3,13.9-11.9,13.9
			C585.8,53.7,581.5,48.5,581.5,39.8z M583.6,39.8c0,7.7,3.5,12.1,9.8,12.1c6.3,0,9.8-4.4,9.8-12.1s-3.5-12.1-9.8-12.1
			C587.1,27.7,583.6,32.1,583.6,39.8z"/>
                 <path d="M615.5,21.4v5h7.7v1.7h-7.7v25.1h-1.9V28.1h-5.4v-1.7h5.4v-3.6c0-5.6,0.5-10.5,8.4-10.5c1.1,0,2.3,0.2,2.9,0.5l-0.4,1.8
			c-0.4-0.2-1.3-0.5-2.5-0.5C617.9,14.1,615.5,15.3,615.5,21.4z"/>
                 <path d="M642.3,53.2V13.8h15.2c6.5,0,10.8,1.5,10.8,10.2c0,5.6-3.2,8.2-5.4,8.8c3.3,0.7,6.7,3.6,6.7,9.6c0,9.2-5.1,10.8-11.4,10.8
			H642.3z M644.3,15.6v16.5h14.2c4.4,0,7.6-2.3,7.6-8.1c0-6.7-3-8.3-7.2-8.3H644.3z M659.1,51.4c4.2,0,8.3-1.4,8.3-9
			c0-7.2-4.6-8.5-8.3-8.5h-14.8v17.5H659.1z"/>
                 <path d="M677.2,40.5c0.3,7,4.3,11.4,9.9,11.4c5.1,0,7.7-2.1,8.4-5.5l2.1,0.3c-0.8,4.1-4.3,6.9-10.6,6.9c-7.1,0-11.9-5.6-11.9-13.9
			c0-8.3,4.8-13.9,11.9-13.9c8.2,0,11,5.5,11,13.9v0.8H677.2z M677.2,38.8h18.7c-0.1-6.8-2.3-11.1-8.8-11.1
			C681.6,27.7,677.6,32,677.2,38.8z"/>
                 <path d="M718.3,26.1l-0.3,2c-0.5-0.1-1.2-0.3-2-0.3c-5.9,0-9.5,4.6-9.6,8.3v17h-1.9V31.9l-0.3-5.5h1.5l0.7,5.6
			c1.7-3.8,5.9-6.1,9.6-6.1C716.8,25.9,717.6,26,718.3,26.1z"/>
                 <path d="M743.7,54.7c0,5.9-4.4,9.1-11.6,9.1c-7.4,0-9.6-3.5-10.4-7l2-0.3c0.7,3.3,2.9,5.7,8.3,5.7c5.4,0,9.7-2,9.7-7.2v-7.6
			c-1.9,3.6-6.2,5.3-9.9,5.3c-7,0-11.2-5.1-11.2-13.4s4.1-13.4,11.2-13.4c3.7,0,8,2,9.9,5.6l0.7-5.1h1.5l-0.3,5.5V54.7z M741.8,34.4
			c-1-2.7-3.8-6.7-9.8-6.7c-5.7,0-9.1,4.2-9.1,11.6c0,7.4,3.4,11.6,9.1,11.6c5.9,0,8.7-3.8,9.8-6.5V34.4z"/>
                 <path d="M752,40.5c0.3,7,4.3,11.4,9.9,11.4c5.1,0,7.7-2.1,8.4-5.5l2.1,0.3c-0.8,4.1-4.3,6.9-10.6,6.9c-7.1,0-11.9-5.6-11.9-13.9
			c0-8.3,4.8-13.9,11.9-13.9c8.2,0,11,5.5,11,13.9v0.8H752z M752,38.8h18.7c-0.1-6.8-2.3-11.1-8.8-11.1
			C756.4,27.7,752.4,32,752,38.8z"/>
                 <path d="M781.3,31.5c1.7-3.2,5.5-5.6,9.9-5.6c5.2,0,9.2,2.9,9.2,7.9v19.4h-1.9V34.6c0-4.4-3.2-6.9-7.8-6.9c-5.3,0-8.3,4-9.4,6.7
			v18.9h-1.9V31.9l-0.3-5.5h1.5L781.3,31.5z"/>
                 <path d="M341.9,123.4h-2V83.9h25.3v1.8h-23.3v16.5h21.2v1.8h-21.2V123.4z"/>
                 <path d="M378.3,108.2l9.7-1.5v-2.1c0-5.2-3-6.8-8.2-6.9c-4.8-0.1-7,1.8-7.7,5.1l-2-0.3c0.8-3.7,3.5-6.5,9.7-6.5
			c6.5,0,10.2,2.9,10.2,8.8v13l0.3,5.5h-1.5l-0.7-5.1c-2.2,4-6.7,5.6-10.7,5.6c-5.8,0-8.6-3.1-8.6-7.5
			C368.7,111.2,372.5,109.1,378.3,108.2z M377.3,122.1c6.1,0,9.3-3.1,10.7-6.7v-6.9l-10,1.6c-4.1,0.7-7.3,1.9-7.3,6.4
			C370.8,120.1,373.1,122.1,377.3,122.1z"/>
                 <path d="M408,96.1c6.3,0,9.8,2.8,10.6,6.9l-2.1,0.3c-0.7-3.4-3.4-5.5-8.4-5.5c-5.7,0-9.8,4.8-9.8,12.1c0,7.4,4.1,12.1,9.8,12.1
			c5.1,0,7.7-2.1,8.4-5.5l2.1,0.3c-0.8,4.1-4.3,6.9-10.6,6.9c-7.1,0-11.9-5.6-11.9-13.9C396.1,101.6,400.9,96.1,408,96.1z"/>
                 <path d="M443.1,118.3c-1.7,3.2-5.5,5.6-9.9,5.6c-5.2,0-9.2-2.9-9.2-7.9V96.6h1.9v18.7c0,4.4,3.2,6.9,7.8,6.9c5.3,0,8.3-4,9.4-6.7
			V96.6h1.9v21.3l0.3,5.5h-1.5L443.1,118.3z"/>
                 <path d="M453.1,82.9h1.9v40.4h-1.9V82.9z"/>
                 <path d="M476.8,121.6l0.4,1.8c-0.6,0.3-1.8,0.5-2.9,0.5c-7.9,0-8.4-5-8.4-10.5V98.3h-5.4v-1.7h5.4v-7.1l1.9-0.7v7.7h8.7v1.7h-8.7
			v16.4c0,6.2,2.4,7.3,6.5,7.3C475.5,122.1,476.5,121.7,476.8,121.6z"/>
                 <path d="M494,121l-5,12.5h-2.2l4.6-11.1l-10.5-25.8h2.3l5.7,14.4l3.5,9.3l3.5-9.3l5.8-14.4h2.2L494,121z"/>
                 <path d="M520.6,110c0-8.7,4.3-13.9,11.9-13.9c7.6,0,11.9,5.2,11.9,13.9c0,8.7-4.3,13.9-11.9,13.9
			C524.9,123.9,520.6,118.7,520.6,110z M522.7,110c0,7.7,3.5,12.1,9.8,12.1c6.3,0,9.8-4.4,9.8-12.1c0-7.7-3.5-12.1-9.8-12.1
			C526.2,97.8,522.7,102.3,522.7,110z"/>
                 <path d="M554.6,91.6v5h7.7v1.7h-7.7v25.1h-1.9V98.3h-5.4v-1.7h5.4v-3.6c0-5.6,0.5-10.5,8.4-10.5c1.1,0,2.3,0.2,2.9,0.5l-0.4,1.8
			c-0.4-0.2-1.3-0.5-2.5-0.5C557,84.2,554.6,85.4,554.6,91.6z"/>
                 <path d="M583.4,123.4h-2V83.9h25.3v1.8h-23.3v16.5h21.2v1.8h-21.2V123.4z"/>
                 <path d="M615.7,88.8h-3v-3h3V88.8z M613.2,96.6h1.9v26.8h-1.9V96.6z"/>
                 <path d="M624.9,101.6c1.7-3.2,5.5-5.6,9.9-5.6c5.2,0,9.2,2.9,9.2,7.9v19.4H642v-18.7c0-4.4-3.2-6.9-7.8-6.9c-5.3,0-8.3,4-9.4,6.7
			v18.9H623V102l-0.3-5.5h1.5L624.9,101.6z"/>
                 <path d="M652.1,110.7c0.3,7,4.3,11.4,9.9,11.4c5.1,0,7.7-2.1,8.4-5.5l2.1,0.3c-0.8,4.1-4.3,6.9-10.6,6.9
			c-7.1,0-11.9-5.6-11.9-13.9c0-8.3,4.8-13.9,11.9-13.9c8.2,0,11,5.5,11,13.9v0.8H652.1z M652.1,109h18.7
			c-0.1-6.8-2.3-11.1-8.8-11.1C656.5,97.8,652.5,102.2,652.1,109z"/>
                 <path d="M715.6,110h-18l-5,13.4h-2.2l15.2-39.9h2.1l15.2,39.9h-2.2L715.6,110z M702.3,97.4l-4,10.7h16.6l-4-10.7l-4.3-11.7
			L702.3,97.4z"/>
                 <path d="M742.1,96.3l-0.3,2c-0.5-0.1-1.2-0.3-2-0.3c-5.9,0-9.5,4.6-9.6,8.3v17h-1.9V102l-0.3-5.5h1.5l0.7,5.6
			c1.7-3.8,5.9-6.1,9.6-6.1C740.6,96.1,741.5,96.1,742.1,96.3z"/>
                 <path d="M762.2,121.6l0.4,1.8c-0.6,0.3-1.8,0.5-2.9,0.5c-7.9,0-8.4-5-8.4-10.5V98.3h-5.4v-1.7h5.4v-7.1l1.9-0.7v7.7h8.7v1.7h-8.7
			v16.4c0,6.2,2.4,7.3,6.5,7.3C760.9,122.1,761.8,121.7,762.2,121.6z"/>
                 <path d="M770.9,120.7l-3,10.9h-1.3l1.6-10.9H770.9z"/>
                 <path d="M345.2,153.1l-3.5-8.5l0.2,12.7v23.3h-2v-39.4h2.4l11.6,28.2l3.7,9.2l3.7-9.2l11.6-28.2h2.4v39.4h-2v-23.3l0.2-12.7
			l-3.5,8.5l-11.3,27.5h-2.2L345.2,153.1z"/>
                 <path d="M402.5,175.5c-1.7,3.2-5.5,5.6-9.9,5.6c-5.2,0-9.2-2.9-9.2-7.9v-19.4h1.9v18.7c0,4.4,3.2,6.9,7.8,6.9c5.3,0,8.3-4,9.4-6.7
			v-18.9h1.9v21.3l0.3,5.5h-1.5L402.5,175.5z"/>
                 <path d="M413.1,160.9c0,3.8,2.6,4.1,8.4,4.8c4.9,0.6,10.6,1.3,10.6,7.2c0,5.7-5,8.1-11.3,8.1c-6.3,0-9.8-2.8-10.6-6.9l2.1-0.3
			c0.7,3.4,3.3,5.5,8.4,5.5s9.3-1.7,9.3-6.3c0-4.5-4-5-9.5-5.6c-4.3-0.5-9.5-1.1-9.5-6.5c0-5.5,4.7-7.7,9.9-7.7
			c6.3,0,9.8,2.8,10.6,6.9l-2.1,0.3c-0.7-3.4-3.4-5.5-8.5-5.5C417.5,155,413.1,156.5,413.1,160.9z"/>
                 <path d="M441,146h-3v-3h3V146z M438.6,153.7h1.9v26.8h-1.9V153.7z"/>
                 <path d="M459,153.2c6.3,0,9.8,2.8,10.6,6.9l-2.1,0.3c-0.7-3.4-3.4-5.5-8.4-5.5c-5.7,0-9.8,4.8-9.8,12.1s4.1,12.1,9.8,12.1
			c5.1,0,7.7-2.1,8.4-5.5l2.1,0.3c-0.8,4.1-4.3,6.9-10.6,6.9c-7.1,0-11.9-5.6-11.9-13.9S451.9,153.2,459,153.2z"/>
                 <path d="M497.3,165.4l9.7-1.5v-2.1c0-5.2-3-6.8-8.2-6.9c-4.8-0.1-7,1.8-7.7,5.1l-2-0.3c0.8-3.7,3.5-6.5,9.7-6.5
			c6.5,0,10.2,2.9,10.2,8.8v13l0.3,5.5h-1.5l-0.7-5.1c-2.2,4-6.7,5.6-10.7,5.6c-5.8,0-8.6-3.1-8.6-7.5
			C487.7,168.3,491.6,166.3,497.3,165.4z M496.4,179.3c6.1,0,9.3-3.1,10.7-6.7v-6.9l-10,1.6c-4.1,0.7-7.3,1.9-7.3,6.4
			C489.8,177.2,492.1,179.3,496.4,179.3z"/>
                 <path d="M518.3,158.8c1.7-3.2,5.5-5.6,9.9-5.6c5.2,0,9.2,2.9,9.2,7.9v19.4h-1.9v-18.7c0-4.4-3.2-6.9-7.8-6.9c-5.3,0-8.3,4-9.4,6.7
			v18.9h-1.9v-21.3l-0.3-5.5h1.5L518.3,158.8z"/>
                 <path d="M564.3,140.1h1.9v35l0.3,5.5h-1.5l-0.7-5.1c-1.9,3.6-6,5.6-9.7,5.6c-7,0-11.2-5.6-11.2-13.9s4.1-13.9,11.2-13.9
			c3.7,0,7.8,2,9.7,5.6V140.1z M564.3,161.7c-1-2.7-3.7-6.7-9.6-6.7c-5.7,0-9.2,4.8-9.2,12.1s3.4,12.1,9.2,12.1c5.9,0,8.6-4,9.6-6.7
			V161.7z"/>
                 <path d="M600.4,180.5h-13v-39.4h13c11.5,0,17.8,4.3,17.8,19.7S611.9,180.5,600.4,180.5z M600.9,178.7c10.7,0,15.2-4.2,15.2-17.9
			s-4.5-17.9-15.2-17.9h-11.5v35.8H600.9z"/>
                 <path d="M626,167.8c0.3,7,4.3,11.4,9.9,11.4c5.1,0,7.7-2.1,8.4-5.5l2.1,0.3c-0.8,4.1-4.3,6.9-10.6,6.9c-7.1,0-11.9-5.6-11.9-13.9
			s4.8-13.9,11.9-13.9c8.2,0,11,5.5,11,13.9v0.8H626z M626,166.1h18.7c-0.1-6.8-2.3-11.1-8.8-11.1C630.4,155,626.4,159.3,626,166.1z
			"/>
                 <path d="M654.8,160.9c0,3.8,2.6,4.1,8.4,4.8c4.9,0.6,10.6,1.3,10.6,7.2c0,5.7-5,8.1-11.3,8.1c-6.3,0-9.8-2.8-10.6-6.9l2.1-0.3
			c0.7,3.4,3.3,5.5,8.4,5.5s9.3-1.7,9.3-6.3c0-4.5-4-5-9.5-5.6c-4.3-0.5-9.5-1.1-9.5-6.5c0-5.5,4.7-7.7,9.9-7.7
			c6.3,0,9.8,2.8,10.6,6.9l-2.1,0.3c-0.7-3.4-3.4-5.5-8.5-5.5C659.2,155,654.8,156.5,654.8,160.9z"/>
                 <path d="M682.7,146h-3v-3h3V146z M680.2,153.7h1.9v26.8h-1.9V153.7z"/>
                 <path d="M711.7,182c0,5.9-4.4,9.1-11.6,9.1c-7.4,0-9.6-3.5-10.4-7l2-0.3c0.7,3.3,2.9,5.7,8.3,5.7c5.4,0,9.7-2,9.7-7.2v-7.6
			c-1.9,3.6-6.2,5.3-9.9,5.3c-7,0-11.2-5.1-11.2-13.4c0-8.3,4.1-13.4,11.2-13.4c3.7,0,8,2,9.9,5.6l0.7-5.1h1.5l-0.3,5.5V182z
			 M709.7,161.7c-1-2.7-3.8-6.7-9.8-6.7c-5.7,0-9.1,4.2-9.1,11.6s3.4,11.6,9.1,11.6c5.9,0,8.7-3.8,9.8-6.5V161.7z"/>
                 <path d="M721.1,158.8c1.7-3.2,5.5-5.6,9.9-5.6c5.2,0,9.2,2.9,9.2,7.9v19.4h-1.9v-18.7c0-4.4-3.2-6.9-7.8-6.9c-5.3,0-8.3,4-9.4,6.7
			v18.9h-1.9v-21.3l-0.3-5.5h1.5L721.1,158.8z"/>
             </g>
         </g>
     </svg>
);

const kmdLogo = {
    no: kmdLogoNorwegian,
    en: kmdLogoEnglish
};

const visitingAddress = {
    no: 'Besøksadresse',
    en: 'Visiting address'
};

const postalAddress = {
    no: 'Postadresse',
    en: 'Postal address'
};

const telephone = {
    no: 'Telefon',
    en: 'Telephone'
};

const email = {
    no: 'E-post',
    en: 'Email'
};

const followUs = {
    no: 'Følg oss',
    en: 'Follow us'
};

function getText(block, lang) {
    return block[lang] || block.no;
}

class Footer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			innerWidth: 0,
		};

		this.handleResize = this.handleResize.bind(this);
		this.isMobile = this.isMobile.bind(this);
	}

	componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

	handleResize() {
        this.setState({
            innerWidth: window.innerWidth
        });
    }	

    isMobile() {
        return this.state.innerWidth < 1000;
    }

    render() {		
        const {links, contactInfo, mainPage, uibUrl, language} = this.props;
        return (
            <footer className="footer">
                <aside className="contact">
                    <a href={mainPage} className="kmdLogo">
                        {kmdLogo[language] || kmdLogoNorwegian}
                    </a>
                    <div className="info">
                        <div className="contactSection">
                            <p className="small sectionHeader">{getText(visitingAddress, language)}:</p>
                            <p className="small contactText">{contactInfo.contact1}</p>
                            <p className="small contactText">{contactInfo.contact2}</p>
                            <p className="small contactText">{contactInfo.contact3}</p>
                        </div>

                        <div className="contactSection">
                            <p className="small sectionHeader">{getText(postalAddress, language)}:</p>
                            <p className="small contactText">{contactInfo.post1}</p>
                            <p className="small contactText">{contactInfo.post2}</p>
                        </div>

                        <div className="contactSection">
                            <p className="small sectionHeader">{getText(telephone, language)}:</p>
                            <a className="small-link contactLink" href={`tel:${contactInfo.telephone && contactInfo.telephone.replace(/ /g,'')}`}>{contactInfo.telephone}</a>
                        </div>

                        <div className="contactSection">
                            <p className="small sectionHeader">{getText(email, language)}:</p>
                            <a className="small-link contactLink" href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
                        </div>

                        <div className="contactSection">
                            <p className="small sectionHeader">{getText(followUs, language)}:</p>
                            <div>
                                <a className="small-link contactLink" href={contactInfo.facebook}>Facebook</a>
                            </div>
                            <div>
                                <a className="small-link contactLink" href={contactInfo.instagram}>Instagram</a>
                            </div>
                            <div>
                                <a className="small-link contactLink" href={contactInfo.twitter}>Twitter</a>
                            </div>
                        </div>
                    </div>
                    <a href={uibUrl} className="uibLogo">
                        {uibLogo}
                    </a>
                </aside>
                <div className="links">
                    <Newsletter language={language} />
                    {links.map((link, i) => {
                        return (
                            <div key={i} className="section">
                                <div style={{marginBottom: 16}}>
                                    <a href={link.href} className="large-link footer-large">{link.text}</a>
                                </div>
                                {!this.isMobile() && link.children.map((child, j) => <a key={j} href={child.href} className="small-link footer-small">{child.text}</a>)}
                            </div>
                        )
                    })}
                </div>
            </footer>
        )
    }
}

module.exports = Footer;