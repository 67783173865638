const React = require('react');
const PersonMini = require('../../person-mini/Component');

class PersonList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            logoWidth: 0,
        }
    }

    render() {
        return (
            <div className="person-list">
                {this.props.header &&
                    <h2 id={this.props.header.replace(/\s/g, '-')} className="header">
                        {this.props.header}
                    </h2>
                }
                <div className="list">
                    {this.props.people.map((person, index) => (
                        <div key={index} className="item">
                            <PersonMini {...person} />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

module.exports = PersonList;
