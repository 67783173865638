const React = require('react');
const getImage = require('../../getImage');

class PublicationMini extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {title, authors, href, images} = this.props;
        return (
            <a href={href} className="publication-mini">
                <div className="imageBox" style={{backgroundImage: `url("${getImage(images[0], 200)}")`}}/>
                <div className="infoBox">
                    <p className="title">{title}</p>
                    <p className="authors">{authors.map(person => person.name).join(', ')}</p>
                </div>
            </a>
        );
    }
}

module.exports = PublicationMini;