const React = require('react');
const isNode = (typeof process !== 'undefined');
const Modal = isNode ? require('react-modal') : () => null;
const AnchorMenu = require('./AnchorMenu');

class SimpleAnchorMenu extends React.Component {

    componentWillMount() {
        isNode && Modal.setAppElement('body');
    }

    render() {
        return (
            <div className="menu" style={{ zIndex: 10000 }}>
                <Modal
                    isOpen={this.props.isOpen}
                    contentLabel={this.props.title}
                    style={{
                        overlay: {
                            zIndex: 100000,
                        },
                        content: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            padding: 0,
                            border: 'none',
                            borderRadius: 0,
                            background: 'black',
                        }
                    }}
                >
                    <div className="menu" style={{ zIndex: 10001 }}>
                        <div className="menu-content" style={{ marginTop: '25px' }}>
                            <AnchorMenu nodes={this.props.anchors} onClickMainMenuNav={this.props.onClose} title={this.props.title} language={this.props.language} onClickAnchor={this.props.onClickAnchor} />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

}

module.exports = SimpleAnchorMenu;