const React = require('react');

class DynamicInputForm extends React.Component {
    constructor(props) {
        super(props);
        
        const inputs = {};
        props.inputs.forEach(({ name }) => {
            inputs[name] = '';
        });

        const ticket = this.props.tickets[0].sku; // TODO: Might want do ignore this and require that customer actually clicks a ticket type

        this.state = {
            inputs: inputs,
            ticket: ticket,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
        this.renderInput = this.renderInput.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.onSubmit(this.state.inputs, this.state.ticket);
    }

    handleInputChange({ target }) {
        this.setState({
            inputs : {
                ...this.state.inputs,
                [target.name]: target.value,
            },
        });
    }

    handleRadioButtonChange(e) {
        this.setState({
            ticket: e.target.value,
        })
    }

    renderInput(props, key) {
        switch(props.type) {
            case 'select':
                let color = 'black';
                if (!this.state.inputs[props.name]) {
                    color = 'grey'
                }

                    return (
                        <div className="form-input-wrapper dropdown-menu" key={key}>
                            <select
                                onChange={this.handleInputChange}
                                className={props.className}
                                style={{color: color}}
                                id={props.id}                                    
                                required={props.required}
                                type={props.type}
                                name={props.name}
                                defaultValue=""
                            >
                                <option style={{color: 'gray'}} disabled={true} value="">{props.name}</option>
                                {props.options.map((option) => <option key={option.value} value={option.value}>{option.text}</option>)}
                            </select>
                            <div className="dropdown-arrow"></div>
                        </div>
                    );            
            default:
                return (
                    <div className="form-input-wrapper" key={key}>
                        <input
                            onChange={this.handleInputChange}
                            className={props.className}
                            id={props.id}                                    
                            required={props.required}
                            type={props.type}
                            name={props.name}
                            placeholder={props.label}
                        />
                    </div>
                );
        }
    }

    renderRadioButton(props, key) {
        return (
            <label className="radio-button-wrapper" key={this.props.inputs.length + key}>
                <input 
                    type="radio"
                    name="ticket"
                    value={props.sku}
                    onChange={this.handleRadioButtonChange}
                /> 
                <div className="text-wrapper">
                    <span>{`${props.name}: `}</span>
                    <b><span>{`${props.price} ${props.currency}`}</span></b>
                    <span>{props.description}</span>
                </div>
            </label>
        );
    }

    render() {
		return (
            <div className="form-container">
                <h1>{this.props.header}</h1>
                <form onSubmit={this.handleSubmit}>
                    {this.props.inputs.map((input, i) => this.renderInput(input, i))}
                    {this.props.tickets.map((ticket, i) => this.renderRadioButton(ticket, i))}
                    <div className="button-wrapper">
                        <input className="submit-button" type="submit" value={this.props.submitButtonText} />
                    </div>
                </form>
            </div>
        );
    }
}

module.exports = DynamicInputForm;
