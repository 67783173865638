const React = require('react');
const OpinionMini = require('../../opinion-mini/Component');
const RandomGrid = require('../../../../webapp/components/RandomGrid');

class OpinionList extends React.Component {
    render() {
        return (
            <RandomGrid>
                {this.props.opinions.map(opinion => <OpinionMini key={opinion._id} size="small" {...opinion}/>)}
            </RandomGrid>
        );
    }
}

module.exports = OpinionList;