const isNode = (typeof process !== 'undefined');
const React = require('react');
const ReactDOM = isNode ? require('react-dom') : {};

const Image = require('../../../../webapp/components/Image');
const Menu = require('../../../../webapp/components/Menu');
const Logo = require('../../../../webapp/components/Logo');
const handleAnchor = require('../../handleAnchor');

function scrollTop() {
    if (window.pageYOffset) return window.pageYOffset;
    return document.documentElement.clientHeight
        ? document.documentElement.scrollTop
        : document.body.scrollTop;
}

class SectionHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            imageLoaded: false,
            anchors: [],
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.createAnchors = this.createAnchors.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('DOMContentLoaded', this.createAnchors);

        this.handleScroll();
        this.handleResize();
        this.setState({ loaded: true })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('DOMContentLoaded', this.createAnchors);
    }

    handleResize() {
        const headerHeight = ReactDOM.findDOMNode(this.refs.header).getBoundingClientRect().height;
        const imageHeight = ReactDOM.findDOMNode(this.refs.image).getBoundingClientRect().height;

        this.setState({
            innerWidth: window.innerWidth,
            headerHeight,
            imageHeight,
        })
    }

    handleScroll() {
        this.setState({
            scrollTop: scrollTop(),
        });
    }

    createAnchors() {
        const elements = [...document.querySelectorAll('h2, h3')];
        elements.forEach((elem, i) => elem.id = `anchor-${i}`);
        const anchors = elements.map(({ tagName, innerHTML }, i) => ({ text: innerHTML, href: `#anchor-${i}`, indent: tagName === 'H2' ? 0 : 20 }));
        this.setState({ anchors });
        handleAnchor();
    }

    render() {
        const { headerHeight, imageHeight, scrollTop, innerWidth, anchors } = this.state;

        let logoWidth;
        let logoClass;

        if (innerWidth < 1000) {
            logoWidth = innerWidth;
            logoClass = 'responsiveLogo';
        } else if (scrollTop < headerHeight) {
            logoWidth = Math.max(headerHeight - scrollTop, 100);
            logoClass = 'top';
        } else {
            logoWidth = innerWidth / 8;
            logoClass = 'bottom';
        }

        return (
            <div className="section-header">
                <div>
                    <Menu {...this.props.menu}>
                        {this.state.imageLoaded && this.props.anchors && (
                            <ul className="anchors" style={{ top: scrollTop < (headerHeight + imageHeight - 235) ? (headerHeight + imageHeight - scrollTop) : 235 }}>
                                {anchors.map(({ text, href, indent }) => <li className="list-item" key={href} style={{ paddingLeft: indent }}><a href={href} dangerouslySetInnerHTML={{ __html: text }} /></li>)}
                            </ul>
                        )}
                    </Menu>
                    <div className={logoClass}>
                        {this.state.loaded && <Logo width={logoWidth} mainPage={this.props.menu.mainPage} />}
                    </div>
                </div>
                <header ref="header" className="section-content">
                    <h1>{this.props.header}</h1>
                </header>
                <div ref="image" className={this.props.orientation ? `image-container-${this.props.orientation}` : 'image-container'}>
                    <Image sources={this.props.images} alt={this.props.imageText} onLoad={() => this.setState({ imageLoaded: true, imageHeight: ReactDOM.findDOMNode(this.refs.image).getBoundingClientRect().height })} />
                    {this.props.caption && (
                        <figcaption>
                            <div className={`image-content-${this.props.orientation}`} dangerouslySetInnerHTML={{ __html: this.props.caption }} />
                            <div className="fill" />
                        </figcaption>
                    )}
                </div>
            </div>
        );
    }
}

module.exports = SectionHeader;