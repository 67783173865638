const React = require('react');
const Logo = require('../../../../webapp/components/Logo');
const Menu = require('../../../../webapp/components/Menu');
const handleAnchor = require('../../handleAnchor');

class PersonListHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {mounted: false, headings: []};
        this.handleResize = this.handleResize.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('DOMContentLoaded', this.handleLoad);
        this.handleResize();
        this.setState({mounted: true});
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('DOMContentLoaded', this.handleLoad);
    }

    handleResize() {
        const innerWidth = window.innerWidth;
        let logoWidth;
        if (innerWidth > 1000) {
            logoWidth = innerWidth / 8;
        } else {
            logoWidth = innerWidth;
        }
        this.setState({logoWidth});
    }

    handleLoad() {
        const elements = [...document.querySelectorAll('h2')];
        const headings = elements.map(e => e.innerHTML);
        this.setState({headings});
        handleAnchor();
    }


    render() {
        const {header, menu} = this.props;
        const headings = this.state.headings;
        return (
            <div className="person-list-header">
                <div>
                    <Menu {...menu}>
                        <div className="logo">
                            {this.state.mounted && <Logo width={this.state.logoWidth} mainPage={menu.mainPage}/>}
                        </div>
                        <ul className="navList">
                            {headings.map((e, i) => {
                                return (
                                    <li key={i} className="navLink">
                                        <a href={`#${e.replace(/\s/g, '-')}`}>{e}</a>
                                    </li>
                                );
                            })}
                        </ul>
                    </Menu>
                </div>
                <h1 className="header">{header}</h1>
            </div>

        );
    }
}

module.exports = PersonListHeader;