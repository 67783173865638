const isNode = (typeof process !== 'undefined');
const React = require('react');
const Image = require('../../../../webapp/components/Image');
const Menu = require('../../../../webapp/components/Menu');
const Logo = require('../../../../webapp/components/Logo');
const moment = isNode ? require('moment') : () => ({ format: () => '' });

class NewsSiteHeader extends React.Component {
    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);
        this.isMobile = this.isMobile.bind(this);

        this.state = {
            loaded: false,
            innerWidth: 0,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        this.setState({
            loaded: true
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({
            innerWidth: window.innerWidth,
        });
    }

    isMobile() {
        return this.state.innerWidth < 1000;
    }

    render() {

        return (
            <div className="news-site-header">
                {this.isMobile() &&
                    <div className="logo-wrapper">
                        <Logo width={this.state.innerWidth} mainPage={this.props.menu.mainPage} />
                    </div>
                }
                {/*
                TODO: Only render <Menu /> on large screensizes??? 
                Seems to work, probably handled with css?
                */}
                <Menu {...this.props.menu}>
                    <div className="logo">
                        {this.state.loaded && <Logo width={this.state.innerWidth < 1000 ? this.state.innerWidth : (this.state.innerWidth / 8)} mainPage={this.props.menu.mainPage} />}
                    </div>
                </Menu>
                <div className="newsSite">
                    <Image sources={this.props.images} className="image" />
                    <p className="created">
                        {moment(this.props.createdTime).format('DD/MM - YYYY')}
                    </p>
                    <h1>
                        {this.props.header}
                    </h1>
                    <p className="ingress">
                        {this.props.ingress}
                    </p>
                    <p className="small">
                        {`${this.props.menu.language === 'en' ? 'Published' : 'Publisert'}: ${moment(this.props.createdTime).format('DD/MM - YYYY')}${this.props.writtenBy ? ` ${this.props.menu.language === 'en' ? 'by' : 'av'} ${this.props.writtenBy}` : ''}. ${this.props.menu.language === 'en' ? 'Updated' : 'Oppdatert'}: ${moment(this.props.modifiedTime).format('DD/MM - YYYY')}`}
                    </p>
                </div>
            </div>
        );
    }
}

module.exports = NewsSiteHeader;
