const React = require('react');
// const Image = require('../../Image');
const Image = require('../../../../webapp/components/Image');

class ImageCaption extends React.Component {
    render() {
        const {images, caption, orientation, url} = this.props;
        const content = (
            <figure className={`image-caption-${orientation}`}>
                <Image sources={images} alt={!caption ? 'Bilde mangler tekst' : null}/>
                <figcaption>
                    <div className="image-content" dangerouslySetInnerHTML={{__html: caption}}/>
                    <div className="fill"/>
                </figcaption>
            </figure>
        );
        if (url) {
            return <a href={url} style={{display: 'block', backgroundImage: 'none'}}>{content}</a>
        }
        return content;
    }
}

module.exports = ImageCaption;
