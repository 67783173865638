const React = require('react');
const getImage = require('../../../site/parts/getImage');

class Image extends React.Component {

    constructor(props) {
        super(props);
        this.renderImage = this.renderImage.bind(this);
        this.renderTextOnImage = this.renderTextOnImage.bind(this);
    }

    renderImage() {
        if (!this.props.sources) {
            return null;
        }

        return (
            <img {...this.props} src={getImage(this.props.sources)} />
        )
    }

    renderTextOnImage() {
        if (!this.props.text) {
            return null;
        }

        return (
            <h1 className="text-on-image" style={this.props.textStyle} dangerouslySetInnerHTML={{ __html: this.props.text }}>
            </h1>
        );
    }

    render() {
        return (
            <div className="image-container">
                {this.renderImage()}
                {this.renderTextOnImage()}
            </div>
        )
    }
}

module.exports = Image;
